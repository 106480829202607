import { createStore } from 'vuex'

export default createStore({
  state: {
    token: '',
    user: '',
  },
  getters: {
    getLogin(state) {
      return typeof state.token === 'string' && state.token !== ''
    },
    getToken(state) {
      return state.token
    },
    getUserId(state) {
      return state.user
    },
    // getUserType(state) {
    //   return state.user_type
    // }
  },
  actions: {
    loginSession(context, result) {
      localStorage.setItem('token', result.data.data.token)
      localStorage.setItem('user', result.data.data.user)
      context.commit('setSession', data)
    },
    getSession(context) {
      const session = localStorage.getItem('token')
      if (session && typeof session === 'string' && session !== '') {
        const data = JSON.parse(session)
        context.commit('setSession', data)
      }
    },
    logoutSession(context) {
      context.commit('logoutSession')
    }
  },
  mutations: {
    setSession(state, n) {
      state.token = n.token
      state.user = n.user
    },
    logoutSession(state) {
      localStorage.clear()
      state.token = ''
      state.user = ''
    }
  }
})