<template>
  <section class="flirting-section" style="padding-bottom: 0px">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="content">
            <div class="section-header">
              <h2 class="title extra-padding wow fadeInUp">Featured Profile</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ==========Community-Section========== -->
  <section class="community-section inner-page">
    <div class="container">
      <div class="row">
        <div v-for="member in members" :key="member" class="col-lg-6">
          <div class="single-friend">
            <img :src="member.member_image" alt="" />
            <div class="content">
              <a href="single-profile.html" class="name">
                {{ member.members.first_name }}
                <span class="isvarify">
                  <i class="fas fa-check-circle"></i>
                </span>
              </a>
              <p class="date">{{ member.members.city }}</p>
              <div class="container">
                <i class="connnect-btn-2 far fa-heart"></i>
                <i class="connnect-btn far fa-comment-dots"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FeaturedMembers",

  data() {
    return {
      members: "",
    };
  },
  methods: {
    async getRecentMembers() {
      await this.axios
        .get("recent_members", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.members = result.data.data.members;
        })
        .catch((err) => {});
    },
  },

  mounted() {
    this.getRecentMembers();
    //console.log(members);
    //this.members = members
  },
};
</script>
