<template>
  <section class="log-reg">
      <div class="top-menu-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <router-link to="/" class="backto-home" 
              style="background: linear-gradient(166deg, #f22876 0%, #942dd9 100%); 
              border-radius: 52px; padding: 16px; margin-top: 20px; color: white;"
                ><i class="fas fa-chevron-left"></i> Back to Isna
                Matrimonials</router-link
              >
            </div>
            <div class="col-lg-7">
              <!-- <div class="logo">
                <img src="assets/images/logo2.png" alt="logo" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-end">
          <div class="image image-log"></div>
          <div class="image"></div>
          <div class="col-lg-7">
            <div class="log-reg-inner">
              <div class="section-header inloginp">
                <h2 class="title">Recover Password</h2>
              </div>
              <div class="main-content inloginp">
                <form @submit.prevent="resetPassword" method="post">
                  <div class="form-group">
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      v-model="email"
                      class="my-form-control"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div class="button-wrapper">
                    <a href="#">
                      <button type="submit" class="custom-button">Get Reset Link</button>
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
</template>

<script>
export default {

    name: 'PasswordReset',
    data() {
        return {
            email: '',
        }
    },
    methods:{
        async resetPassword() {
        await this.axios
            .post("sendPasswordResetLinkEmail", {
            email: this.email,
            })
            .then((res) => {
              this.$router.push("/")
            })
            .catch((error) => {
            });
        },
    },
}
</script>


