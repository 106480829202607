<template>
  <!-- ==========Faq-Section========== -->
  <section class="faq-section" style="padding-top: 118px">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="content">
            <div class="section-header">
              <h6 class="sub-title extra-padding">Got any Question</h6>
              <h2 class="title">We’ve Got Answers</h2>
              <p class="text">Try to check out frequently ask questions</p>
            </div>
          </div>
        </div>
      </div>
      <div class="faq-area bulletPointShow">
        <div class="faq-wrapper">
          <div class="faq-item active open">
            <div class="faq-title">
              <h6 class="title">What is ISNA Online Matrimonials?</h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                ISNA Online Matrimonials is a web-based matrimonial service for single
                Muslims in North America who are seeking a spouse. Members can post their
                detailed profile, post pictures, set match preferences, and send & receive
                messages from other members in this online database. This website is
                developed, monitored, and administered by the Islamic Society of North
                America (ISNA).
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">How do I become a member of ISNA Online matrimonials?</h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                You can register for a free 7-day trial or join the paid membership by
                clicking on ‘<span style="color: #ff3333">Register Now</span>’ button on
                the home page. You will need to fill out the form to create your personal
                profile. In paid membership you will have options for 1 month ($ 19.95), 3
                months ($ 49.95), and 6 months plans ($ 89.95).
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">
                What is my personal information, date of birth, and address used for? How
                can I edit this information?
              </h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                Your personal information, date of birth, and address are for our records.
                We do not display any of your personal information on the website. You can
                change this information by clicking on ‘<span style="color: #ff3333"
                  >Account Setting</span
                >’ on your profile home page.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">
                What is the difference between login name and display name?
              </h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                Login name is used to login to the account, while the display name shows
                on your profile during search.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">How can I change my Display Name on my Profile?</h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                You can change your display name by clicking on ‘<span
                  style="color: #ff3333"
                  >Update My Profile</span
                >’ from your profile home page.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">
                Why should I add my photograph to my matrimonial profile
              </h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                Statistics show that adding a photograph to your profile increases the
                “profile views” by up to 7 times. You are also likely to receive 10 times
                more responses by adding a photo. You can add up to 3 photos to your
                Profile.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">What are the benefits of Free Membership Trial?</h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <ul>
                <li>Create your profile</li>
                <li>Add photos to your profile</li>
                <li>Set your partner’s preferences</li>
                <li>Search for profiles</li>
                <li>Browse matches</li>
                <li>Send and receive messages</li>
              </ul>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">
                What are the benefits of Deluxe Membership (Paid Membership)?
              </h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                Deluxe membership has all the features and benefits of Free Trial
                Membership. In addition to this, it allows you to share your personal
                contact information with other paid members. Deluxe members can also
                perform advance search based on their preferences.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">
                What are the payment and cancellation options and procedures?
              </h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <ul>
                <li>
                  If you choose a 7-day Free Trial you do not need to provide credit card
                  information. At the end of Free Trial you will be asked to provide
                  payment information, if you choose to become a paid member. In paid
                  membership (Deluxe Package) you can choose from 1 Month, 3 Month, or 6
                  Month options.
                </li>
                <li>
                  As a paid member you can choose any of the paid membership plans (1, 3,
                  or 6 Month Membership Plans). After the membership plan expires you will
                  be <span style="color: #ff3333">automatically charged</span> according
                  to the <b>Next Membership Plan</b>. By default Next Membership Plan is
                  the same as the current membership plan but you can change it before
                  your current membership expires.
                  <b>As a member you can also cancel auto recharging</b>.
                </li>
                <li>
                  After you choose a membership plan, you will receive an email two days
                  before the expiration date regarding the charges for the renewal of your
                  membership. The email also provides cancellation steps to follow in case
                  you want to cancel the membership.
                </li>
              </ul>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">How do I cancel my membership?</h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>To cancel your membership, please follow these steps:</p>
              <ul>
                <li>Click on Membership Settings on User Home page</li>
                <li>
                  Follow the Cancel Your Membership section. In this section you can
                  cancel your membership easily.
                </li>
              </ul>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">
                Will I have to subscribe every time my membership period ends or will my
                membership be automatically renewed until I cancel it?
              </h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                If you have not opt out of the auto recharge and renewal, your membership
                will automatically be renewed and your credit card will be charged at the
                end of your membership plan period. You can cancel your membership at
                anytime.
                <span style="color: #ff3333"
                  >As a member you can also cancel auto recharging</span
                >.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">How do I edit my profile?</h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                You can edit your profile by clicking on ‘<span style="color: #ff3333"
                  >Update My Profile</span
                >’ from your profile home page.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">I'm having trouble uploading my pictures?</h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                Each picture you upload must be not greater than 200 KB in size. Pictures,
                more than the prescribed size, may not be uploaded due to restrictions on
                the picture size. Picture must be in .jpg or .png format.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">I cannot see my new profile?</h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                Each profile that is created for the first time (just after registration)
                goes through a screening process during which it cannot be viewed
                publicly. Your profile will be available for viewing within 24 hours after
                you create it.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">
                How long does it take to get a new or updated profile posted for viewing?
              </h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                Profile is screened before it goes live with the updated information. This
                process may take up to 24 hours.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">I cannot see my updated profile?</h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                Each profile that is updated may go under the process of screening. During
                this process your updated profile cannot be viewed publicly. But your old
                profile can be viewed publicly.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">I cannot save my profile?</h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                While saving your profile you must complete all the required fields marked
                with red asterisk. Leaving any of these fields blank or unselected will
                not allow you to save your profile.
              </p>
            </div>
          </div>
          <div class="faq-item">
            <div class="faq-title">
              <h6 class="title">Who should I contact for questions or feedback?</h6>
              <span class="right-icon"></span>
            </div>
            <div class="faq-content">
              <p>
                For Technical Support email:
                <span style="color: #669900">support@isnamatrimonials.net </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    //Faq
    $(".faq-wrapper .faq-title").on("click", function (e) {
      var element = $(this).parent(".faq-item");
      if (element.hasClass("open")) {
        element.removeClass("open");
        element.find(".faq-content").removeClass("open");
        element.find(".faq-content").slideUp(300, "swing");
      } else {
        element.addClass("open");
        element.children(".faq-content").slideDown(300, "swing");
        element.siblings(".faq-item").children(".faq-content").slideUp(300, "swing");
        element.siblings(".faq-item").removeClass("open");
        element
          .siblings(".faq-item")
          .find(".faq-title, .faq-title-two")
          .removeClass("open");
        element.siblings(".faq-item").find(".faq-content").slideUp(300, "swing");
      }
    });
  },
};
</script>
