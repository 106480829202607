<template>
  <div>
    <PublicHeader v-if="$route.name !== 'Login' && $route.name !== 'Register' && $route.name !== 'Password Reset' && $route.name !== 'Password'" />
    <router-view />
    <PublicFooter v-if="$route.name !== 'Login' && $route.name !== 'Register' && $route.name !== 'Password Reset' && $route.name !== 'Password'" />
  </div>
</template>

<script>
import PublicHeader from "./components/PublicHeader.vue";
import PublicFooter from "./components/PublicFooter.vue";
export default {
  components: {
    PublicHeader,
    PublicFooter,

  },
};
</script>
<style></style>
