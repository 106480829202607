<!-- <?php include "header.php"; ?> -->
<template>
    <!-- ==========Faq-Section========== -->
    <section class="faq-section" style="padding-top: 118px;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <div class="content">
                        <div class="section-header">
                            <h6 class="sub-title extra-padding">
                                Got any Question
                            </h6>
                            <h2 class="title">
                                We’ve Got Answers
                            </h2>
                            <p class="text">
                                Isna matrimonial terms of use / service agreement
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="faq-area bulletPointShow">
                <div class="faq-wrapper">
                    <div class="faq-item active open">
                        <div class="faq-title">
                            <h6 class="title">Welcome to ISNA Matrimonial. </h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>the No.1 Muslim Matrimonial Services Provider in North America. In order to use the ISNA
                                Matrimonial Site, you must register as a member of the Site and agree to be bound by
                                these (“Terms of Use Agreement"). This Agreement sets out the legally binding terms for
                                your membership. If you object to anything in this Agreement or the ISNA Matrimonial
                                Privacy Policy, do not use the Website or the Service. This Agreement may be modified by
                                ISNA Matrimonial from time to time, effective upon notice to you as a Member.
                            </p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Eligibility </h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p> You must be at least eighteen (18) years of age, to register as a member of ISNA
                                Matrimonial or use this Site. By using this Site, you represent and warrant that you
                                have the right, authority, and capacity to enter into this Agreement and to abide by all
                                of the terms and conditions of this Agreement.
                            </p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Term</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>This Agreement will remain in full force and effect while you use the Site and/or are a
                                Member of ISNA Matrimonial. You may terminate your membership at any time, for any
                                reason. ISNA Matrimonial may terminate your access to the Site and/or your membership
                                for any reason effective upon sending notice to you at the email address you provide in
                                your application for membership or such other email address as you may later provide to
                                ISNA Matrimonial. If ISNA Matrimonial terminates your membership in the ISNA Matrimonial
                                Service because you have breached the Agreement, you will not be entitled to any refund
                                of any unused Subscription fees (if paid). Even after this Agreement is terminated,
                                certain provisions will remain in effect including sections 4,5,7,9 -14, of this
                                Agreement.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Non-Commercial Use by Members</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>The ISNA Matrimonial Site is for the personal use of individual members only, and may not
                                be used in connection with any commercial endeavors. This includes providing links to
                                other websites, whether deemed competitive to ISNA Matrimonial or otherwise.
                                Organizations, companies, and/or businesses may not become Members of ISNA Matrimonial
                                and should not use the ISNA Matrimonial Service or Site for any purpose. Illegal and/or
                                unauthorized uses of the Site, including unauthorized framing of or linking to the Site
                                will be investigated, and appropriate legal action will be taken.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Membership Categories</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>Users of the Service may use minimal service as Guests (Public Users) or become "Trial
                                Members" or "Deluxe Members" of the Service. Guests are allowed access to a minimal
                                level of the Service's features. Trial Members are allowed to access more features of
                                the Service than are Guests, but are not allowed access to all of the features that
                                Deluxe Members are allowed to access. Trial Members are not charged by us for the
                                features they can access. Deluxe Members are provided with access to additional features
                                of the Service that are not available to Guests or Trial Members. Certain features of
                                the Service are "paid features", for which a Deluxe Member is charged.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Fees and Payments</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <ol class="uperAlphabet">
                                <li>Deluxe Members of the Service are required to purchase the membership on monthly,
                                    quarterly, or semiannual basis. Each month is considered as 30 days of length, any
                                    one paying in February will get the same amount of days of premium membership as the
                                    person who is paying in a month with 31 days. Similarly 3 months membership is
                                    considered as 90 days membership and one year as 360 days.
                                </li>
                                <li>The Deluxe member has an option to turn off or on his/her auto-renew feature any
                                    time after the first payment is made, through the Account Settings option.</li>
                                <li> If we find that any member has used a stolen or fraudulent credit card the personal
                                    information of that member will be forwarded to the Law enforcement agencies of the
                                    resident country of the member.</li>
                                <li>Most subscription plans to the Service consist of an initial period, for which there
                                    is a onetime charge, followed by recurring period charges as agreed to by you. By
                                    entering into this Agreement, you acknowledge that your subscription has an initial
                                    and recurring payment feature and you accept responsibility for all recurring
                                    charges prior to cancellation</li>
                            </ol>
                            <p>ISNA MATRIMONIAL MAY SUBMIT PERIODIC CHARGES (e.g., MONTHLY) WITHOUT FURTHER
                                AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (CONFIRMED IN WRITING UPON
                                REQUEST BY ISNA MATRIMONIAL) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO
                                CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE ISNA
                                MATRIMONIAL REASONABLY COULD ACT. TO TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT
                                METHOD, GO TO MEMBERSHIP SETTINGS.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Other Terms of Use by Members</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>You may not engage in advertising to, or solicitation of, other Members to buy or sell
                                any products or services through ISNA Matrimonial. You will not transmit any chain
                                letters or junk email to other ISNA Matrimonial Members. Although ISNA Matrimonial
                                cannot monitor the conduct of its Members off the ISNA Matrimonial Site, it is also a
                                violation of these rules to use any information obtained from the Service in order to
                                harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or
                                sell to any Member without their prior explicit consent. In order to protect ISNA
                                Matrimonial and/or our Members from any abuse / misuse, ISNA Matrimonial reserves the
                                right to restrict the number of communications / profile contacts & responses / emails
                                which a Member may send to other Member in any 24-hour period to a number which ISNA
                                Matrimonial deems appropriate in its sole discretion.</p>
                            <p>You may not use any automated processes, including IRC Bots, EXE's, CGI or any other
                                programs/scripts to view content on or communicate / contact / respond / interact with
                                ISNA Matrimonial and/or its members.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Proprietary Rights in Content on ISNA Matrimonial</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>ISNA Matrimonial owns and retains other proprietary rights in the ISNA Matrimonial Site
                                and the ISNA Matrimonial Service. The Site contains the copyrighted material,
                                trademarks, and other proprietary information of ISNA & ISNA Matrimonial, and its
                                licensors. Except for that information which is in the public domain or for which you
                                have been given permission, you may not copy, modify, publish, transmit, distribute,
                                perform, display, or sell any such proprietary information.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Content Posted on the Site</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <ol class="uperAlphabet">
                                <li>Right to Monitor and Remove Content</li>
                                <p>You understand and agree that ISNA Matrimonial may review and delete any Content that
                                    in the sole judgment of ISNA Matrimonial violate these Terms or which might be
                                    offensive, illegal, or that might violate the rights, harm, or threaten the safety
                                    of Members or other visitors to the Site. To enable ISNA Matrimonial to monitor the
                                    Content, all Content text must be in the English language.</p>
                                <li>Personally Identifiable Information</li>
                                <p>You may not include in your Member profile, visible to other members, any telephone
                                    numbers, street addresses, and last names, URL's, email addresses or any other
                                    information that would allow others to locate or contact you outside of the Service.
                                </p>
                                <li>Content Responsibility</li>
                                <p>You are solely responsible for the Content that you publish or display on the ISNA
                                    Matrimonial service, or transmit to other ISNA Matrimonial Members. ISNA Matrimonial
                                    reserves the right to verify the authenticity of Content posted on the site. In
                                    exercising this right, ISNA Matrimonial may ask you to provide any documentary or
                                    other form of evidence supporting the Content you post on the site. If you fail to
                                    produce such evidence, ISNA Matrimonial may, in its sole discretion, terminate your
                                    Membership without a refund.</p>
                                <p>By posting Content to any public area of ISNA Matrimonial, you automatically grant,
                                    and you represent and warrant that you have the right to grant, to ISNA Matrimonial,
                                    and other ISNA Matrimonial Members, an irrevocable, perpetual, non-exclusive,
                                    fully-paid, worldwide license to use, copy, perform, display, and distribute such
                                    information and content and to prepare derivative works of, or incorporate into
                                    other works, such information and content, and to grant and authorize sublicenses of
                                    the foregoing.</p>
                                <li>Prohibited Contents</li>
                                <p>The following is a partial list of the kind of Content that is illegal or prohibited
                                    on the Site. ISNA Matrimonial will investigate and take appropriate legal action in
                                    its sole discretion against anyone who violates this provision, including without
                                    limitation, removing the offending communication from the Service and terminating
                                    the Membership of such violators without a refund.</p>
                                <p>It includes Content that</p>
                                <ul>
                                    <li>is patently offensive to the online community, such as Content that promotes
                                        racism, bigotry , hatred or physical harm of any kind against any group or
                                        individual</li>
                                    <li>harasses or advocates harassment of another person.</li>
                                    <li>involves the transmission of "junk mail", "chain letters," or unsolicited mass
                                        mailing or "spamming";</li>
                                    <li>promotes information that you know is false, misleading or promotes illegal
                                        activities or conduct that is abusive, threatening, obscene, defamatory or
                                        libelous;</li>
                                    <li>promotes an illegal or unauthorized copy of another person's copyrighted work,
                                        such as providing pirated computer programs or links to them, providing
                                        information to circumvent manufacture-installed copy-protect devices, or
                                        providing pirated music or links to pirated music files;</li>
                                    <li>contains restricted or password only access pages, or hidden pages or images
                                        (those not linked to or from another accessible page);</li>
                                    <li>displays pornographic or sexually explicit material of any kind;</li>
                                    <li>solicits personal information from anyone under 18;</li>
                                    <li>provides instructional information about illegal activities such as making or
                                        buying illegal weapons, violating someone's privacy, or providing or creating
                                        computer viruses;</li>
                                    <li>solicits passwords or personal identifying information for commercial or
                                        unlawful purposes from other users; and </li>
                                    <li>engages in commercial activities and/or sales without our prior written consent
                                        such as contests, sweepstakes, barter, advertising, and pyramid schemes</li>
                                </ul>
                                <li>Additional Guidelines for Photos</li>
                                <p>The photo which you post as your primary photo should satisfy the following
                                    additional requirements: (i) you are in it; (ii) your face is clear; (iii) it is no
                                    more than 5 years old; (iv) you are the only recognizable person; and (v) it is a
                                    jpg or gif file. All photos you post (including your primary photo) should satisfy
                                    the following requirements: (1) it does not contain your name or contact
                                    information, (2) it is not a duplicate of another posted photo; (3) it is smaller
                                    than 5MB in size; (4) it is not sexually explicit, (5) it does not demonstrate an
                                    illegal activity, and (6) any other person in the photo which is recognizable has
                                    given you permission to post their likeness (which, in the case of persons under the
                                    age of 18, must be provided by their parent or legal guardian). It is your
                                    obligation to obtain all required consents before posting a photo.</p>
                            </ol>

                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Copyright Policy</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>You may not post, distribute, or reproduce in any way any copyrighted material,
                                trademarks, or other proprietary information without obtaining the prior written consent
                                of the owner of such proprietary rights. Without limiting the foregoing, if you believe
                                that your work has been copied and posted on the ISNA Matrimonial service in a way that
                                constitutes copyright infringement, please provide our Copyright Agent with the
                                following information: an electronic or physical signature of the person authorized to
                                act on behalf of the owner of the copyright interest; a description of the copyrighted
                                work that you claim has been infringed; a description of where the material that you
                                claim is infringing is located on the our Site; your address, telephone number, and
                                email address; a written statement by you that you have a good faith belief that the
                                disputed use is not authorized by the copyright owner, its agent, or the law; a
                                statement by you, made under penalty of perjury, that the above information in your
                                Notice is accurate and that you are the copyright owner or authorized to act on the
                                copyright owner's behalf.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Member Disputes</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>You are solely responsible for your interactions with other ISNA Matrimonial Members.
                                ISNA Matrimonial reserves the right, but has no obligation, to monitor disputes between
                                you and other Members.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Operation and Modification of the Service</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>ISNA Matrimonial may establish additional policies concerning the use and operation of
                                the Service generally or for a particular feature of the Service. ISNA Matrimonial may
                                change these policies at any time, in its sole discretion with or without notice.
                                However, ISNA Matrimonial will post relevant policies in appropriate locations as they
                                are adopted or modified. Such policies are part of these Terms and incorporated herein
                                by reference.</p>
                            <p>You agree that ISNA Matrimonial has no responsibility or liability for the deletion or
                                failure to store any Content maintained on, or transmitted by, the Service. You
                                understand that ISNA Matrimonial may close your account and Membership if it is inactive
                                for an extended period of time.
                            </p>
                            <p>You agree that ISNA Matrimonial reserves the right (for any reason and without notice) to
                                modify or discontinue, temporarily or permanently, all or any part of the Service and
                                the Site.</p>
                            <p>Unless explicitly stated otherwise, any new features on the Site or the Service are
                                subject to these Terms.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Privacy</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>Use of the ISNA Matrimonial Site and/or the ISNA Matrimonial Service is governed by our
                                Privacy Policy. </p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Disclaimers</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <ol class="uperAlphabet">
                                <li>No Warranties</li>
                                <p>Each profile that is created for the first time (just after registration) goes
                                    through a
                                    screening process during which it cannot be viewed publicly. Your profile will be
                                    available for viewing within 24 hours after you create it. </p>
                                <li>Specific Disclaimers</li>
                                <p>ISNA Matrimonial is not responsible for any incorrect or inaccurate Content posted on
                                    the Site or in connection with the Service, whether caused by users of the Site,
                                    Members or by any of the equipment or programming associated with or used in the
                                    Service. ISNA Matrimonial is not responsible for the conduct, whether online or
                                    offline, of any user of the Site or Member of the Service, including ones who
                                    violate these Terms.
                                </p>
                                <li>Internet Related Issues.</li>
                                <p>ISNA Matrimonial assumes no responsibility for any error, omission, interruption,
                                    deletion, defect, delay in operation or transmission, communications line failure,
                                    theft or destruction or unauthorized access to, or alteration of, user or Member
                                    communications. ISNA Matrimonial is not responsible for any problems or technical
                                    malfunction of any telephone network or lines, computer online systems, servers or
                                    providers, computer equipment, software, failure of email on account of technical
                                    problems or traffic congestion on the Internet or at the Site, or any combination
                                    thereof, including injury or damage to users and/or Members or to any other person's
                                    computer related to or resulting from participating or downloading materials in
                                    connection with the Internet, the Site and/or in connection with the Service.</p>
                                <li>Rankings</li>
                                <p>ISNA Matrimonial specifically disclaims any responsibility for the accuracy or
                                    completeness of any rankings posted on the Site or available through the Service as
                                    well as for any positive or negative implications which may be derived from the
                                    rankings of Members.</p>
                            </ol>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title">Limitation on Liability</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>Except in jurisdictions where such provisions are restricted, in no event will ISNA
                                Matrimonial be liable to you or any third person for any indirect, consequential,
                                exemplary, incidental, special or punitive damages, including also lost profits arising
                                from your use of the Site or the ISNA Matrimonial Service, even if ISNA Matrimonial has
                                been advised of the possibility of such damages. Notwithstanding anything to the
                                contrary contained herein, ISNA Matrimonial, liability to you for any cause whatsoever,
                                and regardless of the form of the action, will at all times be limited to the amount
                                paid, if any, by you to ISNA Matrimonial, for the Service during the term of membership.
                            </p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title"> Governing Law; Exclusive Jurisdiction</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>The laws of the State of Indiana, USA, excluding its conflicts of law rules, are the
                                exclusive laws governing these Terms and the enforcement thereof. The state and federal
                                courts with districts which include Hendricks County, Indiana, USA, shall be the
                                exclusive forum and venue to resolve any and all disputes arising out of or relating to
                                these Terms and you consent to personal jurisdiction and venue by such state and federal
                                courts. However, in an action to enforce a judgment, such forum and venue shall be
                                nonexclusive.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title"> Indemnity</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>You agree to indemnify and hold ISNA Matrimonial, its subsidiaries, affiliates, officers,
                                agents, and other partners and employees, harmless from any loss, liability, claim, or
                                demand, including reasonable attorney's fees, made by any third party due to or arising
                                out of your use of the Service in violation of this Agreement and/or arising from a
                                breach of these Terms of Use and/or any breach of your representations and warranties
                                set forth above. </p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <div class="faq-title">
                            <h6 class="title"> Other</h6>
                            <span class="right-icon"></span>
                        </div>
                        <div class="faq-content">
                            <p>By becoming a Member of the ISNA Matrimonial service, you agree to receive emails from
                                ISNA Matrimonial.</p>
                            <p>This Agreement, accepted upon use of the Site and further affirmed by becoming a Member
                                of the ISNA Matrimonial service, contains the entire agreement between you and ISNA
                                Matrimonial regarding the use of the Site and/or the Service. If any provision of this
                                Agreement is held invalid, the remainder of this Agreement shall continue in full force
                                and effect</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "TermCondition",
     mounted() {
    //Faq
    $(".faq-wrapper .faq-title").on("click", function (e) {
      var element = $(this).parent(".faq-item");
      if (element.hasClass("open")) {
        element.removeClass("open");
        element.find(".faq-content").removeClass("open");
        element.find(".faq-content").slideUp(300, "swing");
      } else {
        element.addClass("open");
        element.children(".faq-content").slideDown(300, "swing");
        element.siblings(".faq-item").children(".faq-content").slideUp(300, "swing");
        element.siblings(".faq-item").removeClass("open");
        element
          .siblings(".faq-item")
          .find(".faq-title, .faq-title-two")
          .removeClass("open");
        element.siblings(".faq-item").find(".faq-content").slideUp(300, "swing");
      }
    });
  },
}
</script>
<!-- ==========Faq-Section========== -->
<!-- <?php include "footer.php"; ?> -->