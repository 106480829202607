<template>
<loader v-if="this.LoadingTimingFlag==false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2" bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
  <DashboardHeader></DashboardHeader>
  <section class="user-setting-section">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-md-12">
          <Form enctype="multipart/form-data" @submit="saveMatchCriteria">

            <div class="input-info-box mt-30">
              <div class="header">Personal Information</div>
              <div class="content">
                <div class="row">
                  <div class="col-lg-2 col-md-2">
                    <div class="my-input-box">
                      <label for="">Age Range:</label>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="my-input-box">
                      <Field as="select" name="age_from" v-model="age_from">
                        <option value="" disabled selected>Age From</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                        <option value="61">61</option>
                        <option value="62">62</option>
                        <option value="63">63</option>
                        <option value="64">64</option>
                        <option value="65">65</option>
                        <option value="66">66</option>
                        <option value="67">67</option>
                        <option value="68">68</option>
                        <option value="69">69</option>
                        <option value="70">70</option>
                        <option value="71">71</option>
                        <option value="72">72</option>
                        <option value="73">73</option>
                        <option value="74">74</option>
                        <option value="75">75</option>
                        <option value="76">76</option>
                        <option value="77">77</option>
                        <option value="78">78</option>
                        <option value="79">79</option>
                        <option value="80">80</option>
                        <option value="81">81</option>
                        <option value="82">82</option>
                        <option value="83">83</option>
                        <option value="84">84</option>
                        <option value="85">85</option>
                        <option value="86">86</option>
                        <option value="87">87</option>
                        <option value="88">88</option>
                        <option value="89">89</option>
                        <option value="90">90</option>
                        <option value="91">91</option>
                        <option value="92">92</option>
                        <option value="93">93</option>
                        <option value="94">94</option>
                        <option value="95">95</option>
                        <option value="96">96</option>
                        <option value="97">97</option>
                        <option value="98">98</option>
                        <option value="99">99</option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="my-input-box">
                      <Field as="select" name="age_to" v-model="age_to">
                        <option value="" disabled selected>Age To</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                        <option value="53">53</option>
                        <option value="54">54</option>
                        <option value="55">55</option>
                        <option value="56">56</option>
                        <option value="57">57</option>
                        <option value="58">58</option>
                        <option value="59">59</option>
                        <option value="60">60</option>
                        <option value="61">61</option>
                        <option value="62">62</option>
                        <option value="63">63</option>
                        <option value="64">64</option>
                        <option value="65">65</option>
                        <option value="66">66</option>
                        <option value="67">67</option>
                        <option value="68">68</option>
                        <option value="69">69</option>
                        <option value="70">70</option>
                        <option value="71">71</option>
                        <option value="72">72</option>
                        <option value="73">73</option>
                        <option value="74">74</option>
                        <option value="75">75</option>
                        <option value="76">76</option>
                        <option value="77">77</option>
                        <option value="78">78</option>
                        <option value="79">79</option>
                        <option value="80">80</option>
                        <option value="81">81</option>
                        <option value="82">82</option>
                        <option value="83">83</option>
                        <option value="84">84</option>
                        <option value="85">85</option>
                        <option value="86">86</option>
                        <option value="87">87</option>
                        <option value="88">88</option>
                        <option value="89">89</option>
                        <option value="90">90</option>
                        <option value="91">91</option>
                        <option value="92">92</option>
                        <option value="93">93</option>
                        <option value="94">94</option>
                        <option value="95">95</option>
                        <option value="96">96</option>
                        <option value="97">97</option>
                        <option value="98">98</option>
                        <option value="99">99</option>
                      </Field>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-ld-2 col-md-2">
                    <div class="my-input-box">
                      <label for="">Height Range:</label>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="my-input-box">
                      <select as="select" v-model="height_from">
                        <option value="0" disabled selected>Height From</option>
                        <option v-for="height in heights" :key="height" :value="height.numeric_value">
                          {{ height.text_value }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                    <div class="my-input-box">
                      <select as="select" v-model="height_to">
                        <option value="0" disabled selected>Height To</option>
                        <option v-for="height in heights" :key="height" :value="height.numeric_value">
                          {{ height.text_value }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">

                  <div class="col-md-12">
                    <div class="my-input-box">
                      <label for="">Eye Color: </label>
                      <div class="container">
                        <div class="community-section inner-page row">
                          <div class="top-filter noBorder">
                            <div class="left">
                              <label class="mr-3 mb-4" v-for="color in eye_colors" :key="color">
                                <Field class="custom-button" type="checkbox" id="eyeColor" :value="color.numeric_value"
                                  name="eyeColor" v-model="eyeColor" />
                                {{ color.text_value }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="my-input-box">
                      <label for="">Hair Color:</label>
                      <div class="container">
                        <div class="community-section inner-page row">
                          <div class="top-filter noBorder">
                            <div class="left">
                              <label class="mr-3 mb-4" v-for="color in hair_colors" :key="color">
                                <Field class="custom-button" type="checkbox" id="hairColor" :value="color.numeric_value"
                                  name="hairColor" v-model="hairColor" />
                                {{ color.text_value }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="my-input-box">
                      <label for="">Body Type</label>
                      <div class="container">
                        <div class="community-section inner-page row">
                          <div class="top-filter noBorder">
                            <div class="left">
                              <label class="mr-3 mb-4" v-for="btype in body_types" :key="btype">
                                <Field class="custom-button" type="checkbox" id="bodyType" :value="btype.numeric_value"
                                  name="bodyType" v-model="bodyType" />
                                {{ btype.text_value }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-info-box mt-30">
              <div class="header">Professional Information</div>
              <div class="content">
                <div class="row">
                  <div class="col-md-12">
                    <div class="my-input-box">
                      <label for="">Profession</label>
                      <div class="container">
                        <div class="community-section inner-page row">
                          <div class="top-filter noBorder">
                            <div class="left">
                              <label class="mr-3 mb-4" v-for="pro in professions" :key="pro">
                                <Field class="custom-button" type="checkbox" id="profession" :value="pro.profession_id"
                                  name="profession" v-model="profession" />
                                {{ pro.profession_name }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <!-- <div class="my-input-box">
                      <label for="">Other Profession</label>
                      <input
                        type="text"
                        v-model="other_profession"
                        placeholder="Other Profession"
                      />
                    </div> -->
                  </div>
                  <div class="col-md-12">
                    <div class="my-input-box">
                      <label for="">Annual Income</label>
                      <div class="container">
                        <div class="community-section inner-page row">
                          <div class="top-filter noBorder">
                            <div class="left">
                              <label class="mr-3 mb-4" v-for="incomes in annualIncomes" :key="incomes">
                                <Field class="custom-button" type="checkbox" id="annualIncome"
                                  :value="incomes.numeric_value" name="annualIncome" v-model="annualIncome" />
                                {{ incomes.text_value }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="my-input-box">
                      <label for="">Education</label>
                      <div class="container">
                        <div class="community-section inner-page row">
                          <div class="top-filter noBorder">
                            <div class="left">
                              <label class="mr-3 mb-4" v-for="edu in educations" :key="edu">
                                <Field class="custom-button" type="checkbox" id="education" :value="edu.numeric_value"
                                  name="education" v-model="education" />
                                {{ edu.text_value }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Other Education</label>
                      <input type="text" placeholder="Other Education" />
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="input-info-box mt-30">
              <div class="header">Background Information</div>
              <div class="content">
                <div class="row">
                  <div class="my-input-box col-sm-12 col-lg-6 col-md-6">
                    <label for="">Country</label>
                    <select v-model="country" @change="getstates($event)" class="CountryDropdown">
                      <option value="0" selected>Select Country</option>
                      <option value="223">USA</option>
                      <option value="36">Canada</option>
                    </select>
                  </div>
                  <div class="my-input-box col-sm-12 col-lg-6 col-md-6">
                    <label for="">states</label>
                    <select v-model="state" class="StateDropdown">
                      <option value="0" disabled selected>Select States</option>
                      <option id="Selectoption" v-for="state in states" :key="state" :value="state.id">
                        {{ state.name }}
                      </option>
                    </select>
                  </div>


                  <div class="col-sm-12 col-md-6">
                    <div class="my-input-box">
                      <label for="">Country of Origin</label>
                      <Field as="select" name="countryoforigin" v-model="countryoforigin" id="countryoforigin">
                        <option value="0" disabled selected>Select One</option>
                        <option v-for="country in countries" :key="country" :value="country.id">
                          {{ country.name }}
                        </option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="my-input-box">
                      <label for="">Religion</label>
                      <div class="container">
                        <div class="community-section inner-page row">
                          <div class="top-filter noBorder">
                            <div class="left">
                              <label class="mr-3 mb-4" v-for="relig in religions" :key="relig">
                                <Field class="custom-button" type="checkbox" id="religion" :value="relig.numeric_value"
                                  name="religion" v-model="religion" />
                                {{ relig.text_value }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="my-input-box">
                      <label v-if = "this.country == 223">Status in USA</label>
                      <label v-else>Status in Canada</label>
                      <div class="container">
                        <div class="community-section inner-page row">
                          <div class="top-filter noBorder">
                            <div class="left">
                              <label class="mr-3 mb-4" v-for="st in usa_statuses" :key="st">
                                <Field class="custom-button" type="checkbox" id="status" :value="st.numeric_value"
                                  name="status" v-model="status" />
                                {{ st.text_value }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Other Status in USA</label>
                      <input type="text" placeholder="Other Status in USA" />
                    </div>
                  </div> -->
                </div>
                <label>Language</label>
                <div class="community-section inner-page">
                  <div class="container">
                    <div class="row">
                      <div class="top-filter noBorder">
                        <div class="left">
                          <label class="mr-3 mb-4" v-for="lang in languages" :key="lang">
                            <Field class="custom-button" type="checkbox" id="language" :value="lang.numeric_value"
                              name="language" v-model="language" />
                            {{ lang.text_value }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-info-box mt-30">
              <div class="header">Other Information</div>
              <div class="content">
                <div class="row">
                  <div class="col-md-12">
                    <div class="my-input-box">
                      <label for="">Marital Status</label>
                      <div class="container">
                        <div class="community-section inner-page row">
                          <div class="top-filter noBorder">
                            <div class="left">
                              <label class="mr-3 mb-4" v-for="st in marital_status" :key="st">
                                <Field class="custom-button" type="checkbox" id="maritalStatus"
                                  :value="st.numeric_value" name="maritalStatus" v-model="maritalStatus" />
                                {{ st.text_value }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Want Children</label>
                      <Field v-model="wantChild" name="wantchild" as="select" id="wantchild">
                        <option value="0" disabled selected>Select One</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                        <option value="3">May be</option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Have Child</label>
                      <Field as="select" v-model="haveChild" name="havechild" id="havechild">
                        <option value="0" disabled selected>Select One</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-input-box">
                      <label for="">Smoke</label>
                      <Field as="select" v-model="smoke" name="smoke" id="smoke">
                        <option value="0" disabled selected>Select One</option>
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons mt-30">
              <button type="submit" class="custom-button" >Submit</button>
            </div>
            <br />
            <div class="alert alert-success" v-if="updated" role="alert">
              Profile Updated Successfully!
            </div>
          </Form>
        </div>
      </div>
    </div>
  </section>
</template>


<script>

import { Field, Form, ErrorMessage } from "vee-validate";
import DashboardHeader from '@/components/DashboardHeader';
export default {
  name: "MatchCriteria",

  components: {
    Field,
    Form,
    DashboardHeader,
  },


  data() {
    return {
      updated: false,
      LoadingTimingFlag: false,
      country: "0",
      state: "0",
      result: "",
      heights: "",
      height_from: "0",
      height_to: "0",
      age_from: "",
      age_to: "",
      eye_colors: "",
      eyeColor: [],
      hair_colors: "",
      hairColor: [],
      body_types: "",
      bodyType: [],
      profession: [],
      professions: "",
      annualIncome: [],
      annualIncomes: "",
      education: [],
      educations: "",
      religion: [],
      religions: "",
      status: [],
      usa_statuses: "",
      language: [],
      languages: "",
      maritalStatus: [],
      marital_status: "",
      wantChild: "0",
      haveChild: "0",
      smoke: "0",
      meta: [],
      statesBoolValue: false,
      countries: '',
      countryoforigin: "0",
      states: [],
    };


  },

  methods: {
    //get countries from db
    async getcountries() {
      await this.axios
        .get("get_countries", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.countries = result.data.data.countries;
          // console.log(this.countries, "ofOrigincountries")
        })
        .catch((err) => { });
    },
    //get states from db
    async getstates(event) {
      // alert(event);
      this.LoadingTimingFlag=false
      await this.axios
        .get("get_states", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {

          if (event != undefined) {
              this.states.splice(0);
              result.data.data.states.forEach(element => {
              if (element.country_id == event.target.value) {

                this.states.push(element);
              }
            });
          } else {
               result.data.data.states.forEach(element => {
              if ( element.country_id==this.country) {
                this.states.push(element);
              }
            });
          }
        })
        .catch((err) => { })
        .finally(() => {
          this.LoadingTimingFlag=true;
                    });;


        await this.axios
        .get("get_match_criteria/" + JSON.parse(localStorage.getItem("user"))[0].member_id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.state = result.data[0].state;
          // console.log(this.state,"resultGetState");
        })
         .catch((err) => { });




    },
    //get professions from db
    async getProfession() {
      await this.axios
        .get("get_profession", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.professions = result.data.data.profession;
        })
        .catch((err) => { });
    },
    // submit edit profile data
    async saveMatchCriteria() {
      this.LoadingTimingFlag = false;
      var bodyFormData = new FormData();
      bodyFormData.append("age_from", this.age_from);
      bodyFormData.append("age_to", this.age_to);
      bodyFormData.append("height_from", this.height_from);
      bodyFormData.append("height_to", this.height_to);
      bodyFormData.append("country", this.country);
      bodyFormData.append("origin", this.countryoforigin);
      bodyFormData.append("state", this.state);
      bodyFormData.append("eye_color", this.eyeColor);
      bodyFormData.append("hair_color", this.hairColor);
      bodyFormData.append("body_type", this.bodyType);
      bodyFormData.append("profession", this.profession);
      bodyFormData.append("income", this.annualIncome);
      bodyFormData.append("education", this.education);
      bodyFormData.append("other_education", this.other_education);
      bodyFormData.append("religion", this.religion);
      bodyFormData.append("usa_status", this.status);
      bodyFormData.append("other_usa_status", this.other_status);
      bodyFormData.append("language", this.language);
      bodyFormData.append("marital_status", this.maritalStatus);
      bodyFormData.append("want_childern", this.wantChild);
      bodyFormData.append("have_childern", this.haveChild);
      bodyFormData.append("smoke", this.smoke);

      this.axios
        .post("update_match_criteria", bodyFormData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result, "SaveMatchCriteria")
          this.updated = result.data.data.message;
          this.LoadingTimingFlag = true
          window.location.replace("dashboard");
        })
        .catch((err) => {
          this.LoadingTimingFlag = true
         });
    },
    //get and papulate reference data
    async getReferenceData() {
      await this.axios
        .get("get_reference_data", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.heights = result.data.data[0][0];
          this.eye_colors = result.data.data[0][1];
          this.hair_colors = result.data.data[0][2];
          this.body_types = result.data.data[0][3];
          this.annualIncomes = result.data.data[0][5];
          this.educations = result.data.data[0][6];
          this.religions = result.data.data[0][7];
          this.usa_statuses = result.data.data[0][8];
          this.marital_status = result.data.data[0][10];
          this.languages = result.data.data[0][9];
        })

        .catch((err) => { });
    },



    async getSavedMatchCriteria() {
      await this.axios
        .get("get_match_criteria/" + JSON.parse(localStorage.getItem("user"))[0].member_id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result, "MatchCriteriaresult");
          this.smoke = result.data[0].smoke
          this.haveChild = result.data[0].have_childern
          this.wantChild = result.data[0].want_childern
          this.countryoforigin = result.data[0].origin
          this.country = result.data[0].country
          this.state = result.data[0].state
          // console.log(this.state,"GetSaveMatchData")

          const age = result.data[0].age_range.split(",")
          this.age_from = age[0]
          this.age_to = age[1]
          const height = result.data[0].height_range.split(",")
          this.height_from = height[0]
          this.height_to = height[1]
          const eyeColour = result.data[0].eye_color.split(",");
          eyeColour.forEach(element => {
            const colors = parseInt(element);
            this.eyeColor.push(colors);
          });
          const hairColour = result.data[0].hair_color.split(",");
          hairColour.forEach(element => {
            const colors = parseInt(element);
            this.hairColor.push(colors);
          });
          const btype = result.data[0].build.split(",");
          btype.forEach(element => {
            const bodytype = parseInt(element);
            this.bodyType.push(bodytype);
          });
          const Professional = result.data[0].profession.split(",");
          Professional.forEach(element => {
            const Professionals = parseInt(element);
            this.profession.push(Professionals);
          });
          const annualincomes = result.data[0].annual_income.split(",");
          annualincomes.forEach(element => {
            const Annualincomes = parseInt(element);
            this.annualIncome.push(Annualincomes);
          });
          const Education = result.data[0].education.split(",");
          Education.forEach(element => {
            const Educations = parseInt(element);
            this.education.push(Educations);
          });
          const UsaStatus = result.data[0].usa_status.split(",");
          UsaStatus.forEach(element => {
            const UsaStatuses = parseInt(element);
            this.status.push(UsaStatuses);
          });
          const religion = result.data[0].religion.split(",");
          religion.forEach(element => {
            const Religion = parseInt(element);
            this.religion.push(Religion);
          });
          const Language = result.data[0].language.split(",");
          Language.forEach(element => {
            const Languagees = parseInt(element);
            this.language.push(Languagees);
          });
          const MaritalStatus = result.data[0].marital_status.split(",");
          MaritalStatus.forEach(element => {
            const Maritalstatus = parseInt(element);
            this.maritalStatus.push(Maritalstatus);
          });
        })

        .catch((err) => { })
        .finally(() => {
                    });
    },


  },

  mounted() {

    // if(this.$route.query.criteria_id && this.$route.query.criteria_id != ''){
    //   this.getSavedMatchCriteria();
    // }




    this.getReferenceData();
    this.getcountries();
    // this.getstates();
    this.getProfession();
    this.getSavedMatchCriteria();
    this.getstates();
    // setTimeout(() => {
    //   this.LoadingTimingFlag=true;
    // }, 2500)
  },
};
</script>

<style scoped>
.user-setting-section .input-info-box .content .my-input-box input {
  height: auto !important;
}

.row {
  align-items: baseline;
}

.img-fluid {
  width: 120px;
  height: 120px;
  display: block;
  cursor: pointer;
  margin: auto;
  background-size: cover;
  background-position: center center;
}
</style>
