<template>
  <loader v-if="this.LoadingTimingFlag == false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2"
    bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
  <section class="community-section inner-page" style="margin-top: 50px;">
    <div class="container">

      <div v-if="members">
        <h4><i class="fas fa-search"></i> Recently Joined</h4>
        <br />

        <div class="row">
          <div v-for="(member, index) in members" :key="member" class="col-lg-4 col-md-6">
            <section class="profile-section">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="left-profile-area" style="box-shadow: rgb(176 171 193 / 40%) 0px 5px 30px 0px;">
                      <div class="profile-about-box">
                        <div class="top-bg"></div>
                        <div class="p-inner-content">
                          <div class="profile-img">
                            <!-- <img src="assets/images/profile/profile-user.png" alt=""> -->
                            <img v-if="member.members.image == null && member.members.gender == 'Male'"
                              src="../../public/assets/isna-images/dummyImage.png" style="width: 120px; height: 120px;">
                              <img v-if="member.members.image == null && member.members.gender == 'Female'"
                              src="../../public/assets/isna-images/femaledummy.jpg" style="width: 120px; height: 120px;">
                            <img v-if = "member.members.image != null" :src="member.member_image" alt="" style="width: 120px; height: 120px; object-fit: contain;" />
                            <!-- <div class="active-online"></div> -->
                          </div>
                          <router-link :to="{
                            path: '/profile',
                            query: {
                              member_id: `${member.members.member_id}`,
                            },
                          }">
                            <a class="name">
                              {{  member.members.first_name  }} {{ member.members.last_name }}
                            </a>
                          </router-link>
                          <ul class="p-b-meta-one" style="display: flex; justify-content: center;">
                            <li>
                              {{  getAge(member.members.d_o_b)  }}
                            </li>
                            <li v-if="member.meta[13] && member.meta[13].meta_value == '1'">| Never Married</li>
                            <li v-else-if="member.meta[13] && member.meta[13].meta_value == '2'">| Divorced</li>
                            <li v-else-if="member.meta[13] && member.meta[13].meta_value == '3'">| Widow/Widower</li>
                            <li>
                              | Muslim
                            </li>

                          </ul>
                          <p v-if="member.members.country == '223'">{{  member.members.city  }}, USA</p>
                          <p v-else>{{  member.members.city  }}, Canada</p>
                          <!-- <p> <i class="fas fa-map-marker-alt"></i>{{ member.members.city}},{{member.members.country}}</p> -->
                          <div class="p-b-meta-two" style="justify-content: center;">
                             <div class="right">
                              <button style="background-image: linear-gradient(166deg, #f22876 0%, #942dd9 100%) !important;" class="custom-button" v-if="!members_fav.includes(member.members.member_id)"
                                @click="favProfile(member.members.member_id)">
                                Add To Favourite
                              </button>
                              <!-- {{member.members.member_id}} -->
                              <!-- <div>{{checkFav1(member.members.member_id,index)}}</div> -->
                              <!-- <p class="alert alert-success"  v-if="fav_type == true">You Added,{{member.members.member_id}}</p> -->
                              <button style="background-image: linear-gradient(166deg, #f22876 0%, #942dd9 100%) !important;" class="custom-button" v-if="members_fav.includes(member.members.member_id)"
                                @click="favProfile(member.members.member_id)">
                                Remove Favourite
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { isSet } from 'lodash';

export default {
  name: "members",
  props: ["member_gender"],
  data() {
    return {
      LoadingTimingFlag: false,
      members: "",
      members_fav: [],
      image: "",
      favs: [],
      aged: [],
      Recentcountries: "",
      fav_type: true,
      message: '',
      fav_data:[],
      member_id:[],
    };
  },
  methods: {

    getRecentMembers() {
       this.axios
        .get("dashboard_members" , {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.members = result.data.data.members;
          console.log(this.members,"dsfdsfdsfdsfdsf");
          this.getfav();
        })
        .catch((err) => { });
    },

    getfav() {
      this.LoadingTimingFlag = false;
      for(let i =0 ; i<this.members.length;i++){
        this.axios
        .get("check_profile_favorite/" + this.members[i].members.member_id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
           this.members_fav.push(result.data.data.fav_profiles[0].favorite_id);
        })
        .catch((err) => { })
        .finally(() => {
          this.LoadingTimingFlag = true;
                    });
      }
    },




    // checkFav() {


    //   // return 1;
    //   this.axios
    //     .get("check_profile_favorite/" + member_id, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       },
    //     })
    //     .then((result) => {
    //       console.log(result,"Favorite");
    //       this.fav_data=result.data.data.fav_profiles.length;
    //       if (result.data.data.fav_profiles = 28468) {
    //         console.log(result.data.data.fav_profiles,"Fav_profile");
    //         this.fav_type = true;
    //         // return this.fav_type;
    //       }
    //       // else{
    //       //   this.fav_type = false;
    //       // }
    //     })
    //     .catch((err) => { });
    // },



    async favProfile(member_id) {
      var formBody = new FormData();
      formBody.append("favorite_id", member_id),
        formBody.append("type", this.fav_type);
      await this.axios
        .post("profile_favorite", formBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result,"Fav_result");
          this.members_fav.splice(0);
          this.getfav();
          this.fav_type = !this.fav_type;

        })
        .catch((err) => { });


    },
    getAge(Age) {
      // console.log(Age, "Addufb")
      var dateString = Age;

      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      // console.log(age);
      this.age = age;
      return age;
    }
  },
  computed: {
  },
  mounted() {
    window.scrollTo(0,0)
    this.getRecentMembers();

  },
};
</script>
<style scoped>
.active {
  background-image: linear-gradient(166deg, #f22876 0%, #942dd9 100%);
  color: #fff;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .name {
  font-size: 18px;
}

.custom-button {
  width: 155px;
  text-align: center;
  font-size: 12px;
  margin-left: 3px;
  background-image: linear-gradient(-51deg, #f664bc 0%, #fb7bbc 35%, #ff92bb 100%) !important;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img {
  top: -110px;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content {
  padding: 38px 30px 30px;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .p-b-meta-one li {
  margin-right: 5px;
  font-size: 16px;
}

.profile-section {
  padding: 10px 0px 20px;
}


@media (min-width: 992px) and (max-width: 1330px) {
  .profile-section .left-profile-area {
    width: 290px;
  }
}

@media (min-width: 300px) and (max-width: 450px) {
  .profile-section .left-profile-area .profile-about-box .p-inner-content .name {
    font-size: 15px;
  }

  .profile-section .left-profile-area .profile-about-box .p-inner-content .p-b-meta-one li {
    margin-right: 5px;
    font-size: 14px;
  }
}
</style>
