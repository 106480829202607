<template>
    <section class="product-category-2 profile-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="tab-content tabStyling" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-gird" role="tabpanel" aria-labelledby="pills-gird-tab">
                <div class="row">
                  <div class="col-md-8 col-sm-12">
                    <h4 class="p-3">Gallery</h4>
                  </div>
                  <div class="col-md-4 col-sm-12 right">
                    <div class="top-filter">
                      <div class="left right">
                        <router-link to="gallery" v-if="this.$route.name != 'Profile'">Manage Gallery</router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="access_level == 3 || access_level == 2 && isLoggedIn">
                  <div v-for="gallery_image in gallery_images" :key="gallery_image" class="col-lg-3 col-md-3">
                    <div class="single-product">
                      <div class="img">
                        <img :src="gallery_image" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-else-if="access_level == 3 || !isLoggedIn">
                  <div class="col-lg-6 col-md-6 col-md-12">
                    <p style="color: #f22876">
                      To View Media:
                      <router-link class="custom-button" to="/register">Register Now</router-link>
                    </p>
                  </div>
                  <div class="col-lg-6 col-md-6 col-md-12">
                    <p style="color: #f22876">
                      Already a Member?
                      <router-link class="custom-button" to="/login">Login Now</router-link>
                    </p>
                  </div>
                </div>
                <div class="privacy" v-else>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <div class="col-lg-12 col-md-12">
                        <p style="color: #f22876">Member's gallery is private!</p>
                      </div>
                      <div class="alert alert-success" v-if="request_sent" role="alert">
                        Request Sent Successfully!
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 right">
                      <div class="top-filter" v-if="isLoggedIn">
                        <form>
                          <div class="right">
                            <button v-if="access_level == 1" type="submit" class="custom-button"
                              @click.prevent="requestAccess">
                              Request Access
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <!-- <div class="col-lg-12 col-md-12">
                      <p style="color: #f22876">{{ privacy_message }}</p>
                    </div>
                    <div class="alert alert-success" v-if="request_sent" role="alert">
                      Request Sent Successfully!
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>

  <script>
  export default {
    name: "MemberGallery",
    props: ["member_id"],
    data() {
      return {
        request_sent: false,
        gallery_images: [],
        privacy_message: "",
        path: null,
        access_level: "",
        LoadingTimingFlag: false
      };
    },
    methods: {
      async getImages() {
        await this.axios
          .get(this.path + this.$props.member_id, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })

          .then((result) => {
            if (result && result.data.data.gallery) {
              this.gallery_images = result.data.data.gallery;
            }
          })
          .catch((err) => { });
      },

      async getMediaAccessLevel() {
        await this.axios
          .get("get_member_profile/" + this.$props.member_id, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })

          .then((result) => {
            const access = result.data.data.member_profile[0].gallery_access;
            if(access == 1){
              this.getAccessRequests();
            }else{
              this.access_level = access;
              this.getImages();
            }
          })
          .catch((err) => { });
      },

      async requestAccess() {
        var formBody = new FormData();
        formBody.append("to_member", this.$props.member_id);
        await this.axios
          .post("/request_gallery_access", formBody, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })

          .then((result) => {
            this.request_sent = true;
          })
          .catch((err) => { });
      },

      async getAccessRequests() {
        await this.axios
          .get("get_allowed_users/"+ this.$props.member_id,{
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            console.log(result.data.data,"Test")
            if (result.data.data.requests != "") {
              console.log("Test2")
              this.access_requests = result.data.data.requests;
              this.access_requests.forEach(element => {

                if(element.status == 1){
                  this.access_level = 2;
                  this.getImages();
                }else{
                  this.access_level = 1;
                }
              });

            }
            else {
              this.access_level = 1;
              // this.request_message = "No Requests Received Yet!";
            }
          })
          .catch((err) => { });
      },
    },

    computed: {
      isLoggedIn() {
        return (this.isLoggedIn = localStorage.getItem("token"));
      },
    },
    mounted() {

      const called_from = this.$route.name;
      if (called_from == "Profile") this.path = "/get_member_gallery/";
      else this.path = "/get_dashboard_gallery/";

      this.getMediaAccessLevel();
    },
  };
  </script>

  <style scoped>
  #usernotification .modal-body .header-area {
    border-bottom: 1px solid black;
  }
  .right{
    text-align: right;
  }
  .my-input-box {
    display: flex;
  }

  label {
    padding: 10px;
  }

  input[type="file"] {
    border: none !important;
  }

  .single-product img {
    height: 200px;
  }

  #accesscontrol .modal-body .notification-list .single-list:hover {
    background: none;
  }

  #accesscontrol.modal-body .notification-list .single-list {
    padding: 20px 30px 20px;
  }

  @media screen and (min-width: 800px) {
    .modal-dialog {
      width: 50%;
    }
  }

  .modal-body .col-md-12 {
    padding: 10px;
  }
  </style>
