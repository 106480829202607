<template>
  <div>
    <!-- ==========Overlay========== -->
    <div class="overlay"></div>
    <a href="#" class="scrollToTop">
      <i class="fas fa-angle-up"></i>
    </a>
    <!-- ==========Overlay========== -->

    <!-- ==========Header-Section========== -->
    <header
      class="header-section"
      v-if="$route.name !== 'Login' && $route.name !== 'Register'"
    >
      <div class="container">

        <!-- Header for guest users -->
        <div class="header-wrapper" >
          <div class="logo">
            <router-link v-if="isLoggedIn" to="/dashboard">
              <img src="assets/images/logo/brand_logo.png" alt="logo" />
            </router-link>
              <router-link v-else to="/">
              <img src="assets/images/logo/brand_logo.png" alt="logo" />
            </router-link>
          </div>



          <ul class="menu">
            <li>
              <router-link to="/faqs" @click="gotoTop()">FAQ's</router-link>
            </li>
            <li>
              <router-link to="/about" @click="gotoTop()">About Us</router-link>
            </li>
            <li>
              <router-link to="/contact" @click="gotoTop()">Contact</router-link>
            </li>

            <li>
              <router-link to="/articles" @click="gotoTop()">Articles</router-link>
            </li>
            <li class="separator">
              <span>|</span>
            </li>
            <li class="user-profile menu-item-has-children">
              <a href="#">
                <img src="assets/images/user-demo.png" alt="" />
              </a>
              <ul class="submenu" v-if= "this.isLoggedin == '' || this.isLoggedin == null">
                <li>
                  <router-link to="/login">Login</router-link>
                </li>

                <li>
                  <router-link to="/register">Register</router-link>
                </li>
              </ul>
              <ul class="submenu" v-else>
                <li>
                  <router-link to="/updateprofile">Update Profile</router-link>
                </li>
                <li @click.prevent="manageSubscription">
                  <a>Manage Subscription</a>
                </li>
                <li @click.prevent="logout">
                  <a>Logout</a>
                </li>
              </ul>
            </li>
          </ul>

          <div class="header-bar d-lg-none">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <!-- header for members -->
        <!-- <div class="header-wrapper" v-if="isLoggedIn && isActive != 'null'">
          <div class="logo">
            <router-link to="/dashboard">
              <img src="assets/images/logo/brand_logo.png" alt="logo" />
            </router-link>
          </div>

          <ul class="menu">
            <li>
              <router-link to="/dashboard">Dashboard</router-link>
            </li>
            <li>
              <router-link to="/advance-search">Advance Search</router-link>
            </li>

            <li>
              <router-link to="/chat">Chat</router-link>
            </li>

            <li class="separator">
              <span>|</span>
            </li>
            <li class="user-profile menu-item-has-children">
              <a href="#">
                <img src="assets/images/user-demo.png" alt="" />
              </a>
              <ul class="submenu">
                <li>
                  <router-link to="/match">Match Criteria</router-link>
                </li>
                <li>
                  <router-link to="/updateprofile">Update Profile</router-link>
                </li>
                <li @click="logout">
                  <a>Logout</a>
                </li>
              </ul>
            </li>
          </ul>

          <div class="header-bar d-lg-none">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div> -->
      </div>
    </header>

    <div class="search-overlay">
      <div class="close"><i class="fas fa-times"></i></div>
      <form action="#">
        <input type="text" placeholder="Write what you want.." />
      </form>
    </div>
    <!-- ==========Header-Section========== -->

    <!-- ==========Breadcrumb-Section========== -->
    <section
      v-if="
        $route.name !== 'Home' &&
        $route.name !== 'Login' &&
        $route.name !== 'Register' &&
        $route.name !== 'Profile' &&
        $route.name !== 'Dashboard'&&
        $route.name !== 'Update Profile'&&
        $route.name !== 'Saved Searches' &&
        $route.name !== 'Match Criteria' &&
        $route.name !== 'Gallery' &&
        $route.name !== 'Favouurite Profiles' &&
        $route.name !== 'Advance Search' &&
        $route.name !== 'Match Profiles' &&
        $route.name !== 'Search Results'
      "
      class="custom-breadcrumb breadcrumb-area profile-bc-area"
    >
      <div class="container">
        <div class="content" v-if="$route.name !== 'Chat'">
          <div class="row">
            <div class="col-6">
              <h2 class="title extra-padding">
                {{ $route.name }}
              </h2>
            </div>
            <div class="col-6">
              <ul class="breadcrumb-list extra-padding d-flex justify-content-end">
                <li v-if="!isLoggedIn">
                  <router-link to="/"> Home </router-link>
                </li>
                <li v-else>
                  <router-link to="/dashboard"> Home </router-link>
                </li>
                <li>
                  {{ $route.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==========Breadcrumb-Section========== -->
  </div>
</template>

<script>
export default {
  name: "PublicHeader",
  data() {
    return {
      isLoggedin: '',
    };
  },
  methods: {
    async logout() {
      this.axios
        .post("logout", null, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          localStorage.removeItem("user");
          localStorage.removeItem("redirect_url");
          localStorage.removeItem("token");
          localStorage.removeItem("subscription");
          localStorage.removeItem("check_subscription");
          localStorage.removeItem("billing");
          localStorage.removeItem("setupTime");
          window.location.replace("/");
        })
        .catch((error) => {
          //location.reload();
          console.log(error);
        });
    },
    async manageSubscription(){
      await this.axios
        .post("manage_subscription", null, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log(response);
          window.location.href = response.data.data.session.url;
        })
        .catch((error) => {
          //location.reload();
          console.log(error);
        });

    },
    checkLoggedIn() {
      console.log("isLoggedIn");
      this.isLoggedin = localStorage.getItem("token");
      console.log(this.isLoggedin,"login");
    },
  },

  computed: {
    isLoggedIn() {
      return (this.isLoggedIn = localStorage.getItem("token"));
    },

    isActive() {
      return (this.isActive = localStorage.getItem("subscription"));
    },
   gotoTop() {
    window.scrollTo(0,0)
      },

  },

  mounted() {
    this.checkLoggedIn();
    $(".header-bar").on("click", function () {
      $(".menu").toggleClass("active");
      $(".header-bar").toggleClass("active");
      $(".overlay").toggleClass("active");
    });
    $(".menu").on("click", function () {
      $(".menu").removeClass("active");
      $(".header-bar").removeClass("active");
      $(".overlay").removeClass("active");
    });
    //Menu Dropdown Icon Adding
    $("ul>li>.submenu").parent("li").addClass("menu-item-has-children");
    // drop down menu width overflow problem fix
    $("ul")
      .parent("li")
      .hover(function () {
        var menu = $(this).find("ul");
        var menupos = $(menu).offset();
        if (menupos.left + menu.width() > $(window).width()) {
          var newpos = -$(menu).width();
          menu.css({
            left: newpos,
          });
        }
      });
    $(".menu li a").on("click", function (e) {
      var element = $(this).parent("li");
      if (element.hasClass("open")) {
        element.removeClass("open");
        element.find("li").removeClass("open");
        element.find("ul").slideUp(300, "swing");
      } else {
        element.addClass("open");
        element.children("ul").slideDown(300, "swing");
        element.siblings("li").children("ul").slideUp(300, "swing");
        element.siblings("li").removeClass("open");
        element.siblings("li").find("li").removeClass("open");
        element.siblings("li").find("ul").slideUp(300, "swing");
      }
    });

    var headerOne = $(".header-section");
    $(window).on("scroll", function () {
      if ($(this).scrollTop() < 1) {
        headerOne.removeClass("header-active");
      } else {
        headerOne.addClass("header-active");
      }
    });
    $(".window-warning .lay").on("click", function () {
      $(".window-warning").addClass("inActive");
    });
    $(".seat-plan-wrapper li .movie-schedule .item").on("click", function () {
      $(".window-warning").removeClass("inActive");
    });
  },
};
</script>

<style scoped>

.header-wrapper .menu li .submenu li a:hover{

  cursor: pointer !important;
}
@media (max-width: 991px){
  .header-wrapper .menu li .submenu li a:hover {
      text-transform: capitalize;
      color: #e9eeff;
      cursor: pointer;
  }
}
</style>
