<template>
  <!-- ========= Profile Section Start -->

  <section class="user-setting-section">
    <div class="container">
      <div class="row">
        <Form
          @submit="addPayment"
          :validation-schema="schema"
          :initial-values="formValues"

        >
          <div class="col-xl-12 col-md-12">
            <div class="input-info-box mt-30">
              <div class="header">Select Plan</div>
              <div class="content">
                <div class="community-section inner-page">
                  <div class="container">
                    <div class="row">
                      <div class="top-filter noBorder">
                        <div class="left">
                          <label class="mr-3" v-for="plan in plans" :key="plan">
                            <Field
                              class="custom-button"
                              type="radio"
                              :value="plan.id"
                              v-model="billing_plan"
                              name="plan"
                            />
                            {{ plan.name }} , {{ plan.amount }}
                          </label>
                          <ErrorMessage class="error-msg" name="plan" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="buttons mt-30">
              <button type="submit" class="custom-button">Submit</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </section>

  <!-- ========= Profile Section Start -->
</template>

<script>
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "SubscriptionForm",

  components: {
    Field,
    Form,
    ErrorMessage,
  },

  setup() {
    // Non-reactive because it was not explicitly defined with `reactive` or `ref`
    const schema = yup.object().shape({
      // first_name: yup.string().required().nullable().label("First Name"),
      // last_name: yup.string().required().nullable().label("Last Name"),
      // add1: yup.string().required().nullable().label("Address 1"),
      // add2: yup.string().required().nullable().label("Address 2"),
      // country: yup.string().required().nullable().label("Country"),
      // state: yup.string().required().nullable().label("State"),
      // zip: yup.string().required().nullable().label("Zip"),
      // phone: yup.string().required().nullable().label("Phone"),
      // billing_plan: yup.string().required().nullable().label("Plan"),
    });

    return {
      schema,
    };
  },

  data() {
    return {
      submit: false,
      states: "",
      countries: "",
      plans: "",
      displayError: '',
      clientSecret: '',

      billing_plan: '',
    };
  },

  methods: {
    //get countries from db
    async getcountries() {
      await this.axios
        .get("get_countries", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.countries = result.data.data.countries;
        })
        .catch((err) => {});
    },
    check_sub(){
          this.axios.get("get_subscription_details/"+localStorage.getItem('subscription'), {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            })
              .then((result) => {
                console.log(result,'result');
                // this.subscription = result.data.data.status.data.length > 0 && result.data.data.status.data[0].cancel_at_period_end == false ? result.data.data.status.data[0].cancel_at_period_end : true;
                // console.log(this.subscription,'this.subscription');
                if (result.data.data.end_date != result.data.data.current_date) {
                  localStorage.setItem('check_subscription', false);
                  localStorage.setItem('billing', false);
                  this.$router.push("dashboard");
                }
              }).catch((err) => {  })
        },

    async getstates() {
      await this.axios
        .get("get_states", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.states = result.data.data.states;
        })
        .catch((err) => {});
    },

    async addPayment() {

      var formBody = new FormData();
      formBody.append("plan", this.billing_plan);
      //console.log(formBody);
      await this.axios
        .post("add_subscription", formBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result.data.data.session);
          window.location.href = result.data.data.session.url;
        })
        .catch((err) => {});
    },

    async getBillingPlans() {
      await this.axios
        .get("get_billing_plans", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result.data.data.plans.data);
          this.plans = result.data.data.plans.data;
        })
        .catch((err) => {});
    },

  },

  mounted() {
    if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
    this.check_sub();
    this.getBillingPlans();
  },
};
</script>

<style>
input {
  width: 100%;
}
</style>
