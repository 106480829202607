
<template>
<section class="faq-section" style="padding-top: 118px;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="content">
                    <div class="section-header">
                        <h6 class="sub-title extra-padding">
                            Got any Question
                        </h6>
                        <h2 class="title">
                            We’ve Got Answers
                        </h2>
                        <p class="text">
                            Try to check out our Privacy Policies
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="faq-area bulletPointShow">
            <div class="faq-wrapper">
                <div class="faq-item active open">
                    <div class="faq-title">
                        <h6 class="title">ISNA Online Matrimonial website</h6>
                        <span class="right-icon"></span>
                    </div>
                    <div class="faq-content">
                        <p>is powered, monitored, and maintained by the Islamic Society of North America (ISNA).
                            This service is designed to provide a convenient way to its members to interact with one
                            another on the Web. To accomplish this, our members create their profiles and our
                            matching search capability provides them matches based on their pre-selected criteria.
                            Personal member information in this database cannot be used by the public (site
                            visitors), other members, or anyone not employed by ISNA.</p>

                        <p>We are a subscription-based service. We collect credit card, address, or bank
                            information when members choose to subscribe to our fee-based services.</p>
                    </div>
                </div>
                <div class="faq-item">
                    <div class="faq-title">
                        <h6 class="title">Information submitted to ISNA Matrimonials </h6>
                        <span class="right-icon"></span>
                    </div>
                    <div class="faq-content">
                        <p>There are two types of user-submitted information we collect: Public and Private.</p>
                        <p><b>
                                <li>Public</li>
                            </b> Information is defined as information that may be displayed on the site,
                            such as
                            gender, age, height etc. but cannot be linked to a particular user.
                        </p>
                        <p><b>
                                <li>Private</li>
                            </b> Information is defined as personal information such as name, address, email, phone
                            number, credit card information, and any other contact information.
                        </p>
                        <p><b>
                                <li>Public</li>
                            </b>To accomplish our goal of bringing compatible people together, we must gather
                            information, which may or may not include customer-specified ISNA Matrimonials username,
                            gender, age, ethnic background, appearance, school of thought, income range, occupation,
                            and general geographic location. Much of this information is optional, and meant to be
                            seen by members and visitors to the site but cannot be used to identify a specific
                            member by name, address, etc.</p>
                        <p><b>
                                <li>Private</li>
                            </b> This information is gathered from members and visitors who apply for the various
                            services our site offers.</p>
                        <p>This information includes, but is not limited to: email address, first name, last name,
                            birth date, credit card number or checking account information for payment, a
                            user-specified password, mailing address, zip code and telephone number.</p>
                    </div>
                </div>
                <div class="faq-item">
                    <div class="faq-title">
                        <h6 class="title">Use of Information Obtained by ISNA Matrimonials</h6>
                        <span class="right-icon"></span>
                    </div>
                    <div class="faq-content">
                        <p>Information users submit to ISNA Matrimonials.</p>
                        <p><b>
                                <li>Public</li>
                            </b>Public information supplied by the user is intended to be viewed by visitors to the
                            site. Visitors for searching and matching purposes use this information. Public
                            information cannot be used to identify a specific member by name, address, etc.</p>
                        <p><b>
                                <li>Private</li>
                            </b>We primarily use email addresses submitted by members to verify enrollment and when
                            forwarding anonymous e-mails from ad responders. Email addresses are not disclosed to
                            the responder. We may also use a user's email address to send news regarding our site or
                            events, but the user may choose not to receive email of this type by contacting us.
                            Email addresses may also be used in private correspondence with the user for customer
                            service issues. Financial information that is collected is used to bill the user for
                            products and services. All other private information submitted by the user is
                            confidential and will not be disclosed except as required by law or to protect members
                            of ISNA Matrimonials. All private information can be updated or deleted by the user at
                            any time.</p>
                    </div>
                </div>
                <div class="faq-item">
                    <div class="faq-title">
                        <h6 class="title">Sharing of the Information this Site Gathers/Tracks</h6>
                        <span class="right-icon"></span>
                    </div>
                    <div class="faq-content">
                        <p>Except where you are expressly informed otherwise or as described immediately below, we
                            do not sell, rent, share, trade or give away any of your personal information except to
                            the financial institution that processes your credit card or check transaction unless
                            required by law or for the protection of your membership.</p>
                    </div>
                </div>
                <div class="faq-item">
                    <div class="faq-title">
                        <h6 class="title">Links</h6>
                        <span class="right-icon"></span>
                    </div>
                    <div class="faq-content">
                        <p>This site contains links to other websites. Please be aware that ISNA Matrimonials is not
                            responsible for the privacy practices of other Web sites. We encourage our users to be
                            aware when they leave our site and to read the privacy statements of each and every web
                            site that collects personally identifiable information. This privacy statement applies
                            solely to information collected by this Web site.</p>
                    </div>
                </div>
                <div class="faq-item">
                    <div class="faq-title">
                        <h6 class="title">Correcting/Updating or Removing Information</h6>
                        <span class="right-icon"></span>
                    </div>
                    <div class="faq-content">
                        <p>ISNA Matrimonials gives users several options for modifying or removing their profile
                            information from our database at any time. Please use the Edit My Profile button to make
                            any changes. If you want to change your main email address contact information, please
                            use the 'Edit My Contact Info' button.</p>
                    </div>
                </div>
                <div class="faq-item">
                    <div class="faq-title">
                        <h6 class="title">Security</h6>
                        <span class="right-icon"></span>
                    </div>
                    <div class="faq-content">
                        <p>ISNA Matrimonials takes every precaution to protect our users' information. When users
                            submit sensitive information via the website, your information is protected both online
                            and off-line. When our registration/order process asks users to enter sensitive
                            information, (such as a credit card number) that information is encrypted and is
                            protected with the best encryption software in the industry - SSL. While we use SSL
                            encryption to protect sensitive information online, we also do everything in our power
                            to protect user information off-line. All of our users' information, not just the
                            sensitive information mentioned above, is restricted in our offices. Only employees who
                            need the information to perform a specific job (for example a billing clerk or a
                            customer service representative) are granted access to personally identifiable
                            information. If you have any questions about the security at our website, please contact
                            us.</p>
                    </div>
                </div>
                <div class="faq-item">
                    <div class="faq-title">
                        <h6 class="title">Cancellation Policy:</h6>
                        <span class="right-icon"></span>
                    </div>
                    <div class="faq-content">
                        <p>Members may cancel at any time but no refund will be given if your membership plan hasn't
                            ended yet.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
export default {
    name: "PrivacyPolicy",

     mounted() {
    //Faq
    $(".faq-wrapper .faq-title").on("click", function (e) {
      var element = $(this).parent(".faq-item");
      if (element.hasClass("open")) {
        element.removeClass("open");
        element.find(".faq-content").removeClass("open");
        element.find(".faq-content").slideUp(300, "swing");
      } else {
        element.addClass("open");
        element.children(".faq-content").slideDown(300, "swing");
        element.siblings(".faq-item").children(".faq-content").slideUp(300, "swing");
        element.siblings(".faq-item").removeClass("open");
        element
          .siblings(".faq-item")
          .find(".faq-title, .faq-title-two")
          .removeClass("open");
        element.siblings(".faq-item").find(".faq-content").slideUp(300, "swing");
      }
    });
  },
}
</script>