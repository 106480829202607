<template>
   <loader v-if="this.loaderShow != true" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2"
    bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
  <div>
    <!-- ========== Login & Registation Section ========== -->
    <section class="log-reg">
      <div class="top-menu-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <router-link to="/" class="backto-home"
              style="background: linear-gradient(166deg, #f22876 0%, #942dd9 100%);
              border-radius: 52px; padding: 16px; margin-top: 20px; color: white;"
                ><i class="fas fa-chevron-left"></i> Back to Isna
                Matrimonials</router-link
              >
            </div>
            <div class="col-lg-7">
              <!-- <div class="logo">
                <img src="assets/images/logo2.png" alt="logo" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-end">
          <div class="image"></div>
          <div class="col-lg-7">
            <div class="log-reg-inner">
              <!-- <div class="section-header">
                                <h2 class="title"> Welcome to Isna Matrimonials </h2>
                                <p> Let's create your profile! Just fill in the fields below, and we’ll get a new account.</p>
                            </div> -->
              <div class="main-content">
                <!-- .prevent shortcut will stop prevent by default -->
                <form @submit.prevent="register" method="post">
                  <h4 class="content-title">Join us today!</h4>
                  <div class="form-group">
                    <span class="alert-danger" role="alert" v-if="result">{{
                      result
                    }}</span>
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      v-model="first_name"
                      class="my-form-control"
                      placeholder="Enter Your First Name"
                      required
                    />
                    <!-- <span class="alert-danger" role="alert" v-if="v$.first_name.$error">{{ v$.nick_name.$errors[0].$message }}</span> -->
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      v-model="last_name"
                      class="my-form-control"
                      placeholder="Enter Your Last Name"
                      required
                    />
                    <!-- <span class="alert-danger" role="alert" v-if="v$.last_name.$error">{{ v$.nick_name.$errors[0].$message }}</span> -->
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      v-model="email"
                      class="my-form-control"
                      placeholder="Enter Your Email"
                      required
                    />
                    <!-- <span class="alert-danger" role="alert" v-if="v$.email.$error">{{ v$.nick_name.$errors[0].$message }}</span> -->
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      v-model="password"
                      class="my-form-control"
                      placeholder="Enter Your Password"
                      required
                    />
                    <!-- <span class="alert-danger" role="alert" v-if="v$.password.$error">{{ v$.nick_name.$errors[0].$message }}</span> -->
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      v-model="password_confirmation"
                      class="my-form-control"
                      placeholder="Enter Your Confirm Password"
                      required
                    />
                    <!-- <span class="alert-d-anger" role="alert" v-if="v$.confirm_password.$error">{{ v$.nick_name.$errors[0].$message }}</span> -->
                  </div>
                  <input type="hidden" v-model="member_type_id" />
                  <button type="submit" class="custom-button">Create your Profile</button>
                  <div class="or-content" style="text-align: start;margin-top: 10px;">
                    <!-- <p>Sign up with your email</p>
                    <a href="#" class="or-btn"
                      ><img src="assets/images/google.png" alt="" /> Sign Up with
                      Google</a
                    > -->
                    <p class="or-signup">
                      If have an account?
                      <router-link to="/login">Login Now</router-link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- ========== Login & Registation Section ========== -->

    <!-- Modal -->
    <div class="modal fade" id="email-confirm" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog" style="max-width: 700px">
        <div class="modal-content">
          <div class="modal-body">
            <div class="top-img">
              <img src="assets/isna-images/log.jpg" alt="" />
            </div>
            <div class="main-content">
              <h4 class="title">Check Your Inbox, Please!</h4>
              <p>
                A link to verify account has been sent to the email supplied. Please open
                the link and follow the prompts to verify email.
              </p>
              <p class="send-again">Didn't get e-mail? <a href="#">Send it again</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",

  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      member_type_id: 4,
      result: "",
      loaderShow: true,
    };
  },

  methods: {
    async register() {
      this.loaderShow = false;
      await this.axios
        .post("register", {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          member_type_id: this.member_type_id,
        })

        .then((res) => {
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.data.user));
          localStorage.setItem('check_subscription', true);
          localStorage.setItem('subscription', null);


          this.$router.push("UpdateProfile");
        })

        .catch((error) => {
          this.result = error.response.data.errors.password[0];
          if(error.response.data.errors.email[0].length > 0){
            this.result = error.response.data.errors.email[0];
          }
        })
        .finally(() => {
          this.loaderShow = true;
                    });
    },
  },
};
</script>

<style scoped>
input[type="email"],
input[type="password"] {
  width: 100%;
}
</style>
