<template>
    <div>

        <!-- ==========Start-Flirting-Section========== -->
        <section class="flirting-section about-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 align-self-center">
                        <div class="img">
                            <img src="assets/isna-images/about-us.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="content">
                            <div class="section-header">
                                <h6 class="sub-title">
                                    Get to Know More
                                </h6>
                                <h2 class="title">
                                    About us
                                </h2>
                                <p>
                                    <b>ISNA Matrimonials</b> is a web-based matrimonial service for single Muslims in United
                                    States
                                    and Canada who are seeking a spouse. This website provides a secure Islamic platform for
                                    single Muslims and/or their parents to create a member profile and interact with other
                                    members in this database. Our mission is to provide service that can help the
                                    individuals, as well as their parents, in finding a suitable match in the shortest
                                    period of time. We strive to improve this service by adding new features and constantly
                                    expanding the member database.
                                </p>
                                <br>
                                <p class="mb-0">
                                    Our staff is dedicated to helping you in finding your spouse and we will ensure that in
                                    this process you get the best customer service experience.
                                </p>
                                <br>
                                <p class="mb-0">We thank you for providing us the opportunity to helping you in an important
                                    decision of your life.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- ==========Start-Flirting-Section========== -->

        <!-- ========== w-c-u Section Starts ========== -->
        <section class="w-c-u-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="content">
                            <div class="section-header">
                                <h6 class="sub-title">
                                    How We’re different
                                </h6>
                                <h2 class="title extra-padding">
                                    Why Choose Us?
                                </h2>
                                <p class="text">
                                    There are lots of online matrimonial sites available which makes it difficult
                                    to choose the one which can give you a serious partner....
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6">
                        <div class="single-w-c-u-box">
                            <div class="icon">
                                <img src="assets/images/e-c-u/icon2.png" alt="">
                            </div>
                            <h4 class="title">
                                Great Advices
                            </h4>
                            <p>
                                Matrimonial - Where two people who are attracted to each other spend time together.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="single-w-c-u-box">
                            <div class="icon">
                                <img src="assets/images/e-c-u/icon3.png" alt="">
                            </div>
                            <h4 class="title">
                                24/7 Support
                            </h4>
                            <p>
                                Matrimonial - Where two people who are attracted to each other spend time together.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="single-w-c-u-box">
                            <div class="icon">
                                <img src="assets/images/e-c-u/icon4.png" alt="">
                            </div>
                            <h4 class="title">
                                Relationship
                            </h4>
                            <p>
                                Matrimonial - Where two people who are attracted to each other spend time together.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- ==========  w-c-u Section Starts ========== -->
    </div>
</template>

<script>
export default {
  name: 'AbouView'
}
</script>
