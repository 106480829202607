<template>

    <div class="single-community-menu">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 menu">
                    <ul class="top-menu">
                        <li>
                            <router-link to="dashboard" :class="{ active: $route.name=='Dashboard' }">Dashboard </router-link>
                        </li>
                        <li>
                            <router-link to="/updateprofile" :class="{ active: $route.name=='Update Profile' }">Edit Profile </router-link>
                        </li>
                        <li>
                            <router-link to="/gallery" :class="{ active: $route.name=='Gallery' }"> Gallery</router-link>
                        </li>
                        <li>
                            <router-link to="/saved-search" :class="{ active: $route.name=='Saved Searches' }">Saved Search  </router-link>
                        </li>
                        <li>
                            <router-link to="/match" :class="{ active: $route.name=='Match Criteria' }"> Your Match</router-link>
                        </li>
                        <li>
                            <router-link v-if="this.route.length > 0 " :to= "this.route" :class="{ active: $route.name=='Chat' }"> Messages
                                <!-- <div class="num">47</div> -->
                            </router-link>
                            <router-link v-else to= "/chat" :class="{ active: $route.name=='Chat' }"> Messages
                                <!-- <div class="num">47</div> -->
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/favourite-profiles" :class="{ active: $route.name=='Favouurite Profiles' }">Favourite Profiles</router-link>
                        </li>
                        <li>
                            <router-link to="/match-profiles" :class="{ active: $route.name=='Match Profiles' }">Match Profiles</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: 'TopMenu',

    data() {
    return {
      chats: "",
      route: "",
    };
  },
  methods:{
    fetchChats() {
      console.log(this.chats,"Test")
      axios
        .get("/chats", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          this.chats = res.data.data.chats;
          this.route = "/chat?member_id="+this.chats[0].members.id;
          console.log(this.chats[0].members.id,"Test")
        });
    },
  },
    mounted() {
    this.fetchChats();
  },
}

</script>
<style scoped>
.single-community-menu .top-menu{
    text-align: left;
}
</style>
