<template>
  <loader v-if="this.LoadingTimingFlag == false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2"
    bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
  <div v-if="isLoggedIn">
  <DashboardHeader></DashboardHeader>
  </div>
  <div v-if="!isLoggedIn" class="custom-breadcrumb breadcrumb-area profile-bc-area">
    <h2 class="title extra-padding" style="color: white; font-size: 45px; margin-left: 105px;">Search Results</h2></div>
  <div style="margin-top: 50px;">
    <!-- ==========Community-Section========== -->
    <section class="product-category">
      <div class="container">
        <div class="row"></div>
      </div>

    </section>
    <section class="community-section inner-page" >
      <div class="container">

        <div v-if="this.$route.query.search == 'basic-search'">
          <div class="row">
            <div v-for="member in this.members" :key="member" class="col-lg-12 ">
              <!-- {{member}} -->
              <div class="single-friend col-lg-12 col-md-12">
                  <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                    <center>
                      <img v-if="(member.members.image==null || member.members.image=='') && (member.members.gender == 'Male')" src="../../../public/assets/isna-images/dummyImage.png" alt="" />
                      <img v-if="(member.members.image==null || member.members.image=='') && (member.members.gender == 'Female')" src="../../../public/assets/isna-images/femaledummy.jpg" alt="" />
                      <img v-else :src="member.member_image"  alt="" style="height: 150px; object-fit: cover; object-position: top;" />
                      </center><br /><br />
                    <!-- <center> <button class="FavButton" @click="favProfile(member.members.member_id)"> Add To Favourite</button></center> -->
                  </div>
                  <div class="content col-lg-7 col-md-9">
                    <router-link :to="{
                      path: '/profile',
                      query: {
                        member_id: `${member.members.member_id}`,
                      },
                    }">
                      <a style="margin-bottom: 5px;" class="name">
                        {{ member.members.first_name }} {{ member.members.last_name }}
                        <span class="isvarify">
                          <!-- <i class="fas fa-check-circle"></i> -->
                        </span>
                      </a>
                    </router-link>
                    <!-- <p class="date">
                      {{ member.members.city }} , {{ member.members.name }}
                    </p> -->
                    <div class="col-lg-12 col-xl-12" style="padding-left: 0px;">
                      <ul style="display: flex; justify-content: space-between;">
                        <li style="text-align: center;">
                          <img src="../../../public/assets/isna-images/age-group.png" alt="" class="ImageIconSetting"
                            style="width: 29px;height: 28px;">
                          <p>{{ getAge(member.members.d_o_b) }}</p>
                        </li>
                        <li v-if="member.marital_status.length != 0 && member.marital_status[0].meta_value != ''" style="text-align: center;">
                          <img src="../../../public/assets/isna-images/engagement-ring.png" alt=""
                            style="width: 29px;height: 28px;">
                          <p v-if="member.marital_status.length != 0 && member.marital_status[0].meta_value == 1"> Never Married</p>
                          <p v-if="member.marital_status.length != 0 && member.marital_status[0].meta_value == 2"> Divorced</p>
                          <p v-if="member.marital_status.length != 0 && member.marital_status[0].meta_value == 3"> Widow/Widower</p>
                          <p v-if="member.marital_status.length != 0">{{getReferenceData(member.marital_status[0].meta_value)}}</p>
                        </li>

                        <li style="text-align: center;">
                          <img src="../../../public/assets/isna-images/location-pin.png" alt=""
                            style="width: 25px;height: 26px;">
                          <p>{{ member.members.name }}</p>
                        </li>
                        <li style="text-align: center;">
                          <img src="../../../public/assets/isna-images/map.png" alt="" style="width: 25px;height: 26px;">
                          <p v-if="member.members.country=='223'">USA</p>
                          <p v-else>Canada</p>
                        </li>
                      </ul>


                    </div>
                    <div>

                    </div>
                    <p class="description">
                      <!-- {{member.meta[0].meta_value}} -->
                    </p>
                  </div>
                  <div class="col-lg-2 col-xl-2 col-md-12 col-sm-12 rightButton">

                    <div v-if="isLoggedIn" class="container">
                      <router-link style="padding-top: 10px;" class="custom-button chatButton" v-if="isLoggedIn" :to="{
                        path: '/chat',
                        query: {
                          member_id: `${member.members.member_id}`,
                        },
                      }">
                        Chat
                      </router-link>
                      <router-link v-else to="/login">
                        <i class="connnect-btn far fa-comment-dots"></i>
                      </router-link>
                    </div>
                    <!-- <button class="FavButton custom-button" @click="favProfile(member.members.member_id)"> Add To Favourite</button> -->
                    <div v-if="isLoggedIn">
                      <button  class="FavButton custom-button" v-if="!members_fav.includes(member.members.member_id)"
                        @click="favProfile(member.members.member_id)">
                        Add To Favourite
                      </button>
                      <button class="FavButton custom-button" v-if="members_fav.includes(member.members.member_id)"
                        @click="favProfile(member.members.member_id)">
                        Remove Favourite
                      </button>
                    </div>
                      <div v-if="!isLoggedIn">
                        <button style="margin-bottom: 10px;" class="FavButton custom-button"
                          @click="goto_login(member.members.member_id,check = 'chat')">
                          Chat
                        </button>
                        <button class="FavButton custom-button"
                          @click="goto_login(member.members.member_id,check = 'favourite')">
                          Add To Favourite
                        </button>
                      </div>

                  </div>
              </div>
            </div>
          </div>
          <div v-if="this.pagination.last_page > 1" >
            <div class="pagination-area text-center" style="display: flex; justify-content: center;">
              <div v-if="this.pagination.current_page > 1" >
                <a  @click.prevent="basicSearch(this.pagination.current_page - 1)" href="javascript:void(0)">
                  <i class="fas fa-angle-double-left"></i>
                </a>
              </div>
              <div v-for="page in this.pagination.links" :key="page" >
                <a  v-if="(page.label !== this.Previous) && (page.label !== this.Next)" :class=" { active:(parseInt(page.label)===parseInt(this.pagination.current_page)) }" @click.prevent="basicSearch(page.label)" href="javascript:void(0)">
                  {{ page.label }}
                </a>
              </div>
              <div  v-if="pagination.current_page < pagination.last_page">
                <a  @click.prevent="basicSearch(pagination.current_page + 1)" href="#"><i class="fas fa-angle-double-right"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row" v-if="paginatedItems">
            <div v-for="(member,index) in paginatedItems" :key="index"  class="col-lg-12">
              <div class="single-friend col-lg-12 col-md-12">
                  <div class="col-lg-3 col-md-3 col-sm-12 col-12">
                    <center>
                      <img v-if="(member.members.image==null || member.members.image=='') && (member.members.gender == 'Male')" src="../../../public/assets/isna-images/dummyImage.png" alt="" />
                      <img v-if="(member.members.image==null || member.members.image=='') && (member.members.gender == 'Female')" src="../../../public/assets/isna-images/femaledummy.jpg" alt="" />
                      <img v-else :src="member.member_image"  alt="" style="height: 150px; object-fit: cover; object-position: top;" />
                      </center><br /><br />
                    <!-- <center> <button class="FavButton" @click="favProfile(member.members.member_id)"> Add To Favourite</button></center> -->
                  </div>
                  <div class="content col-lg-7 col-md-9">
                    <router-link :to="{
                      path: '/profile',
                      query: {
                        member_id: `${member.members.member_id}`,
                      },
                    }">
                      <a style="margin-bottom: 5px;" class="name">
                        {{ member.members.first_name }} {{ member.members.last_name }}
                        <span class="isvarify">
                          <!-- <i class="fas fa-check-circle"></i> -->
                        </span>
                      </a>
                    </router-link>
                    <!-- <p class="date">
                      {{ member.members.city }} , {{ member.members.name }}
                    </p> -->
                    <div class="col-lg-12 col-xl-12" style="padding-left: 0px;">
                      <ul style="display: flex; justify-content: space-between;">
                        <li style="text-align: center;">
                          <img src="../../../public/assets/isna-images/age-group.png" alt="" class="ImageIconSetting"
                            style="width: 29px;height: 28px;">
                          <p>{{ getAge(member.members.d_o_b) }}</p>
                        </li>
                        <li v-if="member.marital_status.length != 0 && member.marital_status[0].meta_value != ''" style="text-align: center;">
                          <img src="../../../public/assets/isna-images/engagement-ring.png" alt=""
                            style="width: 29px;height: 28px;">
                          <p v-if="member.marital_status.length != 0 && member.marital_status[0].meta_value == 1"> Never Married</p>
                          <p v-if="member.marital_status.length != 0 && member.marital_status[0].meta_value == 2"> Divorced</p>
                          <p v-if="member.marital_status.length != 0 && member.marital_status[0].meta_value == 3"> Widow/Widower</p>
                          <p v-if="member.marital_status.length != 0">{{getReferenceData(member.marital_status[0].meta_value)}}</p>
                        </li>

                        <li style="text-align: center;">
                          <img src="../../../public/assets/isna-images/location-pin.png" alt=""
                            style="width: 25px;height: 26px;">
                          <p>{{ member.members.name }}</p>
                        </li>
                        <li style="text-align: center;">
                          <img src="../../../public/assets/isna-images/map.png" alt="" style="width: 25px;height: 26px;">
                          <p v-if="member.members.country=='223'">USA</p>
                          <p v-else>Canada</p>
                        </li>
                      </ul>


                    </div>
                    <div>

                    </div>
                    <p class="description">
                      <!-- {{member.meta[0].meta_value}} -->
                    </p>
                  </div>
                  <div class="col-lg-2 col-xl-2 col-md-12 col-sm-12 rightButton">
                    <div v-if="isLoggedIn" class="container">
                      <router-link style="padding-top: 10px;" class="custom-button chatButton" v-if="isLoggedIn" :to="{
                        path: '/chat',
                        query: {
                          member_id: `${member.members.member_id}`,
                        },
                      }">
                        Chat
                      </router-link>
                      <router-link v-else to="/login">
                        <i class="connnect-btn far fa-comment-dots"></i>
                      </router-link>
                    </div>
                    <!-- <button class="FavButton custom-button" @click="favProfile(member.members.member_id)"> Add To Favourite</button> -->
                    <div v-if="isLoggedIn">
                      <button  class="FavButton custom-button" v-if="!members_fav.includes(member.members.member_id)"
                        @click="favProfile(member.members.member_id)">
                        Add To Favourite
                      </button>
                      <button class="FavButton custom-button" v-if="members_fav.includes(member.members.member_id)"
                        @click="favProfile(member.members.member_id)">
                        Remove Favourite
                      </button>
                    </div>
                      <div v-if="!isLoggedIn">
                        <button style="margin-bottom: 10px;" class="FavButton custom-button"
                          @click="goto_login(member.members.member_id,check = 'chat')">
                          Chat
                        </button>
                        <button class="FavButton custom-button"
                          @click="goto_login(member.members.member_id,check = 'favourite')">
                          Add To Favourite
                        </button>
                      </div>

                  </div>
              </div>
            </div>
          </div>
          <div  class="row" v-if="paginatedItems">
            <div class="col-lg-12">
              <div v-if="visiblePages" class="pagination-area text-center" style="display: flex; justify-content: center;">
                <div v-if="this.current > 1">
                  <a href="javascript:void(0)" @click.prevent="CurrentPageActive(this.current - 1)"><i class="fas fa-angle-double-left"></i></a>
                </div>
                <div v-for="(pages) in visiblePages" :key="pages">
                  <div v-if="pages=='...'">
                    <a  href="javascript:void(0)">{{ pages }}</a>
                  </div>
                  <div v-else>
                    <a href="javascript:void(0)"  :value="pages" :class="{ active: pages == current }" @click.prevent="CurrentPageActive(pages)">{{ pages }}</a>
                  </div>
                </div>
                <div v-if="this.current < totalPages">
                  <a href="javascript:void(0)" @click.prevent="CurrentPageActive(this.current + 1)"><i class="fas fa-angle-double-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <div class="row" v-if="this.member == null" style=" justify-content: center; text-align: center;">
        <div class="alert alert-danger" style="padding-top: 21px;" role="alert">
          Sorry, No Records Found!
          <router-link class="custom-button" to="/dashboard" style="margin-bottom: 11px"> Go Back</router-link>
        </div>
      </div>
    <!-- ==========Community-Sectionn========== -->

    <!-- Filter Modal -->
    <div class="modal fade filter-p" id="exampleModalCenter" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header justify-content-between">
            <h6 class="modal-title" id="exampleModalCenterTitle">Filter your search</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="join-now-box">
              <div class="single-option">
                <p class="title">I am a :</p>
                <div class="option">
                  <div class="s-input mr-3">
                    <input type="radio" name="gender" id="male" /><label for="male">Male</label>
                  </div>
                  <div class="s-input">
                    <input type="radio" name="gender" id="female" /><label for="female">Female</label>
                  </div>
                </div>
              </div>
              <div class="single-option gender">
                <p class="title">Seeking a :</p>
                <div class="option">
                  <div class="s-input mr-4">
                    <input type="radio" name="seeking" id="males" /><label for="males">Man</label>
                  </div>
                  <div class="s-input">
                    <input type="radio" name="seeking" id="females" /><label for="females">Woman</label>
                  </div>
                </div>
              </div>
              <div class="single-option age">
                <p class="title">Ages :</p>
                <div class="option">
                  <div class="s-input mr-3">
                    <select class="select-bar">
                      <option value="">18</option>
                      <option value="">20</option>
                      <option value="">24</option>
                    </select>
                  </div>
                  <div class="separator">-</div>
                  <div class="s-input ml-3">
                    <select class="select-bar">
                      <option value="">30</option>
                      <option value="">35</option>
                      <option value="">40</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="single-option last">
                <p class="title">Country :</p>
                <div class="option">
                  <div class="s-input mr-3">
                    <select class="select-bar">
                      <option>Select Country</option>
                      <option value="">India</option>
                      <option value="">Japan</option>
                      <option value="">England</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="joun-button">
                <button class="custom-button">Join Now!</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import DashboardHeader from '@/components/DashboardHeader.vue';
export default {
  name: "SearchResults",
  components:{ DashboardHeader},
  data() {
    return {
      members_fav: [],
      members: '',
      member: '',
      check: "",
      current: 1,
      pageSize: 10,
      ActiveClass: '',
      // totalPages: [],
      fav_type: 0,
      maritalStatus:"",
      LoadingTimingFlag: '',
      pagination: [],
      decodedString: '',
      maxVisiblePages: 10,
      url_page:1,
      Previous: "&laquo; Previous",
      Next: "Next &raquo;",
    };
  },

  computed: {
    isLoggedIn() {
      return (this.isLoggedIn = localStorage.getItem("token"));
    },

    totalPages() {
      return Math.ceil(this.members.length / this.pageSize)
    },

    visiblePages() {
      let startPage = Math.max(1, this.current - Math.floor(this.maxVisiblePages / 2))
      // console.log('Total pages ',this.totalPages);
      let endPage = Math.min(this.totalPages, startPage + this.maxVisiblePages - 1)
      if (endPage - startPage + 1 < this.maxVisiblePages) {
        startPage = Math.max(1, endPage - this.maxVisiblePages + 1)
      }

      const pagesArray = []
      if (startPage > 1) {
        pagesArray.push(1)
        if (startPage > 2) {
          pagesArray.push('...')
        }
      }
      for (let i = startPage; i <= endPage; i++) {
        pagesArray.push(i)
      }
      if (endPage < this.totalPages) {
        if (endPage < this.totalPages - 1) {
          pagesArray.push('...')
        }
        pagesArray.push(this.totalPages)
      }
      console.log("Page Array",pagesArray);
      return pagesArray.length > 0 ? pagesArray : []
    },

    paginatedItems() {
      const startIndex = (this.current - 1) * this.pageSize
      const endIndex = startIndex + this.pageSize
      return this.members.slice(startIndex, endIndex)
    },


    // indexStart() {
    //   return (this.current - 1) * this.pageSize;
    // },
    // indexEnd() {
    //   return this.indexStart + this.pageSize;
    // },

    // paginated() {
    //   return this.members.slice(this.indexStart, this.indexEnd);
    // },
    // pageCount() {
    //   let lenght = this.members.length,
    //     size = this.pageSize;

    //   return Math.ceil(lenght / size);

    // },

  },

  methods: {
    // async decode(string){
    //   console.log("Working")
    //   return  this.decodedString = string.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
    // },
    async basicSearch(page=this.$route.query.page) {
      this.LoadingTimingFlag  = false;
      let currentPath = window.location.href;
      currentPath =currentPath.split('page') ;
      currentPath = currentPath[0]+'page='+page ;
      console.log(currentPath);
      window.history.pushState(null, null, currentPath);

      let bodyFormData = {
        "nick_name": '',
        "search_gender" : '',
        "min_age": '' ,
        "max_age" : '',
        "country" : '',
        "state" : '' ,
        "gender": '' ,
        // 'photo':'',
      }
      // dataFormData.nick_name = "Ahmer";
      // console.log("Data form check => " , dataFormData)
      if (this.$route.query.nick_name != '') {
        bodyFormData.nick_name=this.$route.query.nick_name;
      }if (this.$route.query.gender) {
        bodyFormData.search_gender=this.$route.query.gender;
      }if (this.$route.query.min_age) {
        bodyFormData.min_age = this.$route.query.min_age;
      } if (this.$route.query.max_age) {
        bodyFormData.max_age = this.$route.query.max_age;
      } if (this.$route.query.country) {
        bodyFormData.country = this.$route.query.country;
      } if (this.$route.query.state) {
        bodyFormData.state= this.$route.query.state;
      } if (localStorage.getItem("user")) {
        bodyFormData.gender= JSON.parse(localStorage.getItem("user"))[0].gender;
      }
      console.log("body form=> " , bodyFormData)
      this.axios
        .post('search_member', {page:page , FormData:bodyFormData ,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }}
        ).then((result) => {
          console.log(result,"check");
          this.members = result.data.data[0];
          this.pagination = result.data.data[1];
          console.log("Pagination" , this.pagination)
          this.LoadingTimingFlag  = true;
          console.log("Memeber" , this.members)
          if (this.members != 'N') {
            // result.data.data[0].splice(100);

            console.log(this.members, "SearchResult123")

          } else {
            this.member = null;
          }



        }).catch((err) => {
          // this.LoadingTimingFlag = true;
          this.member = null;
        });
    },
    getfav2() {

        this.axios
        .get("check_profile_favorite_for_search", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          this.members_fav.splice(0);
          for(let i =0 ; i<result.data.data.fav_profiles.length;i++){
           this.members_fav.push(result.data.data.fav_profiles[i].favorite_id);
          }
        })
        .catch((err) => { });
    },


     async favProfile(member_id) {
       console.log(this.fav_type)
      console.log(member_id)
      if (this.isLoggedIn) {
        var formBody = new FormData();

        formBody.append("favorite_id", member_id),
        formBody.append("type", this.fav_type);
        await this.axios
          .post("profile_favorite", formBody, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            this.getfav2();
            // this.fav_type = 1;
          })
          .catch((err) => {
           });
      }
      else {
        // alert("You must login to perform this action.");
      }
    },
    goto_login(id,check){
      if(check == 'chat'){
        localStorage.setItem('redirect_url', '/chat?member_id='+id);
        this.$router.push("login");
      }else{
        localStorage.setItem('redirect_url', '/profile?member_id='+id);
        this.$router.push("login");
      }
    },

    async advanceSearch() {

      var formdata = new FormData();
      formdata.append("title", this.$route.query.title),
        formdata.append("min_age", this.$route.query.min_age),
        formdata.append("max_age", this.$route.query.max_age),
        formdata.append("min_height", this.$route.query.min_height),
        formdata.append("max_height", this.$route.query.max_height),
        formdata.append("eye_color", this.$route.query.eye_color),
        formdata.append("build", this.$route.query.build),
        formdata.append("hair_color", this.$route.query.hair_color),
        formdata.append("profession", this.$route.query.profession),
        formdata.append("annual_income", this.$route.query.annual_income),
        formdata.append("education", this.$route.query.education),
        formdata.append("religion", this.$route.query.religion),
        formdata.append("language", this.$route.query.language),
        formdata.append("country", this.$route.query.country),
        formdata.append("state", this.$route.query.state),
        formdata.append("origin", this.$route.query.origin),
        formdata.append("marital_status", this.$route.query.marital_status),
        formdata.append("want_childern", this.$route.query.want_children),
        formdata.append("have_childern", this.$route.query.have_children),
        formdata.append("smoke", this.$route.query.smoke),
        formdata.append("with_pics", this.$route.query.with_pics),
        formdata.append("gender", JSON.parse(localStorage.getItem("user"))[0].gender),

        this.axios
          .post("advance_search_member", formdata, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }).then((result) => {
            this.members = result.data.data[0];
            if (this.members.length > 0) {
            // result.data.data[0].splice(100);
            console.log(this.members, "advance_search_member")
            this.LoadingTimingFlag  = true;
          } else {
            this.member = null;
            this.LoadingTimingFlag  = true;

          }
          }).catch((err) => {
            this.member = null;
            console.log(err);
            this.LoadingTimingFlag  = true;
          });
    },

     getReferenceData(martial_status) {
       this.axios.get("get_reference_data", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          // console.log(result.data.data, "Reference");
          const maritalStatus = result.data.data[0][10].filter(
            (x) => x.numeric_value == martial_status && x.text_value
          );
          // console.log(maritalStatus,"maritalStatus")
          this.maritalStatus = maritalStatus[0].text_value;
          const tempValue=this.maritalStatus;
          return tempValue

        })

        .catch((err) => { });

    },


    getAge(Age) {
      // this.LoadingTimingFlag = false;
      // console.log(Age, "Addufb")
      var dateString = Age;

      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      // console.log(age);
      // this.LoadingTimingFlag = true;
      this.age = age;
      return age;
    },
    async CurrentPageActive(page) {
      this.ActiveClass = "active";
      this.current = page;
    },

    prev() {
      if (this.current > 1) {
        this.current--;
      }
    },

    next() {
      if (this.current < this.pageCount) {
        this.current++;
      }
      // this.current++;
    },
    handleBackButtonClick() {
      // Perform page reload
      window.location.reload();
    },

  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    window.removeEventListener('popstate', this.handleBackButtonClick);
  },
  mounted() {
    this.getfav2();
    window.addEventListener('popstate', this.handleBackButtonClick);

    if (this.$route.query.search == "advance-search") {
      this.advanceSearch();
    }
    if (this.$route.query.search == "basic-search") {
      this.basicSearch();
    }
  },

};
</script>

<style scoped>
.modal-backdrop {
  background-color: none !important;
}

.modal-open {
  overflow: scroll !important;
}

.community-section.inner-page .single-friend img {
  max-width: 152px;
  margin-right: 0px;
}

.FavButton {
  width: 160px;
  font-size: 13px;
  color: white;
  border-radius: 50px;
  height: 45px;
  text-align: center;
  background-image: linear-gradient(166deg, #f22876 0%, #942dd9 100%);
   /* -webkit-box-shadow: 0.872px 9.962px 20px 0px rgb(12 78 165 / 30%);  */


}

.community-section.inner-page .single-friend img {
  max-width: 160px;
  margin-right: 0px;
}

.custom-button {
     width: 170px;
    font-size: 14px;
    height: 46px;
}

.community-section.inner-page .single-friend .content {
  padding-right: 10px;
}

.rightButton {
  text-align: center;
  align-self: center;
}

@media (min-width: 769px) and (max-width: 992px){
   .community-section.inner-page .single-friend {
   flex-wrap: wrap;
  }
   .rightButton {
    display: flex;
  }
  .FavButton{
    margin-top: 0px;
  }

  .chatButton{
  margin-left: -20px;
  margin-bottom: 20px;
  float: right;
}
}

@media (min-width: 993px) and (max-width: 1900px) {
  .rightButton {
    align-self: center;
    align-self: center;
    margin-left: -19px;
  }

  .community-section.inner-page .single-friend {
    display: flex;
  }
  .chatButton{
    margin-left: -15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .community-section.inner-page .single-friend {
    display: block;
  }
  .description{
    display: none;
  }
  .content{
    text-align: center;
  }
   .FavButton{
    margin-top: 10px;
  }
  .chatButton{
    margin-bottom: 15px;
  }


}
</style>
