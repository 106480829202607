<template>
    <DashboardHeader></DashboardHeader>
    <section class="user-setting-section">
        <loader v-if="this.LoadingTimingFlag == false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5"
            speed="2" bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
        <div class="container">
            <div v-if="searches && searches != ''">
                <div v-for="search in searches" :key="search" class="page-title">
                    <div class="col-md-7">
                        {{  search.title  }}
                    </div>
                    <div class="col-md-5">
                        <!-- <button class="custom-button">Show Results</button> -->
                        <router-link :to="{
                            path: '/advance-search',
                            query: {
                                search_id: `${search.id}`,
                            },
                        }">
                            <button class="custom-button">Edit Criteria</button>
                        </router-link>
                        <!-- <button class="custom-button delete_button" @click="delSearch(search.id)">Delete</button>  -->
                        <button class="custom-button delete_button" data-toggle="modal"
                            data-target="#deleteSaveSearch" @click="getSearchId(search.id)">Delete</button>
                       

                    </div>

                </div>
            </div>
            <div class="alert alert-danger" v-if="searches && searches == ''" role="alert">
                No Saved Searches Found!
            </div>
        </div>

        <div class="modal fade" id="deleteSaveSearch" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-md modal_width" role="document" >
                                <div class="modal-content">
                                    <!-- <div class="close closeModal " data-dismiss="modal" aria-label="Close"
                                        style="width: 35px; height: 35px;line-height: 30px;">
                                        <span aria-hidden="true">&times;</span>
                                    </div> -->
                                    <form enctype="multipart/form-data" @submit="editProfile"
                                        :validation-schema="schema" style="height: 200px;">
                                        <div class="modal-body">
                                           <b><p class='text-center' style="margin-top: 26px;">Are you sure you want to delete?</p></b>
                                            <div class='text-center' style="margin-top: 35px;">
                                                <button class="custom-button"
                                                    @click.prevent="delSearch(search_id)">Yes</button>
                                                <button class="custom-button SearchDelete_No" data-dismiss="modal" aria-label="Close">No</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

    </section>
</template>
<script>
import DashboardHeader from "@/components/DashboardHeader.vue";
export default ({
    name: 'SavedSearch',
    data() {
        return {
            LoadingTimingFlag: false,
            searches: '',
            search_id:"",
        }
    },
    components: { DashboardHeader },
    methods: {
        async getSearches() {
            await this.axios
                .get("get_saved_searches", {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }).then((result) => {
                    // console.log(result, "SaveSearched");
                    this.searches = result.data.data.searches;

                })
                .catch((err) => { })
                .finally(() => {
                    this.LoadingTimingFlag = true;
                    });
        },
        async getSearchId(id){
            this.search_id=id;
        },
        async delSearch(id) {
            console.log(id,"ftes6d")
            var bodyFormData = new FormData();
            bodyFormData.append("id", id),
                await this.axios
                    .post("del_saved_search", bodyFormData, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }).then((result) => {
                        window.location.reload();
                    })
                    .catch((err) => { });
        },
    },

    mounted() {

        this.getSearches();

        // setTimeout(() => {
        //     this.LoadingTimingFlag = true;
        // }, 2500);
    },
})
</script>
<style scoped>
.SearchDelete_No{
    margin-left: 10px;
}
.SearchDelete_btn {
    width: 120px;
    height: 40px;
    margin-left: 10px;
}

@media (min-width: 993px) and (max-width: 1900px) {
    .delete_button {
        margin-left: 10px;
    }
    .modal_width{
        width: 50%;
    }
}

.custom-button {
    width: 145px;
    font-size: 16px;
}

@media (min-width: 769px) and (max-width: 992px) {
    .delete_button {
        margin-top: 10px;
    }
    .modal_width{
        width: 50%;
    }
}

@media (max-width:768px) {
    .delete_button {
        margin-top: 10px;
    }
}

@media (max-width: 450px) {
    .custom-button {
        width: 130px;
        font-size: 13px;
    }
    .modal_width{
        width: 95%;
    }
}
</style>
