<template>
  <section class="latest-registered-section">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 align-self-center">
          <vueper-slides
            autoplay
            fixed-height="250px"
            :slide-ratio="1 / 4"
            :visible-slides="3"
            :arrows="false"
            :bullets="false"
            slide-multiple
            :gap="3"
            :dragging-distance="200"
            :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
          >
            <vueper-slide v-for="(slide, i) in slides" :key="i">
              <template #content>
                <div class="single-slider">
                  <div class="img">
                    <img :src="slide.member_image" alt="" />
                  </div>
                  <router-link :to="{
                            path: '/profile',
                            query: {
                              member_id: `${slide.members.member_id}`,
                            },
                          }">
                  <div class="inner-content">
                    <h4 class="name">{{ slide.members.first_name }}</h4>
                    <p>{{ slide.members.city }}</p>
                  </div>
                </router-link>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
        <div class="col-xl-6">
          <div class="content">
            <div class="section-header">
              <h6 class="sub-title extra-padding wow fadeInUp">Latest Registered</h6>
              <h2 class="title wow fadeInUp">Members</h2>
              <p class="text">
                if you have been looking for the someone special of your life for long,
                then your search ends here
              </p>
            </div>
            <router-link to="/register" class="custom-button">Join Now !</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  name: "RecentMemberSlider",

  components: { VueperSlides, VueperSlide },

  data: () => ({
    slides: [],
  }),
  methods: {
    async getRecentMembers() {
      await this.axios
        .get("recent_members", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result.data.data.members);
          this.slides = result.data.data.members;
        })
        .catch((err) => {});
    },
  },

  mounted() {
    this.getRecentMembers();
    //console.log(members);
    //this.members = members
  },
};
</script>
<style scoped>
h4 {
  color: #5650ce;
}

.vueperslides__parallax-wrapper:before {
  box-shadow: none !important;
}
</style>
