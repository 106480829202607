<template>
  <loader v-if="this.LoadingTimingFlag==false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2" bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
    <DashboardHeader></DashboardHeader>

    <section class="community-section inner-page" style="margin-top:50px;">
      <div class="container">
        <div class="row" v-if="paginatedItems">
          <div v-for="(profile,index) in paginatedItems" :key="index"  class="col-lg-12">
            <div class="single-friend col-lg-12 col-md-12">
              <div class="col-lg-3 col-md-3 col-sm-12 col-12 favProfileImag">
                  <img class="FavImage" v-if="profile.original.data.member_profile[0].image == null" src="../../../public/assets/isna-images/dummyImage.png" alt="" />
                  <img class="FavImage" v-else :src="profile.original.data.member_image"  alt="" />
              </div>
              <div class="content col-lg-7 col-md-7">
                <h5>{{ profile.original.data.member_profile[0].first_name }} {{profile.original.data.member_profile[0].last_name }}</h5>
                <p>{{profile.original.data.profession.profession_name}}</p>
                <div class="col-lg-12 col-xl-12 ">
                  <ul class="IconList text-center">
                    <li>
                      <img src="../../../public/assets/isna-images/age-group.png" alt="" class="ImageIconSetting">
                      <br />
                      {{ getAge(profile.original.data.member_profile[0].d_o_b) }}
                    </li>
                    <!-- <li style="text-align: center;">
                      <img src="../../../public/assets/isna-images/engagement-ring.png" alt="" class="ImageIconSetting" >
                      <p>{{profile.original.data.meta[13]}}</p>

                    </li> -->

                    <li style="text-align: center;">
                      <img src="../../../public/assets/isna-images/location-pin.png" alt="" class="ImageIconSetting" >
                      <p>{{ profile.original.data.member_profile[0].name }}</p>
                    </li>
                    <li style="text-align: center;">
                      <img src="../../../public/assets/isna-images/map.png" alt="" class="ImageIconSetting">
                      <p>{{ conver_country(profile.original.data.member_profile[0].country) }}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-2 col-xl-2 col-md-3 col-sm-12 rightButton">
                <div class="container">
                  <router-link class="custom-button" style="padding-top: 7px;" :to="{
                    path: '/profile',
                    query: {
                      member_id: `${profile.original.data.member_profile[0].member_id}`,
                    },
                  }">View Profile
                  </router-link>
                  <!-- <button class="FavButton custom-button"
                                  @click="favProfile(profile.original.data.member_profile[0].member_id)">
                                  Remove Favourite
                                </button> -->
                </div>

              </div>
            </div>
          </div>
        </div>
        <div  class="row" v-if="paginatedItems">
            <div class="col-lg-12">
              <div v-if="visiblePages" class="pagination-area text-center" style="display: flex; justify-content: center;">
                <div v-if="this.current > 1">
                  <a href="javascript:void(0)" @click.prevent="CurrentPageActive(this.current - 1)"><i class="fas fa-angle-double-left"></i></a>
                </div>
                <div v-for="(pages) in visiblePages" :key="pages">
                  <div v-if="pages=='...'">
                    <a  href="javascript:void(0)">{{ pages }}</a>
                  </div>
                  <div v-else>
                    <a href="javascript:void(0)"  :value="pages" :class="{ active: pages == current }" @click.prevent="CurrentPageActive(pages)">{{ pages }}</a>
                  </div>
                </div>
                <div v-if="this.current < totalPages">
                  <a href="javascript:void(0)" @click.prevent="CurrentPageActive(this.current + 1)"><i class="fas fa-angle-double-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        <!-- <div  class="row" v-if="paginated">
          <div class="col-lg-12">
            <div v-if="pageCount" class="pagination-area text-center" style="display: flex; justify-content: center;">
              <a href="javascript:void(0)" @click="prev"><i class="fas fa-angle-double-left"></i><span></span></a>
              <div v-for="(pages) in pageCount" :key="pages">
                <a  :value="pages" :class="{ active: pages == current }" @click="CurrentPageActive(pages)">{{ pages }}</a>
              </div>
              <a href="javascript:void(0)" @click="next"><i class="fas fa-angle-double-right"></i></a>
            </div>
          </div>
        </div> -->
      <!-- </div> -->
        <div class="row" v-if="this.favourites== null || this.favourites == undefined" style=" justify-content: center; text-align: center;">
          <div class="alert alert-danger" role="alert">
            Sorry, No Records Found!
            <router-link class="custom-button" to="/dashboard" style="margin-bottom: 11px; padding-bottom: 36px;"> Go Back</router-link>
          </div>
        </div>
      </div>
    </section>
  </template>

  <script>
  import DashboardHeader from '@/components/DashboardHeader.vue';
  export default {
    name: "MatchProfiles",
    data() {
      return {
        favourites: "",
        meta: "",
        current: 1,
        pageSize: 14,
        ActiveClass: "",
        // totalPages: [],
        LoadingTimingFlag: false,
        maxVisiblePages: 10,
      };
    },
    computed: {
      isLoggedIn() {
        return (this.isLoggedIn = localStorage.getItem("token"));
      },
      totalPages() {
        return Math.ceil(this.favourites.length / this.pageSize)
      },

      visiblePages() {
        let startPage = Math.max(1, this.current - Math.floor(this.maxVisiblePages / 2))
        // console.log('Total pages ',this.totalPages);
        let endPage = Math.min(this.totalPages, startPage + this.maxVisiblePages - 1)
        if (endPage - startPage + 1 < this.maxVisiblePages) {
          startPage = Math.max(1, endPage - this.maxVisiblePages + 1)
        }
        
        const pagesArray = []
        if (startPage > 1) {
          pagesArray.push(1)
          if (startPage > 2) {
            pagesArray.push('...')
          }
        }
        for (let i = startPage; i <= endPage; i++) {
          pagesArray.push(i)
        }
        if (endPage < this.totalPages) {
          if (endPage < this.totalPages - 1) {
            pagesArray.push('...')
          }
          pagesArray.push(this.totalPages)
        }
        console.log("Page Array",pagesArray);
        return pagesArray.length > 0 ? pagesArray : []
      },

      paginatedItems() {
        const startIndex = (this.current - 1) * this.pageSize
        const endIndex = startIndex + this.pageSize
        return this.favourites.slice(startIndex, endIndex)
      },
      // indexStart() {
      // return (this.current - 1) * this.pageSize;
      // },
      // indexEnd() {
      //   return this.indexStart + this.pageSize;
      // },

      // paginated() {
      //   return this.favourites.slice(this.indexStart, this.indexEnd);
      // },
      // pageCount() {
      //   let lenght = this.favourites.length,
      //     size = this.pageSize;

      //   return Math.ceil(lenght / size);

      // },
    },
    methods: {
      async get_match_profiles() {
        this.axios
          .get("get_all_match_profiles", {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }).then((result) => {
            if (result.data.data.match_profiles) {

              this.favourites = result.data.data.match_profiles;
              console.log( this.favourites,"dsfdsdsffdsfddsdsdsfglklkl")

              // console.log(this.favourites[0].original.data.meta[13].meta_value  ,"favorites123")
              // this.meta = result.data.data.meta;
              // this.paginated();
            }
            else {
              this.favourites = null;
            }
          }).catch((err) => {
            console.log(err);
          }) .finally(() => {
            this.LoadingTimingFlag=true;
            });
      },
      conver_country(country){
        // console.log(country,"conver_country");
        if(country == 223){
          return 'USA';
        }else{
          return 'Canada';
        }
      },
      getAge(Age) {
        // console.log(Age, "Addufb")
        var dateString = Age;

        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        // console.log(age);
        this.age = age;
        return age;
      },
      async CurrentPageActive(id) {
        this.ActiveClass = "active";
        this.current = id;
      },
      prev() {
      if (this.current > 1) {
        this.current--;
      }
    },

    next() {
      if (this.current < this.pageCount) {
        this.current++;
      }
      // this.current++;
    },
    },
    mounted() {
      this.get_match_profiles();
      // setTimeout(() => {
      //   this.LoadingTimingFlag=true;
      // }, 2500)
    },
    components: { DashboardHeader }
  }
  </script>

  <style scoped>

   .community-section.inner-page .single-friend {
      display: flex;
    }
     .ImageIconSetting{
      width: 25px;
      height: 25px;
      margin-right: 0px;
    }
    .IconList{
      display: flex;
      justify-content: space-between;

    }
    .custom-button{
      width: 155px;
      margin-top: 15px;
      font-weight: 0px;
       height: 40px;
       font-size: 12px;
    }
  @media (min-width: 993px) and (max-width: 1500px){
    .community-section.inner-page .single-friend img {
      max-width: 150px;
      margin-right: 0px;
  }
  .FavImage{
    width: 100%;
    height: auto;
  }
   .rightButton{
      text-align: center;
      margin-right: 25px;
    }
    .custom-button{
      font-size: 12px;
    }
  }
  @media (min-width: 770px) and (max-width: 992px){
      .community-section.inner-page .single-friend img {
      max-width: 100px;
      margin-right: 0px;
      border-radius: 10px;
  }
     .rightButton{
      text-align: center;
    }
     .custom-button{
      width: 140px;
      margin-top: 15px;
      font-size: 15px;
      height: 40px;
      font-size: 12px;
    }
  }
  @media (max-width: 768px) {

    .favProfileImag{
      text-align: center;
      margin-bottom: 20px;
    }
    .ImageIconSetting{
      width: 25px;
      height: 25px;
      margin-right: 0px;
    }
    .community-section.inner-page .single-friend {
      display: block;
    }

    .content{
      text-align: center;
    }
    .IconList{
      display: flex;
      justify-content: space-between;

    }
    .IconList li p{
       font-size: 13px;
    }
    .rightButton{
      text-align: center;
    }
  }
  @media (max-width: 700px){
      .community-section.inner-page .single-friend img {
      max-width: 100px;
      margin-right: 0px;
      /* border-radius: 10px; */
  }
  }
  </style>
