<template>
  <div>
    <footer class="footer-section">
      <img class="shape1" src="assets/images/footer/f-shape.png" alt="" />
      <div class="newslater-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
              <div class="newslater-container">
                <div class="newslater-wrapper">
                  <div class="icon">
                    <img src="assets/images/footer/n-icon.png" alt="" />
                  </div>
                  <p class="text">
                    Sign up to recieve a monthly email on the latest news!
                  </p>
                  <form class="newslater-form">
                    <input type="email" placeholder="Example@gmail.com"/>
                    <button type="submit">
                      <i class="fab fa-telegram-plane"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="footer-links">
          <div class="row">
            <div class="col-lg-12">
              <hr class="hr" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-sm-6">
              <div class="link-wrapper one">
                <h4 class="f-l-title">Our Information</h4>
                <ul class="f-solial-links">
                  <li>
                    <router-link to="/about"  @click="gotoTop()">
                      <i class="fas fa-angle-double-right"></i> About us
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/contact"  @click="gotoTop()">
                      <i class="fas fa-angle-double-right"></i> Contact Us
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="link-wrapper two">
                <h4 class="f-l-title">My Account</h4>
                <ul class="f-solial-links">
                  <li>
                    <router-link to="/login"  @click="gotoTop()">
                      <i class="fas fa-angle-double-right"></i> Manage Account
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/membership"  @click="gotoTop()">
                      <i class="fas fa-angle-double-right"></i> Membership Level
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="link-wrapper three">
                <h4 class="f-l-title">help center</h4>
                <ul class="f-solial-links">
                  
                  <li>
                    <router-link to="/faqs"  @click="gotoTop()">
                      <i class="fas fa-angle-double-right"></i> FAQ
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="link-wrapper four">
                <h4 class="f-l-title">legal</h4>
                <ul class="f-solial-links">
                  <li>
                    <router-link to="/privacy-policy"  @click="gotoTop()">
                      <i class="fas fa-angle-double-right"></i> Privacy policy
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/term-condition" @click="gotoTop()">
                      <i class="fas fa-angle-double-right"></i> Terms of Services
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright-wrapper">
          <div class="row">
            <div class="col-lg-12">
              <hr class="hr2" />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <div class="copyr-text">
                <span> Copyright © 2022.All Rights Reserved By </span>
                <router-link to="/"  @click="gotoTop()">ISNA Matrimonials</router-link>
              </div>
            </div>
            <div class="col-lg-6">
              <ul class="footer-social-links">
                <li>
                  <a href="#">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="fab fa-dribbble"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "PublicFooter",
   methods: {
   gotoTop() {
window.scrollTo(0,0)
  },
  mounted() {
        window.scrollTo(0,0)
    },
 }
};

</script>
