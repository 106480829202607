<template>

    <section class="log-reg">
      <div class="top-menu-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <router-link to="/" class="backto-home" 
              style="background: linear-gradient(166deg, #f22876 0%, #942dd9 100%); 
              border-radius: 52px; padding: 16px; margin-top: 20px; color: white;"
                ><i class="fas fa-chevron-left"></i> Back to Isna
                Matrimonials</router-link
              >
            </div>
            <div class="col-lg-7">
              <!-- <div class="logo">
                <img src="assets/images/logo2.png" alt="logo" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-end">
          <div class="image"></div>
          <div class="col-lg-7">
            <div class="log-reg-inner">
              <div class="section-header inloginp">
                <h2 class="title">Reset Password</h2>
              </div>
              <div class="main-content inloginp">
                <form @submit.prevent="Change_Password">
                  <div class="form-group">
                    <input
                      id = "password"
                      type="Password"
                      class="my-form-control mb-3"
                      placeholder="Password"
                      minlength="8"
                      v-model="Password"
                    />
                    <input
                      id = "confirm_password"
                      type="Password"
                      class="my-form-control"
                      placeholder="Confirm Password"
                      minlength="8"
                      v-model="confirm_password"
                    />
                    <span>{{this.message}}</span>
                  </div>
                  <div class="button-wrapper">
                    <a href="#">
                      <button type="submit" class="custom-button">Reset Password</button>
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
</template>
<script>
  export default {
    name: 'Password',
    data() {
        return {
            Password: '',
            confirm_password:"",
            message:"",
        }
    },
    methods:{

        async Change_Password() {
          var token = this.$route.query.token;
          var email = this.$route.query.email;
          var bodyFormData = new FormData();
          bodyFormData.append("password", this.Password);
          bodyFormData.append("password_confirmation", this.confirm_password);
          bodyFormData.append("token", token);
          bodyFormData.append("email", email);
          this.axios
        .post("resetPassword", bodyFormData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
            .then((res) => {
            // console.log(res);
            this.$router.push("login")
            })
            .catch((error) => {
              this.message ="Password doesn't Macth";
            });
        },
    },
}
</script>



