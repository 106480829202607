<template>
     <loader v-if="this.LoadingTimingFlag == false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2"
    bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
    <div>
        <!-- ==========Blog-Page========== -->
        <section class="blog-page single-blog-page">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row" style="display: block; margin-right: 0px; margin-left: 0px; ">
                            <div class="col-lg-12" v-for="article in articles" :key="article"  
                            style="box-shadow: 0px 5px 30px 0px rgb(176 171 193 / 40%); padding-left: 0px;padding-right: 0px; border-radius: 30px;">
                                <div class="single-blog">
                                    <div class="img">
                                        <!-- <img src="assets/images/blog/blog1.png" alt=""> -->
                                    </div>
                                    <div class="content">
                                        <div class="left">
                                            <div class="avatar">
                                                <!-- <img src="assets/images/blog/author-avarat.png" alt=""> -->
                                            </div>
                                        </div>
                                        <div class="right">
                                            <p class="date">
                                                {{formatDate(article.created_on)}}
                                            </p>
                                            <h4 class="title">
                                                {{article.title}}
                                            </h4>
                                            <p class="text" v-html="substr(article.description)"></p>
                                        </div>
                                    </div>
                                    <div class="post-footer">
                                        
                                        <div class="right">
                                            <router-link :to="{
                                                path: '/single-article',
                                                query: {
                                                id: `${article.content_id}`,
                                                },
                                            }" class="read-more-btn">Continue Reading</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-4">
                        <div class="widget widget-search">
                            <h5 class="title">search</h5>
                            <form class="search-form">
                                <input type="text" placeholder="Enter your Search Content" required="">
                                <button type="submit"><i class="flaticon-loupe"></i>Search</button>
                            </form>
                        </div>
                        <div class="widget widget-categories">
                            <h5 class="title">categories</h5>
                            <ul>
                                <li>
                                    <a href="#">
                                        <span>Showtimes &amp; Tickets</span><span>50</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Latest Trailers</span><span>43</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Coming Soon </span><span>34</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>In Theaters</span><span>63</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Release Calendar  </span><span>11</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Stars</span><span>30</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span>Horror Movie </span><span>55</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="widget widget-post">
                            <h5 class="title">latest post</h5>
                            <div class="slider-nav">
                                <span class="flaticon-angle-pointing-to-left widget-prev"></span>
                                <span class="flaticon-right-arrow-angle widget-next active"></span>
                            </div>
                            <div class="widget-slider owl-carousel owl-theme owl-loaded owl-drag">
                                
                                
                            <div class="owl-stage-outer"><div class="owl-stage" style="transform: translate3d(-960px, 0px, 0px); transition: all 0.25s ease 0s; width: 2880px;"><div class="owl-item cloned" style="width: 450px; margin-right: 30px;"><div class="item">
                                    <div class="thumb">
                                        <a href="#">
                                            <img src="assets/images/blog/resent-post.png" alt="blog">
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h6 class="p-title">
                                            <a href="#">How to Start, Plan, and Keep a 
                                                Date Night</a>
                                        </h6>
                                        <div class="meta-post">
                                            <a href="#" class="mr-4"><i class="fas fa-user"></i>Admin</a>
                                            <a href="#"> <i class="far fa-comments"></i> Comments</a>
                                        </div>
                                    </div>
                                </div></div><div class="owl-item cloned" style="width: 450px; margin-right: 30px;"><div class="item">
                                    <div class="thumb">
                                        <a href="#">
                                            <img src="assets/images/blog/resent-post.png" alt="blog">
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h6 class="p-title">
                                            <a href="#">How to Start, Plan, and Keep a 
                                                Date Night</a>
                                        </h6>
                                        <div class="meta-post">
                                            <a href="#" class="mr-4"><i class="fas fa-user"></i>Admin</a>
                                            <a href="#"> <i class="far fa-comments"></i> Comments</a>
                                        </div>
                                    </div>
                                </div></div><div class="owl-item active" style="width: 450px; margin-right: 30px;"><div class="item">
                                    <div class="thumb">
                                        <a href="#">
                                            <img src="assets/images/blog/resent-post.png" alt="blog">
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h6 class="p-title">
                                            <a href="#">How to Start, Plan, and Keep a 
                                                Date Night</a>
                                        </h6>
                                        <div class="meta-post">
                                            <a href="#" class="mr-4"><i class="fas fa-user"></i>Admin</a>
                                            <a href="#"> <i class="far fa-comments"></i> Comments</a>
                                        </div>
                                    </div>
                                </div></div><div class="owl-item" style="width: 450px; margin-right: 30px;"><div class="item">
                                    <div class="thumb">
                                        <a href="#">
                                            <img src="assets/images/blog/resent-post.png" alt="blog">
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h6 class="p-title">
                                            <a href="#">How to Start, Plan, and Keep a 
                                                Date Night</a>
                                        </h6>
                                        <div class="meta-post">
                                            <a href="#" class="mr-4"><i class="fas fa-user"></i>Admin</a>
                                            <a href="#"> <i class="far fa-comments"></i> Comments</a>
                                        </div>
                                    </div>
                                </div></div><div class="owl-item cloned" style="width: 450px; margin-right: 30px;"><div class="item">
                                    <div class="thumb">
                                        <a href="#">
                                            <img src="assets/images/blog/resent-post.png" alt="blog">
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h6 class="p-title">
                                            <a href="#">How to Start, Plan, and Keep a 
                                                Date Night</a>
                                        </h6>
                                        <div class="meta-post">
                                            <a href="#" class="mr-4"><i class="fas fa-user"></i>Admin</a>
                                            <a href="#"> <i class="far fa-comments"></i> Comments</a>
                                        </div>
                                    </div>
                                </div></div><div class="owl-item cloned" style="width: 450px; margin-right: 30px;"><div class="item">
                                    <div class="thumb">
                                        <a href="#">
                                            <img src="assets/images/blog/resent-post.png" alt="blog">
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h6 class="p-title">
                                            <a href="#">How to Start, Plan, and Keep a 
                                                Date Night</a>
                                        </h6>
                                        <div class="meta-post">
                                            <a href="#" class="mr-4"><i class="fas fa-user"></i>Admin</a>
                                            <a href="#"> <i class="far fa-comments"></i> Comments</a>
                                        </div>
                                    </div>
                                </div></div></div></div><div class="owl-nav disabled"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div><div class="owl-dots disabled"></div></div>
                        </div>
                        <div class="widget widget-newsletter">
                            <h5 class="title">Newsletter</h5>
                            <form class="search-form">
                                <input type="text" placeholder="Enter your Email Address" required="">
                                <button type="submit"><i class="far fa-envelope"></i> Send</button>
                            </form>
                        </div>
                        <div class="widget widget-tags">
                            <h5 class="title">featured tags</h5>
                            <ul>
                                <li>
                                    <a href="#">creative</a>
                                </li>
                                <li>
                                    <a href="#">design</a>
                                </li>
                                <li>
                                    <a href="#">skill</a>
                                </li>
                                <li>
                                    <a href="#">template</a>
                                </li>
                                <li>
                                    <a href="#" class="active">landing</a>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
    </div>
</template>
    
<script>
import dayjs from "dayjs";
export default {
    name: 'ArticlesView',
    data() {
        return {
            articles: '',
            LoadingTimingFlag: false,
        }
    },
    methods: {
    
        async getArticles() {
        await this.axios
            .get("get_articles", {
            })
            .then((result) => {
                console.log(result.data.data.articles);
                this.articles = result.data.data.articles;
            })
            .catch((err) => {})
            .finally(() => {
                this.LoadingTimingFlag = true
                    });
        },

        formatDate(dateString) {
            const date = dayjs(dateString);
            return date.format('dddd MMMM D, YYYY');
        },

        substr(string) {
    	    return string.substring(0,250) + '...';
        }
    },

    mounted() {
        this.getArticles();
    },
}
</script>

<style scoped>
.right{

    text-align: left;
}

</style>