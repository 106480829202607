<template>
    <div>
        <!-- ==========Contact-Section========== -->
        <section class="contact-section">
            <br />
            <br />
            <br />
            <!-- <img class="img-left" src="assets/images/contact/img-left.png" alt="">
            <img class="img-right" src="assets/images/contact/img-right.png" alt=""> -->
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div class="content">
                            <div class="section-header">

                                <h6 class="sub-title">
                                    Contact Us
                                </h6>
                                <h2 class="title">
                                    Get in Touch
                                </h2>
                                <p class="text">
                                    We'd love to hear from you! Let us know how we can help.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2">

                    </div>
                    <div class="col-lg-8">
                        <form ref="contactusform" @submit.prevent="contactUs">
                            <div class="contact-form-wrapper">
                                <div class="single-input">
                                    <input type="text" placeholder="Full Name" class="ContactInput" v-model="full_name"
                                        required>
                                    <i class="far fa-user icon"></i>

                                </div>
                                <div class="single-input">
                                    <input type="email" placeholder="Example@gmail.com" v-model="email" required>
                                    <i class="far fa-envelope"></i>

                                </div>
                                <div class="single-input">
                                    <textarea placeholder="Type Your Text" v-model="comment"></textarea>
                                    <i style="top: 5%;" class="far fa-comments"></i>

                                </div>
                                <button type="submit" class="custom-button">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </section>
    </div>
</template>
    
<script>

export default {
    name: 'ContactView',
    data() {
        return {
            full_name: "",
            email: "",
            comment: "",
        };
    },
    methods: {

        async contactUs() {
            var formBody = new FormData();
            formBody.append("full_name", this.full_name),
                formBody.append("email", this.email),
                formBody.append("comment", this.comment)
            await this.axios
                .post("contact_us", formBody, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((result) => {
                    this.$refs.contactusform.reset();
                    console.log(result);

                })
        }
    },
}

</script>

<style scoped>
.single-input input {
    padding: 0px 20px 0px 25px !important;
    font-size: 16px;
}

.single-input {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    font-size: 16px;
}

input {
    font-size: 16px;
}

.custom-button {
    float: right;
}
</style>