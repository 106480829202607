<template>
<loader v-if="this.LoadingTimingFlag==false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2" bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
  <div class="search-overlay">
    <div class="close"><i class="fas fa-times"></i></div>
    <form action="#"></form>
  </div>

  <!-- =========Basic Search-Section========== -->
  <DashboardHeader></DashboardHeader>
  <!--  -->
  <section class="product-category profile-section product-details-section">
    <div class="container">
      <div class="row">
        <BasicSearchBar></BasicSearchBar>
      </div>
    </div>
  </section>

  <!-- ==========Advanced Search-Section========== -->
  <section class="community-section inner-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="top-filter">
            <div class="left">
              <router-link to="/advance-search">
                <i class="fas fa-sliders-h"></i> Advance search
              </router-link>

              <router-link to="/saved-search" class="saveSearch">
                <i class="fas fa-sliders-h"></i> Saved search
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ==========Members-Section========== -->
  <!-- ==========Dashboard Gallery Starts========== -->

  <section class="profile-section">
      <div class="container">
          <div class="row">
            <!-- ==========Dashboard Gallery Starts========== -->

          <DashboardGallery></DashboardGallery>

          <!-- ==========Dashboard Gallery Ends========== -->
          <DashboardFavourites></DashboardFavourites>
          <!--Dashboard Match Criteria  -->
          <DashboardMatchCriteria></DashboardMatchCriteria>

          </div>
      </div>
  </section>

  <DashboardMembers></DashboardMembers>
  <AdvanceSearchForm></AdvanceSearchForm>
</template>

<script>
import DashboardHeader from "@/components/DashboardHeader.vue";
import DashboardMembers from "@/components/DashboardMembers.vue";
import BasicSearchBar from "@/components/BasicSearchBar.vue";
import AdvanceSearchForm from "@/components/AdvanceSearchForm.vue";
import MemberGallery from "@/components/MemberGallery.vue";
import DashboardGallery from "@/components/DashboardGallery.vue";
import DashboardFavourites from "@/components/DashboardFavourites.vue";
import DashboardMatchCriteria from "@/components/DashboardMatchCriteria.vue";
export default {
  name: "UserDashboard",
  data() {
    return {
      // member_id: JSON.parse(localStorage.getItem("user"))[0].member_id,
      member_gender: "",
      routerName: "",
      subscription: '',
      LoadingTimingFlag: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.LoadingTimingFlag=true;
    }, 4000)
  },
  components: { DashboardHeader, DashboardMembers,DashboardFavourites, DashboardMatchCriteria ,BasicSearchBar, AdvanceSearchForm, MemberGallery, DashboardGallery },
};
</script>

<style>
@media (min-width: 1900px){
  .profile-section .left-profile-area .profile-uplodate-photo{
    margin-top: 0px;
  }
}


.saveSearch{
    margin-left: 15px;
    width: 200px;
  }
@media (max-width: 550px){
  .saveSearch{
    margin-top: 15px;
    width: 200px;
     margin-left: 0px;
  }
}
.right {
  text-align: right;
}

.menu{

  text-align: center;
}
</style>
