import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/frontend/HomeView.vue'
import AboutView from '../views/frontend/AboutView.vue'
import UserRegister from '../views/frontend/UserRegister.vue'
import UserLogin from '../views/frontend/UserLogin.vue'
import FaqsView from '../views/frontend/FaqsView.vue'
import ContactView from '../views/frontend/ContactView.vue'
import ArticlesView from '../views/frontend/ArticlesView.vue'
import UserDashboard from '../views/backend/UserDashboard.vue'
import UpdateProfile from '../views/backend/UpdateProfile.vue'
import SearchResults from '../views/backend/SearchResults.vue'
import AdvanceSearch from '../views/backend/AdvanceSearch.vue'
import SavedSearch from '../views/backend/SavedSearch.vue'
import ChatView from '../views/backend/ChatView.vue'
import ManageGallery from '../views/backend/ManageGallery.vue'
import MemberProfile from '../views/backend/MemberProfile.vue'
import SubscriptionForm from '../views/backend/SubscriptionForm.vue'
import MatchCriteria from '../views/backend/MatchCriteria.vue'
import SingleArticle from '../views/frontend/SingleArticle.vue'
import PasswordReset from '../views/frontend/PasswordReset.vue'
import MembershipView from '@/views/frontend/MembershipView'
import PrivacyPolicy from '@/views/frontend/PrivacyPolicy'
import TermCondition from '@/views/frontend/TermCondition'
import Password from '@/views/frontend/Password'
import FavouriteProfiles from '@/views/backend/FavouriteProfiles'
import MatchProfiles from '@/views/backend/MatchProfiles'




const routes = [

  { path: '/',  name: 'Home', component: HomeView },
  { path: '/about', name: 'About Us', component: AboutView },
  { path: '/faqs', name: 'FAQs', component: FaqsView },
  { path: '/contact', name: 'Contact Us', component: ContactView },
  { path: '/articles', name: 'Articles', component: ArticlesView },
  { path: '/single-article', name: 'Article', component: SingleArticle },
  { path: '/faqs', name: 'faqs', component: FaqsView},
  { path: '/privacy-policy', name: 'Privacy Policy', component: PrivacyPolicy},
  { path: '/term-condition', name: 'Terms & Conditions', component: TermCondition},
  { path: '/faqs', name: 'faqs', component: FaqsView},
  { path: "/membership" , name: "Membership", component: MembershipView },
  { path: '/register', name: 'Register', component: UserRegister },
  { path: '/login', name: 'Login', component: UserLogin },
  { path: "/dashboard", name: "Dashboard" , component: UserDashboard},
  { path: "/advance-search", name: "Advance Search" , component: AdvanceSearch },
  { path: "/saved-search", name: "Saved Searches" , component: SavedSearch },
  { path: "/search-results/", name: "Search Results" , component: SearchResults },
  { path: "/updateprofile", name: "Update Profile" , component: UpdateProfile},
  { path: "/match/", name: "Match Criteria" , component: MatchCriteria },
  { path: "/chat/", name: "Chat" , component: ChatView },
  { path: "/gallery" , name: "Gallery", component: ManageGallery },
  { path: "/profile" , name: "Profile", component: MemberProfile },
  { path: "/billing" , name: "Billing", component: SubscriptionForm },
  { path: "/password-reset" , name: "Password Reset", component: PasswordReset },
  { path: "/password" , name: "Password", component: Password },
  { path: "/favourite-profiles" , name: "Favouurite Profiles", component: FavouriteProfiles },
  { path: "/match-profiles" , name: "Match Profiles", component: MatchProfiles },

]


const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath === '/dashboard' || to.fullPath === '/updateprofile' || to.fullPath === '/chat' || to.fullPath === '/advance_search' || to.fullPath === '/match' || to.fullPath === '/gallery' || to.fullPath === '/saved-search' || to.fullPath === '/favourite-profiles') {
    if (!localStorage.getItem('token') || localStorage.getItem('token') === null || localStorage.getItem('check_subscription') === 'true') {
      if(localStorage.getItem('billing') === 'true'){
        next('/billing');
      }else if(!localStorage.getItem('billing') || localStorage.getItem('billing') === 'false'){
      next('/login');
      }
    }
  }
  if(to.fullPath === '/login') {
    if (localStorage.getItem('token') && localStorage.getItem('check_subscription') === 'false' && localStorage.getItem('redirect_url') === '') {
      next('/dashboard');
    }else if(localStorage.getItem('token') && localStorage.getItem('redirect_url') != undefined){
      const url = localStorage.getItem('redirect_url');
      localStorage.removeItem("redirect_url");
      next(url);
    }
  }
  next();
});


export default router
