import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from "./store"
import loader from 'vue-ui-preloader'
// import Paginate from 'vuejs-paginate';


//const ws = new WebSocket('ws://127.0.0.1:8080/chat')


axios.defaults.baseURL = 'https://isna-api.66loop.com/api/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

createApp(App).use(router).use(VueAxios, axios).use(store).use(loader).mount('#app')

var hours = 8; // to clear the localStorage after 1 hour
               // (if someone want to clear after 8hrs simply change hours=8)
var now = new Date().getTime();
var setupTime = localStorage.getItem('setupTime');
if (setupTime == null) {
    localStorage.setItem('setupTime', now)
} else {
    if(now-setupTime > hours*60*60*1000) {
        localStorage.clear()
    }
}
