<template>
    <div class="col-xl-4 col-lg-4 col-md-6 marginTop">
        <div class="left-profile-area box-shadow">
            <div class="profile-uplodate-photo">
                <div class="row">
                    <div class="col-8">
                        <h6 class="p-u-p-header">
                            <i class="fas fa-camera"></i> Your Favourites
                        </h6>
                    </div>
                    <div class="col-4">
                        <h6 class="p-u-p-header">
                            <span class="header-span"><router-link to="favourite-profiles">View All</router-link></span>
                            <!-- <span class="header-span">|</span>
                            <span class="header-span"><router-link to="">Upload</router-link></span> -->
                        </h6>
                    </div>
                </div>
                <div class="p-u-p-list">
                    <div class="profile-about-box" style="width:47%; border: 1px solid #e9e9e9; margin-left: 6px;" v-for="profile in favourites" :key="profile">
                        <div class="p-inner-content">
                            <div class="profile-img">
                               <!-- <img v-if="profile.original.data.member_profile[0].image==null" src="../../public/assets/isna-images/dummyImage.png" alt=""> -->
                               <img v-if="(profile.original.data.member_profile[0].image==null || profile.original.data.member_profile[0].image=='')" src="../../public/assets/isna-images/dummyImage.png" alt="" />
                               <img v-else :src="profile.original.data.member_image" alt="">
                            </div>
                            <h5 class="name">
                                {{profile.original.data.member_profile[0].first_name}} {{profile.original.data.member_profile[0].last_name}}
                            </h5>
                            <ul class="p-b-meta-one">
                                <li>
                                    {{getAge(profile.original.data.member_profile[0].d_o_b)}} |{{profile.original.data.member_profile[0].name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "DashboardFavourites",
    data() {
        return {
            favourites: "",
            current: 1,
            pageSize: 14,
            ActiveClass: "",
            totalPages: [],
            meta: [],
        };
    },

    methods: {
        async getFavourites() {
            this.axios
                .get("favorite_profiles", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }).then((result) => {
              result.data.data.fav_profiles.splice(2);
                if (result.data.data.fav_profiles) {
                    this.favourites = result.data.data.fav_profiles;
                    this.meta = result.data.data.meta;
                }
                else {
                    this.favourites = null;
                }
            }).catch((err) => {
                console.log(err);
            });
        },

        getAge(dob) {
            var dateString = dob;
            //console.log(dateString);
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            // console.log(age);
            this.age = age;
            return age;
        }
    },
    mounted() {
        window.scrollTo(0,0)
        this.getFavourites();
    },
}
</script>

<style scoped>
@media (max-width: 1900px) and (min-width: 992px) {
    .profile-section .left-profile-area .profile-uplodate-photo {
        height: 270px;
        margin-top: 0px;
    }
}
.profile-section .left-profile-area .profile-uplodate-photo .p-u-p-list .my-col .img img[data-v-5e159f4e] {
    width: 100%;
    height: 125px;
    object-fit: cover;
    object-position: top;
}
.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img {
    background: #fff;
    width: 80px;
    height: 80px;
    position: absolute;
    border-radius: 15%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top:2px;
    z-index: 9;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .profile-img img {
    border-radius: 15%;
    width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: top;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content .name {
    font-size: 15px;
    line-height: 20px;
}

.box-shadow{
  box-shadow: rgb(176 171 193 / 40%) 0px 5px 30px 0px;
}
.header-span{
    font-size: 14px;
    padding: 0 2px;
    font-weight: normal;
    margin-bottom: 25px;
}

.profile-section .left-profile-area .profile-about-box .p-inner-content {
    position: relative;
    padding: 90px 5px 5px;
    text-align: center;
}

.profile-section .left-profile-area .profile-uplodate-photo {
    margin-top: 0px;
}

.p-b-meta-one li{
    font-size: 12px;
}

@media (max-width: 991px){
    .p-u-p-header{
        font-size: 16px;
    }
    .header-span a{
        font-size: 14px ;
    }
}

@media (max-width:768px){
      .marginTop{
        margin-top: 20px;
    }
}
</style>
