<template>
	<section class="blog-page">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="single-blog post-details" v-for="article in article" :key="article">
						<div class="img">
							<!-- <img src="assets/images/blog/blog1.png" alt=""> --></div>
						<div class="content">
							<div class="left">
								<div class="avatar">
									<!-- <img src="assets/images/blog/author-avarat.png" alt=""> --></div>
							</div>
							<div class="right">
								<p class="date"> {{formatDate(article.created_on)}} </p>
								<div class="post-header">
									<h4 class="m-title">
                                       {{article.title}}
                                    </h4>
									<p v-html="article.description"> </p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import dayjs from 'dayjs';
export default {
	name: 'SingleArticle',
	data() {
		return {
			article: '',
            slides: '',
		}
	},
	methods: {
		async getArticle() {
            await this.axios.get("get_single_article/" + this.$route.query.id, {}).then((result) => {
                this.article = result.data.data.article;
            }).catch((err) => {});
        },

        formatDate(dateString) {
            const date = dayjs(dateString);
            return date.format('dddd MMMM D, YYYY');
        },

        async getRecentMembers() {
            await this.axios
            .get("recent_members", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((result) => {
                console.log(result.data.data.members);
                this.slides = result.data.data.members;
            })
            .catch((err) => {});
        },


	},
	mounted() {
		this.getArticle();
        this.getRecentMembers();
	},
}
</script>
<style>
.right {
	text-align: left;
}
</style>