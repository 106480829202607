<template>
  <div
    class="modal fade filter-p"
    id="exampleModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered p-5" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-between">
          <h6 class="modal-title" id="exampleModalCenterTitle">Filter your search</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="join-now-box">
            <div class="faq-area bulletPointShow">
              <div class="faq-wrapper" style="max-width: 1200px">
                <!-- 1st Accordion -->
                <div class="faq-item active open">
                  <div class="faq-title">
                    <h6 class="title">Personal Information</h6>
                    <span class="right-icon"></span>
                  </div>
                  <div class="faq-content">
                    <div class="filter-main">
                      <div class="right">
                        <div class="item">
                          <span class="show">Age :</span>
                          <select v-model="min_age">
                            <option disabled value="">Min</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                            <option value="32">32</option>
                            <option value="33">33</option>
                            <option value="34">34</option>
                            <option value="35">35</option>
                            <option value="36">36</option>
                            <option value="37">37</option>
                            <option value="38">38</option>
                            <option value="39">39</option>
                            <option value="40">40</option>
                            <option value="41">41</option>
                            <option value="42">42</option>
                            <option value="43">43</option>
                            <option value="44">44</option>
                            <option value="45">45</option>
                            <option value="46">46</option>
                            <option value="47">47</option>
                            <option value="48">48</option>
                            <option value="49">49</option>
                            <option value="50">50</option>
                            <option value="51">51</option>
                            <option value="52">52</option>
                            <option value="53">53</option>
                            <option value="54">54</option>
                            <option value="55">55</option>
                            <option value="56">56</option>
                            <option value="57">57</option>
                            <option value="58">58</option>
                            <option value="59">59</option>
                            <option value="60">60</option>
                            <option value="61">61</option>
                            <option value="62">62</option>
                            <option value="63">63</option>
                            <option value="64">64</option>
                            <option value="65">65</option>
                            <option value="66">66</option>
                            <option value="67">67</option>
                            <option value="68">68</option>
                            <option value="69">69</option>
                            <option value="70">70</option>
                            <option value="71">71</option>
                            <option value="72">72</option>
                            <option value="73">73</option>
                            <option value="74">74</option>
                            <option value="75">75</option>
                            <option value="76">76</option>
                            <option value="77">77</option>
                            <option value="78">78</option>
                            <option value="79">79</option>
                            <option value="80">80</option>
                            <option value="81">81</option>
                            <option value="82">82</option>
                            <option value="83">83</option>
                            <option value="84">84</option>
                            <option value="85">85</option>
                            <option value="86">86</option>
                            <option value="87">87</option>
                            <option value="88">88</option>
                            <option value="89">89</option>
                            <option value="90">90</option>
                            <option value="91">91</option>
                            <option value="92">92</option>
                            <option value="93">93</option>
                            <option value="94">94</option>
                            <option value="95">95</option>
                            <option value="96">96</option>
                            <option value="97">97</option>
                            <option value="98">98</option>
                            <option value="99">99</option>
                          </select>
                          <div class="separator">-</div>
                          <select v-model="max_age">
                            <option value="" disabled>Max</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                            <option value="32">32</option>
                            <option value="33">33</option>
                            <option value="34">34</option>
                            <option value="35">35</option>
                            <option value="36">36</option>
                            <option value="37">37</option>
                            <option value="38">38</option>
                            <option value="39">39</option>
                            <option value="40">40</option>
                            <option value="41">41</option>
                            <option value="42">42</option>
                            <option value="43">43</option>
                            <option value="44">44</option>
                            <option value="45">45</option>
                            <option value="46">46</option>
                            <option value="47">47</option>
                            <option value="48">48</option>
                            <option value="49">49</option>
                            <option value="50">50</option>
                            <option value="51">51</option>
                            <option value="52">52</option>
                            <option value="53">53</option>
                            <option value="54">54</option>
                            <option value="55">55</option>
                            <option value="56">56</option>
                            <option value="57">57</option>
                            <option value="58">58</option>
                            <option value="59">59</option>
                            <option value="60">60</option>
                            <option value="61">61</option>
                            <option value="62">62</option>
                            <option value="63">63</option>
                            <option value="64">64</option>
                            <option value="65">65</option>
                            <option value="66">66</option>
                            <option value="67">67</option>
                            <option value="68">68</option>
                            <option value="69">69</option>
                            <option value="70">70</option>
                            <option value="71">71</option>
                            <option value="72">72</option>
                            <option value="73">73</option>
                            <option value="74">74</option>
                            <option value="75">75</option>
                            <option value="76">76</option>
                            <option value="77">77</option>
                            <option value="78">78</option>
                            <option value="79">79</option>
                            <option value="80">80</option>
                            <option value="81">81</option>
                            <option value="82">82</option>
                            <option value="83">83</option>
                            <option value="84">84</option>
                            <option value="85">85</option>
                            <option value="86">86</option>
                            <option value="87">87</option>
                            <option value="88">88</option>
                            <option value="89">89</option>
                            <option value="90">90</option>
                            <option value="91">91</option>
                            <option value="92">92</option>
                            <option value="93">93</option>
                            <option value="94">94</option>
                            <option value="95">95</option>
                            <option value="96">96</option>
                            <option value="97">97</option>
                            <option value="98">98</option>
                            <option value="99">99</option>
                          </select>
                        </div>

                        <div class="item">
                          <span class="show">Height :</span>
                          <select v-model="min_height">
                            <option value="" disabled>Min</option>
                            <option value="1">3'0"(91cm)</option>
                            <option value="2">3'1"(93cm)</option>
                            <option value="3">3'2"(96cm)</option>
                            <option value="4">3'3"(99cm)</option>
                            <option value="5">3'4"(101cm)</option>
                            <option value="6">3'5"(104cm)</option>
                            <option value="7">3'6"(106cm)</option>
                            <option value="8">3'7"(109cm)</option>
                            <option value="9">3'8"(111cm)</option>
                            <option value="10">3'9"(114cm)</option>
                            <option value="11">3'10"(116cm)</option>
                            <option value="12">3'11"(119cm)</option>
                            <option value="13">4'0"(121cm)</option>
                            <option value="14">4'1"(124cm)</option>
                            <option value="15">4'2"(127cm)</option>
                            <option value="16">4'3"(129cm)</option>
                            <option value="17">4'4"(132cm)</option>
                            <option value="18">4'5"(134cm)</option>
                            <option value="19">4'6"(137cm)</option>
                            <option value="20">4'7"(139cm)</option>
                            <option value="21">4'8"(142cm)</option>
                            <option value="22">4'9"(144cm)</option>
                            <option value="23">4'10"(147cm)</option>
                            <option value="24">4'11"(149cm)</option>
                            <option value="25">4'11"(149cm)</option>
                            <option value="26">5'0"(152cm)</option>
                            <option value="27">5'1"(154cm)</option>
                            <option value="28">5'2"(157cm)</option>
                            <option value="29">5'3"(160cm)</option>
                            <option value="30">5'4"(162cm)</option>
                            <option value="31">5'5"(165cm)</option>
                            <option value="32">5'6"(167cm)</option>
                            <option value="33">5'7"(170cm)</option>
                            <option value="34">5'8"(172cm)</option>
                            <option value="35">5'9"(175cm)</option>
                            <option value="36">5'10"(177cm)</option>
                            <option value="37">5'11"(180cm)</option>
                            <option value="38">6'0"(182cm)</option>
                            <option value="39">6'1"(185cm)</option>
                            <option value="40">6'2"(187cm)</option>
                            <option value="41">6'3"(190cm)</option>
                            <option value="42">6'4"(193cm)</option>
                            <option value="43">6'5"(195cm)</option>
                            <option value="44">6'6"(198cm)</option>
                            <option value="45">6'7"(200cm)</option>
                            <option value="46">6'8"(203cm)</option>
                            <option value="47">6'9"(205cm)</option>
                            <option value="48">6'10"(208cm)</option>
                            <option value="49">6'11"(210cm)</option>
                            <option value="50">7'0"(213cm)</option>
                            <option value="51">7'1"(215cm)</option>
                            <option value="52">7'2"(218cm)</option>
                            <option value="53">7'3"(220cm)</option>
                            <option value="54">7'4"(223cm)</option>
                            <option value="55">7'5"(226cm)</option>
                            <option value="56">7'6"(228cm)</option>
                          </select>
                          <div class="separator">-</div>
                          <select v-model="max_height">
                            <option value="" disabled>Max</option>
                            <option value="1">3'0"(91cm)</option>
                            <option value="2">3'1"(93cm)</option>
                            <option value="3">3'2"(96cm)</option>
                            <option value="4">3'3"(99cm)</option>
                            <option value="5">3'4"(101cm)</option>
                            <option value="6">3'5"(104cm)</option>
                            <option value="7">3'6"(106cm)</option>
                            <option value="8">3'7"(109cm)</option>
                            <option value="9">3'8"(111cm)</option>
                            <option value="10">3'9"(114cm)</option>
                            <option value="11">3'10"(116cm)</option>
                            <option value="12">3'11"(119cm)</option>
                            <option value="13">4'0"(121cm)</option>
                            <option value="14">4'1"(124cm)</option>
                            <option value="15">4'2"(127cm)</option>
                            <option value="16">4'3"(129cm)</option>
                            <option value="17">4'4"(132cm)</option>
                            <option value="18">4'5"(134cm)</option>
                            <option value="19">4'6"(137cm)</option>
                            <option value="20">4'7"(139cm)</option>
                            <option value="21">4'8"(142cm)</option>
                            <option value="22">4'9"(144cm)</option>
                            <option value="23">4'10"(147cm)</option>
                            <option value="24">4'11"(149cm)</option>
                            <option value="25">4'11"(149cm)</option>
                            <option value="26">5'0"(152cm)</option>
                            <option value="27">5'1"(154cm)</option>
                            <option value="28">5'2"(157cm)</option>
                            <option value="29">5'3"(160cm)</option>
                            <option value="30">5'4"(162cm)</option>
                            <option value="31">5'5"(165cm)</option>
                            <option value="32">5'6"(167cm)</option>
                            <option value="33">5'7"(170cm)</option>
                            <option value="34">5'8"(172cm)</option>
                            <option value="35">5'9"(175cm)</option>
                            <option value="36">5'10"(177cm)</option>
                            <option value="37">5'11"(180cm)</option>
                            <option value="38">6'0"(182cm)</option>
                            <option value="39">6'1"(185cm)</option>
                            <option value="40">6'2"(187cm)</option>
                            <option value="41">6'3"(190cm)</option>
                            <option value="42">6'4"(193cm)</option>
                            <option value="43">6'5"(195cm)</option>
                            <option value="44">6'6"(198cm)</option>
                            <option value="45">6'7"(200cm)</option>
                            <option value="46">6'8"(203cm)</option>
                            <option value="47">6'9"(205cm)</option>
                            <option value="48">6'10"(208cm)</option>
                            <option value="49">6'11"(210cm)</option>
                            <option value="50">7'0"(213cm)</option>
                            <option value="51">7'1"(215cm)</option>
                            <option value="52">7'2"(218cm)</option>
                            <option value="53">7'3"(220cm)</option>
                            <option value="54">7'4"(223cm)</option>
                            <option value="55">7'5"(226cm)</option>
                            <option value="56">7'6"(228cm)</option>
                          </select>
                        </div>

                        <div class="community-section inner-page">
                          <div class="container">
                            <div class="row">
                              <div class="header mt-3">Eye Color</div>
                              <div class="top-filter">
                                <div class="left">
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="1"
                                      value="1"
                                      v-model="eye_color"
                                    />
                                    Black
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="2"
                                      value="2"
                                      v-model="eye_color"
                                    />
                                    Blue
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="3"
                                      value="3"
                                      v-model="eye_color"
                                    />
                                    Brown
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="4"
                                      value="4"
                                      v-model="eye_color"
                                    />
                                    Green
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="5"
                                      value="5"
                                      v-model="eye_color"
                                    />
                                    Grey
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="6"
                                      value="6"
                                      v-model="eye_color"
                                    />
                                    Hazel
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="7"
                                      value="7"
                                      name="language"
                                      v-model="eye_color"
                                    />
                                    Color Contacts
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="header pr-5 mt-3">Build</div>
                              <div class="top-filter">
                                <div class="left">
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="1"
                                      value="1"
                                      v-model="build"
                                    />
                                    Slim
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="2"
                                      value="2"
                                      v-model="build"
                                    />
                                    Athletic
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="3"
                                      value="3"
                                      v-model="build"
                                    />
                                    About Average
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="4"
                                      value="4"
                                      v-model="build"
                                    />
                                    Few extra pounds
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="5"
                                      value="5"
                                      v-model="build"
                                    />
                                    Full figured
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="header mt-3">Hair Color</div>
                              <div class="top-filter noBorder">
                                <div class="left">
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="1"
                                      value="1"
                                      v-model="hair_color"
                                    />
                                    Auburn/Red
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="2"
                                      value="2"
                                      v-model="hair_color"
                                    />
                                    Bald
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="3"
                                      value="3"
                                      v-model="hair_color"
                                    />
                                    Black
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="4"
                                      value="4"
                                      v-model="hair_color"
                                    />
                                    Blonde
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="5"
                                      value="5"
                                      v-model="hair_color"
                                    />
                                    Dark Blonde
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="6"
                                      value="6"
                                      v-model="hair_color"
                                    />
                                    Dark Brown
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="7"
                                      value="7"
                                      v-model="hair_color"
                                    />
                                    Grey
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="8"
                                      value="8"
                                      v-model="hair_color"
                                    />
                                    Light Brown
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="9"
                                      value="9"
                                      v-model="hair_color"
                                    />
                                    Platinum
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="10"
                                      value="10"
                                      v-model="hair_color"
                                    />
                                    Salt and Pepper
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="11"
                                      value="11"
                                      v-model="hair_color"
                                    />
                                    Silver
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="12"
                                      value="12"
                                      v-model="hair_color"
                                    />
                                    Indescribable
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 2nd Accordion -->
                <div class="faq-item">
                  <div class="faq-title">
                    <h6 class="title">Professional Information</h6>
                    <span class="right-icon"></span>
                  </div>
                  <div class="faq-content">
                    <div class="filter-main">
                      <div class="right">
                        <div class="community-section inner-page">
                          <div class="container">
                            <div class="row">
                              <div class="header mt-3">Profession</div>
                              <div class="top-filter">
                                <div class="left">
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="1"
                                      value="1"
                                      v-model="profession"
                                    />
                                    Medical Doctor
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="2"
                                      value="2"
                                      v-model="profession"
                                    />
                                    Dentist
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="3"
                                      value="3"
                                      v-model="profession"
                                    />
                                    Computer Scientist
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="4"
                                      value="4"
                                      v-model="profession"
                                    />
                                    Engineer
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="5"
                                      value="5"
                                      v-model="profession"
                                    />
                                    Lawyer
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="6"
                                      value="6"
                                      v-model="profession"
                                    />
                                    Business Management
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="7"
                                      value="7"
                                      v-model="profession"
                                    />
                                    Teacher
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="8"
                                      value="8"
                                      v-model="profession"
                                    />
                                    Student
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="9"
                                      value="9"
                                      v-model="profession"
                                    />
                                    Accounting
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="10"
                                      value="10"
                                      v-model="profession"
                                    />
                                    Own Business
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="header pr-5 mt-3">Annual Income</div>
                              <div class="top-filter">
                                <div class="left">
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="1"
                                      value="1"
                                      v-model="annual_income"
                                    />
                                    Less Than $25,000
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="2"
                                      value="2"
                                      v-model="annual_income"
                                    />
                                    $25,000 to $35,000
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="3"
                                      value="3"
                                      v-model="annual_income"
                                    />
                                    $35,000 to $50,000
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="4"
                                      value="4"
                                      v-model="annual_income"
                                    />
                                    $50,000 to $75,000
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="5"
                                      value="5"
                                      v-model="annual_income"
                                    />
                                    $75,000 to $100,000
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="6"
                                      value="6"
                                      v-model="annual_income"
                                    />
                                    $100,000 to $150,000
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="7"
                                      value="7"
                                      v-model="annual_income"
                                    />
                                    $150,000+
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="header mt-3">Education</div>
                              <div class="top-filter noBorder">
                                <div class="left">
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="1"
                                      value="1"
                                      v-model="education"
                                    />
                                    High School
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="2"
                                      value="2"
                                      v-model="education"
                                    />
                                    Bachelors
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="3"
                                      value="3"
                                      v-model="education"
                                    />
                                    Masters
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="4"
                                      value="4"
                                      v-model="education"
                                    />
                                    Ph.D
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="5"
                                      value="5"
                                      v-model="education"
                                    />
                                    M.D.
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="6"
                                      value="6"
                                      v-model="education"
                                    />
                                    D.D.S
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="7"
                                      value="7"
                                      v-model="education"
                                    />
                                    J.D.
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="8"
                                      value="8"
                                      v-model="education"
                                    />
                                    D.O
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="9"
                                      value="9"
                                      v-model="education"
                                    />
                                    Other
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 3rd Accordion -->
                <div class="faq-item">
                  <div class="faq-title">
                    <h6 class="title">Background Information</h6>
                    <span class="right-icon"></span>
                  </div>
                  <div class="faq-content">
                    <div class="filter-main">
                      <div class="right">
                        <div class="community-section inner-page">
                          <div class="container">
                            <div class="row">
                              <div class="header mt-3">Religion</div>
                            </div>
                            <div class="row">
                              <div class="top-filter">
                                <div class="left">
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="1"
                                      value="1"
                                      v-model="religion"
                                    />
                                    Muslim
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="header pr-5 mt-3">Language</div>
                              <div class="top-filter">
                                <div class="left">
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="1"
                                      v-model="language"
                                    />
                                    Arabic
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="2"
                                      name="language"
                                      v-model="language"
                                    />
                                    Hebrew
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="3"
                                      name="language"
                                      v-model="language"
                                    />
                                    Russian
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="4"
                                      name="language"
                                      v-model="language"
                                    />
                                    Chinese
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="5"
                                      name="language"
                                      v-model="language"
                                    />
                                    Hindi
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="6"
                                      name="language"
                                      v-model="language"
                                    />
                                    Spanish
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="7"
                                      name="language"
                                      v-model="language"
                                    />
                                    Dutch
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="8"
                                      name="language"
                                      v-model="language"
                                    />
                                    Italian
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="9"
                                      name="language"
                                      v-model="language"
                                    />
                                    Tagalong
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="10"
                                      name="language"
                                      v-model="language"
                                    />
                                    English
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="11"
                                      name="language"
                                      v-model="language"
                                    />
                                    Japanese
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="12"
                                      name="language"
                                      v-model="language"
                                    />
                                    Urdu
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="13"
                                      name="language"
                                      v-model="language"
                                    />
                                    French
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="14"
                                      name="language"
                                      v-model="language"
                                    />
                                    Portuguese
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="15"
                                      name="language"
                                      v-model="language"
                                    />
                                    German
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="language"
                                      value="16"
                                      name="language"
                                      v-model="language"
                                    />
                                    Other
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="item mb-3">
                          <span class="show">Country :</span>
                          <select name="country" v-model="country" id="country">
                            <option value="" disabled>Select Country</option>
                            <option value="1">USA</option>
                            <option value="2">Canada</option>
                          </select>
                        </div>
                        <div class="item mb-3">
                          <span class="show">State :</span>
                          <select v-model="state">
                            <option value="" disabled>Please Select State</option>
                            <option v-for="state in states" :key="state"
                             :value="state.id">{{state.name}}</option>
                          </select>
                        </div>
                        <div class="item pb-3">
                          <span class="show">Country of Origin :</span>
                          <select v-model="origin">
                            <option value="" disabled>Please Select Country</option>
                            <option value="AFGHANISTAN">AFGHANISTAN</option>
                            <option value="ALBANIA">ALBANIA</option>
                            <option value="ALGERIA">ALGERIA</option>
                            <option value="AMERICAN SAMOA">AMERICAN SAMOA</option>
                            <option value="ANDORRA">ANDORRA</option>
                            <option value="ANGOLA">ANGOLA</option>
                            <option value="ANGUILLA">ANGUILLA</option>
                            <option value="ANTARCTICA">ANTARCTICA</option>
                            <option value="ANTIGUA AND BARBUDA">
                              ANTIGUA AND BARBUDA
                            </option>
                            <option value="ARGENTINA">ARGENTINA</option>
                            <option value="ARMENIA">ARMENIA</option>
                            <option value="ARUBA">ARUBA</option>
                            <option value="AUSTRALIA">AUSTRALIA</option>
                            <option value="AUSTRIA">AUSTRIA</option>
                            <option value="AZERBAIJAN">AZERBAIJAN</option>
                            <option value="BAHAMAS">BAHAMAS</option>
                            <option value="BAHRAIN">BAHRAIN</option>
                            <option value="BANGLADESH">BANGLADESH</option>
                            <option value="BARBADOS">BARBADOS</option>
                            <option value="BELARUS">BELARUS</option>
                            <option value="BELGIUM">BELGIUM</option>
                            <option value="BELIZE">BELIZE</option>
                            <option value="BENIN">BENIN</option>
                            <option value="BERMUDA">BERMUDA</option>
                            <option value="BHUTAN">BHUTAN</option>
                            <option value="BOLIVIA">BOLIVIA</option>
                            <option value="BOSNIA AND HERZEGOVINA">
                              BOSNIA AND HERZEGOVINA
                            </option>
                            <option value="BOTSWANA">BOTSWANA</option>
                            <option value="BOUVET ISLAND">BOUVET ISLAND</option>
                            <option value="BRAZIL">BRAZIL</option>
                            <option value="BRITISH INDIAN OCEAN TERRITORY">
                              BRITISH INDIAN OCEAN TERRITORY
                            </option>
                            <option value="BRUNEI DARUSSALAM">BRUNEI DARUSSALAM</option>
                            <option value="BULGARIA">BULGARIA</option>
                            <option value="BURKINA FASO">BURKINA FASO</option>
                            <option value="BURUNDI">BURUNDI</option>
                            <option value="CAMBODIA">CAMBODIA</option>
                            <option value="CAMEROON">CAMEROON</option>
                            <option value="CANADA">CANADA</option>
                            <option value="CAPE VERDE">CAPE VERDE</option>
                            <option value="CAYMAN ISLANDS">CAYMAN ISLANDS</option>
                            <option value="CENTRAL AFRICAN REPUBLIC">
                              CENTRAL AFRICAN REPUBLIC
                            </option>
                            <option value="CHAD">CHAD</option>
                            <option value="CHILE">CHILE</option>
                            <option value="CHINA">CHINA</option>
                            <option value="CHRISTMAS ISLAND">CHRISTMAS ISLAND</option>
                            <option value="COCOS (KEELING) ISLANDS">
                              COCOS (KEELING) ISLANDS
                            </option>
                            <option value="COLOMBIA">COLOMBIA</option>
                            <option value="COMOROS">COMOROS</option>
                            <option value="CONGO">CONGO</option>
                            <option value="CONGO, THE DEMOCRATIC REPUBLIC OF THE">
                              CONGO, THE DEMOCRATIC REPUBLIC OF THE
                            </option>
                            <option value="COOK ISLANDS">COOK ISLANDS</option>
                            <option value="COSTA RICA">COSTA RICA</option>
                            <option value="COTE D">COTE D'IVOIRE</option>
                            <option value="CROATIA">CROATIA</option>
                            <option value="CUBA">CUBA</option>
                            <option value="CYPRUS">CYPRUS</option>
                            <option value="CZECH REPUBLIC">CZECH REPUBLIC</option>
                            <option value="DENMARK">DENMARK</option>
                            <option value="DJIBOUTI">DJIBOUTI</option>
                            <option value="DOMINICA">DOMINICA</option>
                            <option value="DOMINICAN REPUBLIC">DOMINICAN REPUBLIC</option>
                            <option value="ECUADOR">ECUADOR</option>
                            <option value="EGYPT">EGYPT</option>
                            <option value="EL SALVADOR">EL SALVADOR</option>
                            <option value="EQUATORIAL GUINEA">EQUATORIAL GUINEA</option>
                            <option value="ERITREA">ERITREA</option>
                            <option value="ESTONIA">ESTONIA</option>
                            <option value="ETHIOPIA">ETHIOPIA</option>
                            <option value="FALKLAND ISLANDS (MALVINAS)">
                              FALKLAND ISLANDS (MALVINAS)
                            </option>
                            <option value="FAROE ISLANDS">FAROE ISLANDS</option>
                            <option value="FIJI">FIJI</option>
                            <option value="FINLAND">FINLAND</option>
                            <option value="FRANCE">FRANCE</option>
                            <option value="FRENCH GUIANA">FRENCH GUIANA</option>
                            <option value="FRENCH POLYNESIA">FRENCH POLYNESIA</option>
                            <option value="FRENCH SOUTHERN TERRITORIES">
                              FRENCH SOUTHERN TERRITORIES
                            </option>
                            <option value="GABON">GABON</option>
                            <option value="GAMBIA">GAMBIA</option>
                            <option value="GEORGIA">GEORGIA</option>
                            <option value="GERMANY">GERMANY</option>
                            <option value="GHANA">GHANA</option>
                            <option value="GIBRALTAR">GIBRALTAR</option>
                            <option value="GREECE">GREECE</option>
                            <option value="GREENLAND">GREENLAND</option>
                            <option value="GRENADA">GRENADA</option>
                            <option value="GUADELOUPE">GUADELOUPE</option>
                            <option value="GUAM">GUAM</option>
                            <option value="GUATEMALA">GUATEMALA</option>
                            <option value="GUINEA">GUINEA</option>
                            <option value="GUINEA-BISSAU">GUINEA-BISSAU</option>
                            <option value="GUYANA">GUYANA</option>
                            <option value="HAITI">HAITI</option>
                            <option value="HEARD ISLAND AND MCDONALD ISLANDS">
                              HEARD ISLAND AND MCDONALD ISLANDS
                            </option>
                            <option value="HOLY SEE (VATICAN CITY STATE)">
                              HOLY SEE (VATICAN CITY STATE)
                            </option>
                            <option value="HONDURAS">HONDURAS</option>
                            <option value="HONG KONG">HONG KONG</option>
                            <option value="HUNGARY">HUNGARY</option>
                            <option value="ICELAND">ICELAND</option>
                            <option value="INDIA">INDIA</option>
                            <option value="INDONESIA">INDONESIA</option>
                            <option value="IRAN, ISLAMIC REPUBLIC OF">
                              IRAN, ISLAMIC REPUBLIC OF
                            </option>
                            <option value="IRAQ">IRAQ</option>
                            <option value="IRELAND">IRELAND</option>
                            <option value="ISRAEL">ISRAEL</option>
                            <option value="ITALY">ITALY</option>
                            <option value="JAMAICA">JAMAICA</option>
                            <option value="JAPAN">JAPAN</option>
                            <option value="JORDAN">JORDAN</option>
                            <option value="KAZAKHSTAN">KAZAKHSTAN</option>
                            <option value="KENYA">KENYA</option>
                            <option value="KIRIBATI">KIRIBATI</option>
                            <option value="KOREA, DEMOCRATIC PEOPLE">
                              KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF
                            </option>
                            <option value="KOREA, REPUBLIC OF">KOREA, REPUBLIC OF</option>
                            <option value="KUWAIT">KUWAIT</option>
                            <option value="KYRGYZSTAN">KYRGYZSTAN</option>
                            <option value="LAO PEOPLE">
                              LAO PEOPLE'S DEMOCRATIC REPUBLIC
                            </option>
                            <option value="LATVIA">LATVIA</option>
                            <option value="LEBANON">LEBANON</option>
                            <option value="LESOTHO">LESOTHO</option>
                            <option value="LIBERIA">LIBERIA</option>
                            <option value="LIBYAN ARAB JAMAHIRIYA">
                              LIBYAN ARAB JAMAHIRIYA
                            </option>
                            <option value="LIECHTENSTEIN">LIECHTENSTEIN</option>
                            <option value="LITHUANIA">LITHUANIA</option>
                            <option value="LUXEMBOURG">LUXEMBOURG</option>
                            <option value="MACAO">MACAO</option>
                            <option value="MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF">
                              MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF
                            </option>
                            <option value="MADAGASCAR">MADAGASCAR</option>
                            <option value="MALAWI">MALAWI</option>
                            <option value="MALAYSIA">MALAYSIA</option>
                            <option value="MALDIVES">MALDIVES</option>
                            <option value="MALI">MALI</option>
                            <option value="MALTA">MALTA</option>
                            <option value="MARSHALL ISLANDS">MARSHALL ISLANDS</option>
                            <option value="MARTINIQUE">MARTINIQUE</option>
                            <option value="MAURITANIA">MAURITANIA</option>
                            <option value="MAURITIUS">MAURITIUS</option>
                            <option value="MAYOTTE">MAYOTTE</option>
                            <option value="MEXICO">MEXICO</option>
                            <option value="MICRONESIA, FEDERATED STATES OF">
                              MICRONESIA, FEDERATED STATES OF
                            </option>
                            <option value="MOLDOVA, REPUBLIC OF">
                              MOLDOVA, REPUBLIC OF
                            </option>
                            <option value="MONACO">MONACO</option>
                            <option value="MONGOLIA">MONGOLIA</option>
                            <option value="MONTSERRAT">MONTSERRAT</option>
                            <option value="MOROCCO">MOROCCO</option>
                            <option value="MOZAMBIQUE">MOZAMBIQUE</option>
                            <option value="MYANMAR">MYANMAR</option>
                            <option value="NAMIBIA">NAMIBIA</option>
                            <option value="NAURU">NAURU</option>
                            <option value="NEPAL">NEPAL</option>
                            <option value="NETHERLANDS">NETHERLANDS</option>
                            <option value="NETHERLANDS ANTILLES">
                              NETHERLANDS ANTILLES
                            </option>
                            <option value="NEW CALEDONIA">NEW CALEDONIA</option>
                            <option value="NEW ZEALAND">NEW ZEALAND</option>
                            <option value="NICARAGUA">NICARAGUA</option>
                            <option value="NIGER">NIGER</option>
                            <option value="NIGERIA">NIGERIA</option>
                            <option value="NIUE">NIUE</option>
                            <option value="NORFOLK ISLAND">NORFOLK ISLAND</option>
                            <option value="NORTHERN MARIANA ISLANDS">
                              NORTHERN MARIANA ISLANDS
                            </option>
                            <option value="NORWAY">NORWAY</option>
                            <option value="OMAN">OMAN</option>
                            <option value="PAKISTAN">PAKISTAN</option>
                            <option value="PALAU">PALAU</option>
                            <option value="PALESTINIAN TERRITORY, OCCUPIED">
                              PALESTINIAN TERRITORY, OCCUPIED
                            </option>
                            <option value="PANAMA">PANAMA</option>
                            <option value="PAPUA NEW GUINEA">PAPUA NEW GUINEA</option>
                            <option value="PARAGUAY">PARAGUAY</option>
                            <option value="PERU">PERU</option>
                            <option value="PHILIPPINES">PHILIPPINES</option>
                            <option value="PITCAIRN">PITCAIRN</option>
                            <option value="POLAND">POLAND</option>
                            <option value="PORTUGAL">PORTUGAL</option>
                            <option value="PUERTO RICO">PUERTO RICO</option>
                            <option value="QATAR">QATAR</option>
                            <option value="REUNION">REUNION</option>
                            <option value="ROMANIA">ROMANIA</option>
                            <option value="RUSSIAN FEDERATION">RUSSIAN FEDERATION</option>
                            <option value="RWANDA">RWANDA</option>
                            <option value="SAINT HELENA">SAINT HELENA</option>
                            <option value="SAINT KITTS AND NEVIS">
                              SAINT KITTS AND NEVIS
                            </option>
                            <option value="SAINT LUCIA">SAINT LUCIA</option>
                            <option value="SAINT PIERRE AND MIQUELON">
                              SAINT PIERRE AND MIQUELON
                            </option>
                            <option value="SAINT VINCENT AND THE GRENADINES">
                              SAINT VINCENT AND THE GRENADINES
                            </option>
                            <option value="SAMOA">SAMOA</option>
                            <option value="SAN MARINO">SAN MARINO</option>
                            <option value="SAO TOME AND PRINCIPE">
                              SAO TOME AND PRINCIPE
                            </option>
                            <option value="SAUDI ARABIA">SAUDI ARABIA</option>
                            <option value="SENEGAL">SENEGAL</option>
                            <option value="SERBIA AND MONTENEGRO">
                              SERBIA AND MONTENEGRO
                            </option>
                            <option value="SEYCHELLES">SEYCHELLES</option>
                            <option value="SIERRA LEONE">SIERRA LEONE</option>
                            <option value="SINGAPORE">SINGAPORE</option>
                            <option value="SLOVAKIA">SLOVAKIA</option>
                            <option value="SLOVENIA">SLOVENIA</option>
                            <option value="SOLOMON ISLANDS">SOLOMON ISLANDS</option>
                            <option value="SOMALIA">SOMALIA</option>
                            <option value="SOUTH AFRICA">SOUTH AFRICA</option>
                            <option value="SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS">
                              SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS
                            </option>
                            <option value="SPAIN">SPAIN</option>
                            <option value="SRI LANKA">SRI LANKA</option>
                            <option value="SUDAN">SUDAN</option>
                            <option value="SURINAME">SURINAME</option>
                            <option value="SVALBARD AND JAN MAYEN">
                              SVALBARD AND JAN MAYEN
                            </option>
                            <option value="SWAZILAND">SWAZILAND</option>
                            <option value="SWEDEN">SWEDEN</option>
                            <option value="SWITZERLAND">SWITZERLAND</option>
                            <option value="SYRIAN ARAB REPUBLIC">
                              SYRIAN ARAB REPUBLIC
                            </option>
                            <option value="TAIWAN, PROVINCE OF CHINA">
                              TAIWAN, PROVINCE OF CHINA
                            </option>
                            <option value="TAJIKISTAN">TAJIKISTAN</option>
                            <option value="TANZANIA, UNITED REPUBLIC OF">
                              TANZANIA, UNITED REPUBLIC OF
                            </option>
                            <option value="THAILAND">THAILAND</option>
                            <option value="TIMOR-LESTE">TIMOR-LESTE</option>
                            <option value="TOGO">TOGO</option>
                            <option value="TOKELAU">TOKELAU</option>
                            <option value="TONGA">TONGA</option>
                            <option value="TRINIDAD AND TOBAGO">
                              TRINIDAD AND TOBAGO
                            </option>
                            <option value="TUNISIA">TUNISIA</option>
                            <option value="TURKEY">TURKEY</option>
                            <option value="TURKMENISTAN">TURKMENISTAN</option>
                            <option value="TURKS AND CAICOS ISLANDS">
                              TURKS AND CAICOS ISLANDS
                            </option>
                            <option value="TUVALU">TUVALU</option>
                            <option value="UGANDA">UGANDA</option>
                            <option value="UKRAINE">UKRAINE</option>
                            <option value="UNITED ARAB EMIRATES">
                              UNITED ARAB EMIRATES
                            </option>
                            <option value="UNITED KINGDOM">UNITED KINGDOM</option>
                            <option value="UNITED STATES">UNITED STATES</option>
                            <option value="UNITED STATES MINOR OUTLYING ISLANDS">
                              UNITED STATES MINOR OUTLYING ISLANDS
                            </option>
                            <option value="URUGUAY">URUGUAY</option>
                            <option value="UZBEKISTAN">UZBEKISTAN</option>
                            <option value="VANUATU">VANUATU</option>
                            <option value="VENEZUELA">VENEZUELA</option>
                            <option value="VIET NAM">VIET NAM</option>
                            <option value="VIRGIN ISLANDS, BRITISH">
                              VIRGIN ISLANDS, BRITISH
                            </option>
                            <option value="VIRGIN ISLANDS, U.S.">
                              VIRGIN ISLANDS, U.S.
                            </option>
                            <option value="WALLIS AND FUTUNA">WALLIS AND FUTUNA</option>
                            <option value="WESTERN SAHARA">WESTERN SAHARA</option>
                            <option value="YEMEN">YEMEN</option>
                            <option value="ZAMBIA">ZAMBIA</option>
                            <option value="ZIMBABWE">ZIMBABWE</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 4th Accordion -->
                <div class="faq-item">
                  <div class="faq-title">
                    <h6 class="title">Other Information</h6>
                    <span class="right-icon"></span>
                  </div>
                  <div class="faq-content">
                    <div class="filter-main">
                      <div class="right">
                        <div class="community-section inner-page">
                          <div class="container">
                            <div class="row">
                              <div class="header mt-3">Marital Status</div>
                            </div>
                            <div class="row">
                              <div class="top-filter">
                                <div class="left">
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="1"
                                      value="1"
                                      name="eyecolor"
                                      v-model="marital_status"
                                    />
                                    Never Married
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="2"
                                      value="2"
                                      v-model="marital_status"
                                    />
                                    Divorced
                                  </label>
                                  <label class="mr-3 mb-4">
                                    <input
                                      class="custom-button"
                                      type="checkbox"
                                      id="3"
                                      value="3"
                                      v-model="marital_status"
                                    />
                                    Widow/Widower
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="item mb-3">
                            <span class="show">Want Children</span>
                            <select v-model="want_children">
                              <option value="" disabled selected>Select One</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                              <option value="3">May be</option>
                            </select>
                          </div>
                          <div class="item pb-3">
                            <span class="show">Have Children :</span>
                            <select v-model="have_children">
                              <option value="" disabled>Select One</option>
                              <option value="0">Yes</option>
                              <option value="1">No</option>
                            </select>
                          </div>
                          <div class="item pb-3">
                            <span class="show">Smoke</span>
                            <select v-model="smoke" id="smoke">
                              <option value="" disabled>Select One</option>
                              <option value="0">Yes</option>
                              <option value="1">No</option>
                            </select>
                          </div>
                          <div class="item pb-3">
                            <span class="show">With Pics Only</span>
                            <select v-model="with_pics">
                              <option value="" disabled>Select One</option>
                              <option value="1">Yes</option>
                              <option value="2">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="ishidden" class="single-option last">
              <input
                placeholder="Please Enter Search Title"
                v-model="title"
                class="single-input-advance"
              />
            </div>

            <div class="joun-button">
              <a href="" @click.prevent="search" style="margin: 15px"
                ><button class="custom-button">Search Now!</button></a
              >
              <a href="" @click.prevent="showtitle"
                ><button class="custom-button">Save Search</button></a
              >
            </div>
            <br />
            <div class="alert alert-success" v-if="updated" role="alert">
              Profile Updated Successfully!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: "AdvancedSearchForm",

  data() {
    return {
      ishidden: false,
      currentStep: 1,
      selected_at: "",
      title: "",
      min_age: "",
      max_age: "",
      min_height: "",
      max_height: "",
      eye_color: [],
      build: [],
      hair_color: [],
      profession: [],
      annual_income: [],
      education: [],
      religion: [],
      language: [],
      country: "",
      state: "",
      states: "",
      origin: "",
      marital_status: [],
      want_children: "",
      have_children: "",
      smoke: "",
      with_pics: "",
      search_results: "",
      search_id: '',

      result: '',
    };
  },

  methods: {
    async search() {
      this.$router.push({
        path: "search-results",
        query: {
          search: "advance-search",
          title: this.title,
          min_age: this.min_age,
          max_age: this.max_age,
          min_height: this.min_height,
          max_height: this.max_height,
          eye_color: this.eye_color,
          build: this.build,
          hair_color: this.hair_color,
          profession: this.profession,
          annual_income: this.annual_income,
          education: this.education,
          religion: this.religion,
          language: this.language,
          country: this.country,
          state: this.state,
          origin: this.origin,
          marital_status: this.marital_status,
          want_childern: this.want_children,
          have_childern: this.have_children,
          smoke: this.smoke,
          with_pics: this.with_pics,
        },
      });
    },

    async showtitle() {
      if (this.currentStep == 1) {
        this.ishidden = !this.ishidden;
        this.currentStep++;
        return;
      }
      if (this.currentStep > 1) {
        this.save();
        return;
      }
    },

    async save() {
      if (this.currentStep == 2 && this.title != "") {
        var formdata = new FormData();
        formdata.append("title", this.title);
        formdata.append("min_age", this.min_age);
        formdata.append("max_age", this.max_age);
        formdata.append("min_height", this.min_height);
        formdata.append("max_height", this.max_height);
        formdata.append("eye_color", this.eye_color);
        formdata.append("build", this.build);
        formdata.append("hair_color", this.hair_color);
        formdata.append("profession", this.profession);
        formdata.append("annual_income", this.annual_income);
        formdata.append("education", this.education);
        formdata.append("religion", this.religion);
        formdata.append("language", this.language);
        formdata.append("country", this.country);
        formdata.append("state", this.state);
        formdata.append("origin", this.origin);
        formdata.append("marital_status", this.marital_status);
        formdata.append("want_childern", this.want_children);
        formdata.append("have_childern", this.have_children);
        formdata.append("smoke", this.smoke);
        formdata.append("with_pics", this.with_pics);
        await this.axios
          .post("add_saved_search", formdata, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            this.result = "Search Criteria Saved Successfully";
          })
          .catch((err) => {
            this.result = "Error Saving Search Criteria Please Try Again";
          });
      }
    },

    async getstates()
    {
      await this.axios
        .get("get_states", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          // console.log(result);
          this.states = result.data.data.states;
        })
        .catch((err) => { });
    },
  },

  mounted() {
    window.scrollTo(0,0)
    this.getstates();
  },
  // mounted() {
  //   this.getStates();
  //   //Accordian
  //   // $(".faq-wrapper .faq-title").on("click", function (e) {
  //   //   var element = $(this).parent(".faq-item");
  //   //   if (element.hasClass("open")) {
  //   //     element.removeClass("open");
  //   //     element.find(".faq-content").removeClass("open");
  //   //     element.find(".faq-content").slideUp(300, "swing");
  //   //   } else {
  //   //     element.addClass("open");
  //   //     element.children(".faq-content").slideDown(300, "swing");
  //   //     element.siblings(".faq-item").children(".faq-content").slideUp(300, "swing");
  //   //     element.siblings(".faq-item").removeClass("open");
  //   //     element
  //   //       .siblings(".faq-item")
  //   //       .find(".faq-title, .faq-title-two")
  //   //       .removeClass("open");
  //   //     element.siblings(".faq-item").find(".faq-content").slideUp(300, "swing");
  //   //   }
  //   // });
  // },
};
</script>
