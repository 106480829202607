<template>
<loader v-if="this.LoadingTimingFlag==false" object="#6610f2" color1="#ffffff" color2="#6610f2" size="5" speed="2" bg="#333333" objectbg="#333333" opacity="50" name="spinning"></loader>
  <section class="breadcrumb-area profile-bc-area profile-section">
    <div class="container">
      <div class="content row">
        <div class="col-lg-4 col-md-5  col-sm-12 centrItemAlign">
          <!-- profile-pic-styling -->
          <div class="profile-img profile-pic-styling">
            <img class="pic-styling" :src="image" alt="" style="width: 250px; height: 250px; object-fit: cover;object-position:top;" />
            <div class="active-online"></div>
          </div>
          <!-- <div class="col-lg-6 col-sm-12 col-12 content FavouriteButton">
            <button v-if="fav_type == false" @click="favProfile" class="custom-button">
              <i class="far fa-heart"></i> Add to Favourites
            </button>
            <button v-if="fav_type == true" @click="favProfile" class="custom-button">
              <i class="far fa-heart"></i> Remove Favourite
            </button>
          </div> -->
          <br />
        </div>

        <div class="col-lg-7 col-md-7 col-sm-12 content  centrItemAlign">
          <h2 class="title">
            {{ member.first_name }} {{ member.last_name }}
          </h2>
          <div>
            <span style="color: white; font-size: small;"> {{ profession }}</span>
          </div><br />
          <ul style="display: flex; justify-content: space-between; color: white; font-size: small;">
            <li style="text-align: center;">
              <img src="../../../public/assets/isna-images/AgeIcon.png" alt="" class="ImageIconSetting"
                style="width: 40px;height: 28px;">
              <p>{{ age }}</p>
            </li>
            <li v-if="maritalStatus != ''" style="text-align: center;">
              <img src="../../../public/assets/isna-images/MartialStatus.png" alt="" style="width: 38px;height: 28px;">
              <p>{{ maritalStatus }}</p>
            </li>
            <li style="text-align: center;">
              <i class="fas fa-mars-double" style="font-size: 16px;"></i>
              <p>{{ member.gender }}</p>
            </li>
            <li style="text-align: center;">
              <img src="../../../public/assets/isna-images/City.png" alt="" style="width: 38px;height: 28px;">
              <p>{{ member.name }}</p>
            </li>
            <li style="text-align: center;">
              <img src="../../../public/assets/isna-images/Country.png" alt="" style="width: 40px;height: 28px;">
              <p>{{this.country}}</p>
            </li>
          </ul>
          <p style="color: white;">
            {{ description }}
          </p>
          <div v-if="isLoggedIn" class="Bottom_Button">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12  content">
             <button v-if="fav_type == false" @click="favProfile" class="custom-button">
              <i class="far fa-heart"></i> Add to Favourites
            </button>
            <button v-if="fav_type == true" @click="favProfile" class="custom-button">
              <i class="far fa-heart"></i> Remove Favourite
            </button>

            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12 content">
              <router-link class="custom-button" :to="{
                path: '/chat',
                query: {
                  member_id: `${member.member_id}`,
                },
              }">
                <i class="far fa-comment-dots"></i> Send Message
              </router-link>
            </div>
          </div>
        <div style="width: 100%; background: greenyellow;" v-if="this.messageSend==true">{{insterestMessage}}</div>
        </div>
      </div>
    </div>
  </section>
  <MemberGallery :member_id="member_id"></MemberGallery>
  <section class="profile-section product-details-section">
    <div class="container">
      <div class="row ">
        <div>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="frist">
              <div class="tab-content-wrapper tabStyling">
                <div class="info-box">

                  <!-- Personal Information -->
                  <div>
                    <!--  Professional Profile -->
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <h6><i class="fas fa-id-card"></i> Professional Profile </h6>
                      </div>
                      <div class="col-lg-12 col-md-12  col-12 MainDiv">
                        <div class="col-lg-5 col-md-5  col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-6 col-md-6 ">
                            <label>Education</label>
                          </div>
                          <div class="col-lg-6 col-md-6 ">
                            <p>{{ education }}</p>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2  col-12"></div>
                        <div class="col-lg-5 col-md-5 " style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-4 col-md-6 ">
                            <label>Profession</label>
                          </div>
                          <div class="col-lg-8 col-md-6 ">
                            <p>{{ profession }}</p>
                          </div>
                        </div>

                      </div>
                      <div class="col-lg-12 col-md-12 col-12 MainDiv">
                        <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-4 col-md-6">
                            <label>Annual Income</label>
                          </div>
                          <div class="col-lg-8 col-md-6 ">
                            <p>{{ annualIncome1 }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />

                    <!--  Physical Appearance -->
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <h6><span><i class="fas fa-male"></i></span> Physical Appearance </h6>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12 MainDiv">
                        <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-6 col-md-6 ">
                            <label>Height</label>
                          </div>
                          <div class="col-lg-6 col-md-6 ">
                            <p>{{ height }}</p>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12"></div>
                        <div class="col-lg-5 col-md-5" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-4 col-md-6 ">
                            <label>Eye Color</label>
                          </div>
                          <div class="col-lg-8 col-md-6 ">
                            <p>{{ eyeColor }}</p>
                          </div>
                        </div>

                      </div>
                      <div class="col-lg-12 col-md-12 col-12 MainDiv">
                        <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-6 col-md-6 ">
                            <label>Hair Color</label>
                          </div>
                          <div class="col-lg-6 col-md-6 ">
                            <p>{{ hairColor }}</p>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12"></div>
                        <div class="col-lg-5 col-md-5" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-4 col-md-6 ">
                            <label>Body Type</label>
                          </div>
                          <div class="col-lg-8 col-md-6 ">
                            <p>{{ bodyType }}</p>
                          </div>
                        </div>

                      </div>
                      <div class="col-lg-12 col-md-12 col-12 MainDiv">
                        <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-6 col-md-6 ">
                            <label>Disability</label>
                          </div>
                          <div class="col-lg-6 col-md-6 ">
                            <p>{{ disability }}</p>
                          </div>
                        </div>

                      </div>


                    </div>
                    <br />

                    <!-- Location Information -->

                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <h6><span><i class="fas fa-map"></i></span> Location Information </h6>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12 MainDiv">
                        <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-6 col-md-6 ">
                            <label>Country of Origin</label>
                          </div>
                          <div class="col-lg-6 col-md-6 ">
                            <p>{{ origin }}</p>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12"></div>
                        <div class="col-lg-5 col-md-5" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-5 col-md-6 ">
                            <label>Currently lives In</label>
                          </div>
                          <div class="col-lg-7 col-md-6 ">
                            <p>{{this.country}}</p>
                          </div>
                        </div>

                      </div>
                      <div class="col-lg-12 col-md-12 col-12 MainDiv">
                        <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-6 col-md-6 ">
                            <label>State</label>
                          </div>
                          <div class="col-lg-6 col-md-6 ">
                            <p>{{ member.name }}</p>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12"></div>
                        <div class="col-lg-5 col-md-5" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-4 col-md-6 ">
                            <label>City</label>
                          </div>
                          <div class="col-lg-8 col-md-6 ">
                            <p>{{ member.city }}</p>
                          </div>
                        </div>

                      </div>
                      <div class="col-lg-12 col-md-12 col-12 MainDiv">
                        <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-6 col-md-6 ">
                            <label>Zip Code</label>
                          </div>
                          <div class="col-lg-6 col-md-6 ">
                            <p v-if = "member.zip_code != Null">{{ member.zip_code }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />

                    <!-- Marital Information -->
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <h6><img src="../../../public/assets/isna-images/engagement-ring.png" alt=""
                            style="width: 17px;height: 17px; margin-top: -7;"> Marital Information </h6>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12 MainDiv">
                        <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-6 col-md-6 ">
                            <label>Marital Status</label>
                          </div>
                          <div class="col-lg-6 col-md-6 ">
                            <p style="text-align: center;">{{ maritalStatus }}</p>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12"></div>
                        <div class="col-lg-5 col-md-5" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-4 col-md-6 ">
                            <label>Have Child</label>
                          </div>
                          <div class="col-lg-8 col-md-6 ">
                            <p style="text-align: center;">{{ haveChild }}</p>
                          </div>
                        </div>

                      </div>
                      <div class="col-lg-12 col-md-12 col-12 MainDiv">
                        <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-4 col-md-6">
                            <label>Want Child</label>
                          </div>
                          <div class="col-lg-8 col-md-6 ">
                            <p style="text-align: center;">{{ wantChild }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>

                  <div>
                    <h4><i class="fas fa-search"></i> {{ member.first_name }} {{ member.last_name }} Is Looking For</h4>
                    <br />
                    <!-- Looking For Personal Information -->
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <h6><i class="fas fa-info-circle"></i> Personal Information </h6>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12 MainDiv">
                        <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-6 col-md-6 ">
                            <label>Gender</label>
                          </div>
                          <div class="col-lg-6 col-md-6 ">
                            <p> {{ match.gender }}</p>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12"></div>
                        <div class="col-lg-5 col-md-5" style="display: flex; border-bottom: 3px solid #dee2e6;">
                          <div class="col-lg-4 col-md-6 ">
                            <label>Age</label>
                          </div>
                          <div class="col-lg-8 col-md-6 ">
                            <p v-if="match.age_range != ''">{{ match.age_range }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                  <!--Looking For  Professional Profile -->
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <h6><i class="fas fa-id-card"></i> Professional Profile </h6>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12 MainDiv">
                      <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-6 col-md-6 ">
                          <label>Education</label>
                        </div>
                        <div class="col-lg-6 col-md-6 ">
                            <span v-if="match.education.length != 0">{{ match.education }}</span>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12"></div>
                      <div class="col-lg-5 col-md-5" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-4 col-md-6 ">
                          <label>Profession</label>
                        </div>
                        <div class="col-lg-8 col-md-6 ">
                            <span>{{ profession1 }}</span>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 col-md-12 col-12 MainDiv">
                      <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-4 col-md-6">
                          <label>Annual Income</label>
                        </div>
                        <div class="col-lg-8 col-md-6 ">
                            <span v-if="match.annualIncome.length != 0">{{ match.annualIncome }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                  <!--Looking For Physical Appearance -->
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <h6><span><i class="fas fa-male"></i></span> Physical Appearance </h6>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12 MainDiv">
                      <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-6 col-md-6 ">
                          <label>Height</label>
                        </div>
                        <div class="col-lg-6 col-md-6 ">
                            <span v-if="match.height_range.length != 0">{{ match.height_range }}</span>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12"></div>
                      <div class="col-lg-5 col-md-5" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-4 col-md-6 ">
                          <label>Eye Color</label>
                        </div>
                        <div class="col-lg-8 col-md-6 ">
                            <span v-if="match.eye_color.length != 0">{{ match.eye_color }}</span>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 col-md-12 col-12 MainDiv">
                      <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-6 col-md-6 ">
                          <label>Hair Color</label>
                        </div>
                        <div class="col-lg-6 col-md-6 ">
                            <span v-if="match.hair_color.length != 0">{{ match.hair_color }}</span>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12"></div>
                      <div class="col-lg-5 col-md-5" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-4 col-md-6 ">
                          <label>Body Type</label>
                        </div>
                        <div class="col-lg-8 col-md-6 ">
                            <span>{{ match.bodyType }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                  <!--Looking For Location Information -->

                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <h6><span><i class="fas fa-map"></i></span> Location Information </h6>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12 MainDiv">
                      <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-6 col-md-6 ">
                          <label>Country of Origin</label>
                        </div>
                        <div class="col-lg-6 col-md-6 ">
                          <p>{{ match.origin }}</p>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12"></div>
                      <div class="col-lg-5 col-md-5" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-5 col-md-6 ">
                          <label>Currently lives In</label>
                        </div>
                        <div class="col-lg-7 col-md-6 ">
                          <p>{{match.country}}</p>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 col-md-12 col-12 MainDiv">
                      <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-6 col-md-6 ">
                          <label>State</label>
                        </div>
                        <div class="col-lg-6 col-md-6 ">
                          <p>{{ match.state }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <!--Looking For Marital Information -->
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <h6><img src="../../../public/assets/isna-images/engagement-ring.png" alt=""
                          style="width: 17px;height: 17px; margin-top: -7;"> Marital Information </h6>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12 MainDiv">
                      <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-6 col-md-6 ">
                          <label>Marital Status</label>
                        </div>
                        <div class="col-lg-6 col-md-6 ">
                            <span v-if="match.maritalStatus.length != 0">{{ match.maritalStatus }}</span>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12"></div>
                      <div class="col-lg-5 col-md-5" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-4 col-md-6 ">
                          <label>Have Child</label>
                        </div>
                        <div class="col-lg-8 col-md-6 ">
                            <span>{{ match.haveChild }}</span>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 col-md-12 col-12 MainDiv">
                      <div class="col-lg-5 col-md-5 col-12" style="display: flex; border-bottom: 3px solid #dee2e6;">
                        <div class="col-lg-4 col-md-6">
                          <label>Want Child</label>
                        </div>
                        <div class="col-lg-8 col-md-6 ">
                            <span>{{ match.wantChild }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-xl-6 col-lg-6 col-md-6">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="frist">
              <div class="tab-content-wrapper tabStyling">
                <div class="info-box">
                  <div class="header p-3">
                    <h4 class="title">Looking For</h4>
                  </div>
                  <div class="content">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th>Gender</th>
                          <td>{{ match.gender }}</td>
                        </tr>
                        <tr>
                          <th>Age</th>
                          <td>{{ match.age_range }}</td>
                        </tr>
                        <tr>
                          <th>Citizenship</th>
                          <td>
                            <span v-for="(usa_status, index) in match.usa_status" :key="usa_status">
                              <span>{{ usa_status }}</span>
                              <span v-if="index + 1 < usa_status.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Location</th>
                          <td>{{ match.state }} , {{ match.country }} </td>
                        </tr>
                        <tr>
                          <th>Height</th>
                          <td>
                            <span v-for="(height_range, index) in match.height_range" :key="height_range">
                              <span>{{ height_range }}</span>
                              <span v-if="index + 1 < height_range.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Eye Color</th>
                          <td>
                            <span v-for="(eye_color, index) in match.eye_color" :key="eye_color">
                              <span>{{ eye_color }}</span>
                              <span v-if="index + 1 < eye_color.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Hair Color</th>
                          <td>
                            <span v-for="(hair_color, index) in match.hair_color" :key="hair_color">
                              <span>{{ hair_color }}</span>
                              <span v-if="index + 1 < hair_color.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Profession</th>
                          <td>
                            <span v-for="(profession, index) in match.profession" :key="profession">
                              <span>{{ profession }}</span>
                              <span v-if="index + 1 < profession.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Annual Income</th>
                          <td>
                            <span v-for="(annualIncome, index) in match.annualIncome" :key="annualIncome">
                              <span>{{ annualIncome }}</span>
                              <span v-if="index + 1 < annualIncome.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Body Type</th>
                          <td>
                            <span v-for="(bodyType, index) in match.bodyType" :key="bodyType">
                              <span>{{ bodyType }}</span>
                              <span v-if="index + 1 < bodyType.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Education</th>
                          <td>
                            <span v-for="(education, index) in match.education" :key="education">
                              <span>{{ education }}</span>
                              <span v-if="index + 1 < education.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Marital Status</th>
                          <td>
                            <span v-for="(maritalStatus, index) in match.maritalStatus" :key="maritalStatus">
                              <span>{{ maritalStatus }}</span>
                              <span v-if="index + 1 < maritalStatus.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Want Children</th>
                          <td>
                            <span v-for="(wantChild, index) in match.wantChild" :key="wantChild">
                              <span>{{ wantChild }}</span>
                              <span v-if="index + 1 < wantChild.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Have Children</th>
                          <td>
                            <span v-for="(haveChild, index) in match.haveChild" :key="haveChild">
                              <span>{{ haveChild }}</span>
                              <span v-if="index + 1 < haveChild.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Religion</th>
                          <td>
                            <span v-for="(religion, index) in match.religion" :key="religion">
                              <span>{{ religion }}</span>
                              <span v-if="index + 1 < religion.length">, </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Smoke</th>
                          <td>{{ this.match.match_smoke }}</td>
                        </tr>
                        <tr>
                          <th>Language</th>
                          <td>
                            <span v-for="(language, index) in match.language" :key="language">
                              <span>{{ language }}</span>
                              <span v-if="index + 1 < language.length">, </span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import MemberGallery from "@/components/MemberGallery.vue";
export default {
  name: "MemberProfile",
  components: {
    MemberGallery,
  },
  data() {
    return {
       LoadingTimingFlag: false,
      insterestMessage:"",
      messageSend:false,
      member_id: this.$route.query.member_id,
      member: "",
      age: '',
      meta: "",
      image: "",
      fav_type: false,
      height: "",
      eyeColor: "",
      hairColor: "",
      bodyType: "",
      profession: "",
      other_profession: "",
      annualIncome1: "",
      education: "",
      other_education: "",
      origin: "",
      religion: "",
      status: "",
      language: [],
      meta_data: [],
      match_data: [],
      profession1: "",
      maritalStatus: "",
      wantChild: "",
      haveChild: "",
      smoke: "",
      message: "",
      desiredQualities: "",
      disability: "",
      walli: "",
      guardianName: "",
      guardianRelation: "",
      r1Name: "",
      r1Phone: "",
      r2Name: "",
      r2Phone: "",
      meta: [],
      match: {
        gender: '',
        age_range: [],
        usa_status: [],
        country: '',
        height_range: [],
        eye_color: [],
        hair_color: [],
        profession: [],
        annualIncome: [],
        origin: '',
        bodyType: '',
        education: [],
        maritalStatus: [],
        wantChild: '',
        haveChild: '',
        religion: '',
        match_smoke: '',
        language: [],
        desiredQualities: '',
        description: "",
        disability: ""
      },
      temp: {
        temp_edu: [],
        temp_language: [],
        temp_annualIncom: [],
        temp_hairColor: [],
        temp_heightRange: [],
        temp_ageRange: [],
        temp_eyeColor: [],
        temp_Profession: [],
        temp_bodyType: [],
        temp_martialStatus: [],
        temp_usastatus: [],
      }
    };
  },
  methods: {
    async getprofile() {
      this.axios
        .get("get_member_profile/" + this.$route.query.member_id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          if(localStorage.getItem("user") != null){
            console.log("Test");
          }
          if(localStorage.getItem("user") != null){
           if(result.data.data.member_profile[0].gender == JSON.parse(localStorage.getItem("user"))[0].gender){
            localStorage.setItem('redirect_url', '');
            this.$router.push("dashboard");
            alert("Not allow to view profile of same gender.");
           }else{
          console.log(result.data.data, "profileData if")
          this.member = result.data.data.member_profile[0];
          this.match_data = result.data.data.match_data;
          this.meta_data = result.data.data.meta;
          this.image = result.data.data.member_image;
          this.profession = result.data.data.profession.profession_name;
          this.state = result.data.data.state;
          this.country = result.data.data.country;
          this.description = result.data.data.orignal_meta[17].meta_value;
          this.getAge();
          this.getprofilemeta();
           }
          }else{
          console.log(result.data.data, "profileData else")
          this.member = result.data.data.member_profile[0];
          this.meta_data = result.data.data.meta;
          this.match_data = result.data.data.match_data;
          console.log(this.match_data,"this.match_data ");
          this.image = result.data.data.member_image;
          this.profession = result.data.data.profession.profession_name;
          this.state = result.data.data.state;
          if(result.data.data.amount.length > 0){
          this.annualIncome1 = result.data.data.amount[0]['text_value'];
          }
          this.country = result.data.data.country;
          this.description = result.data.data.orignal_meta[17].meta_value;
          this.getAge();
          this.getprofilemeta();
          }
        })
        .catch((err) => { });
    },
    async getprofilemeta() {
      await this.axios
        .get("get_members_meta/" + this.$route.query.member_id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result2) => {
          // console.log(result2,"rerewrew")
          this.meta.push(result2.data.data.members_meta);
          for(var i = 0;i<this.meta_data.length;i++){
          if(this.meta_data[i].name == "height"){
          this.height = this.meta_data[i].value;
          }
          if(this.meta_data[i].name == "eye_color"){
          this.eyeColor = this.meta_data[i].value;
          }
          if(this.meta_data[i].name ==  "hair_color"){
            this.hairColor = this.meta_data[i].value;
          }
          if(this.meta_data[i].name ==  "body_type"){
            this.bodyType = this.meta_data[i].value;
          }
          if(this.meta_data[i].name ==  "education"){
            this.education = this.meta_data[i].value;
          }
          if(this.meta_data[i].name ==  "religion"){
            this.religion = this.meta_data[i].value;
          }
          if(this.meta_data[i].name ==  "language"){
            this.language.push(this.meta_data[i].value);
          }
          if(this.meta_data[i].name ==  "marital_status"){
            this.maritalStatus = this.meta_data[i].value;
          }
          if(this.meta_data[i].name ==  "want_childern"){
            this.wantChild = this.meta_data[i].value;

          }
          if(this.meta_data[i].name ==  "have_children"){
            this.haveChild = this.meta_data[i].value;

          }
          if(this.meta_data[i].name ==  "smoke"){
            this.smoke = this.meta_data[i].value;

          }

        }
          for(var j = 0;j<this.meta[0].length;j++){
        if(this.meta[0][j].meta_key ==  "have_children"){
            if(this.meta[0][j].meta_value == 1){
          this.haveChild = 'Yes';
          }else if(this.meta[0][j].meta_value == 2){
            this.haveChild = 'No';
          }
          }
            if(this.meta[0][j].meta_key ==  "origin"){
            this.origin = this.meta[0][j].meta_value;
          }
          if(this.meta[0][j].meta_key ==  "partner_qualities"){
            this.desiredQualities = this.meta[0][j].meta_value;
          }
          if(this.meta[0][j].meta_key == "disability"){
            this.disability = this.meta[0][j].meta_value;
          }
          if(this.meta[0][j].meta_key ==  "guardian"){
            this.walli = this.meta[0][j].meta_value;
          }
          if(this.meta[0][j].meta_key ==  "guardian_name"){
            this.guardianName = this.meta[0][j].meta_value;
          }
          if(this.meta[0][j].meta_key ==  "guardian_relation"){
            this.guardianRelation = this.meta[0][j].meta_value;
          }
          if(this.meta[0][j].meta_key ==  "first_referance_name"){
            this.r1Name  = this.meta[0][j].meta_value;
          }
          if(this.meta[0][j].meta_key ==  "first_referance_num"){
            this.r1Phone  = this.meta[0][j].meta_value;
          }
          if(this.meta[0][j].meta_key ==  "second_referance_name"){
            this.r2Name  = this.meta[0][j].meta_value;
          }
          if(this.meta[0][j].meta_key ==  "second_contact_num"){
            this.r2Phone  = this.meta[0][j].meta_value;
          }
          }
          for(var k = 0;k<this.match_data.length;k++){
            if(this.match_data[k].name ==  "have_children"){
            this.match.haveChild = this.match_data[k].value;
          }
            if(this.match_data[k].name ==  "gender"){
            this.match.gender  = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "age_range"){
            this.match.age_range  = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "education"){
            this.match.education  = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "profession"){
            this.profession1  = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "income"){
            this.match.annualIncome = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "height"){
            this.match.height_range = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "eye_color"){
            this.match.eye_color = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "hair_color"){
            this.match.hair_color = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "body_type"){
            this.match.bodyType = this.match_data[k].value;
          }

          if(this.match_data[k].name ==  "origin"){
            this.match.origin = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "country"){
            this.match.country = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "state"){
            this.match.state = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "marital_status"){
            this.match.maritalStatus = this.match_data[k].value;
          }
          if(this.match_data[k].name ==  "want_childern"){
            this.match.wantChild = this.match_data[k].value;
          }


          }
          this.LoadingTimingFlag = true;
        })
        .catch((err) => { });
    },

    async checkFav() {
      await this.axios
        .get("check_profile_favorite/" + this.$route.query.member_id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result, "12CheckFave");
          if (result.data.data.fav_profiles.length) {
            this.fav_type = true;
          }
        })
        .catch((err) => { });
    },

    async favProfile() {
      var formBody = new FormData();
      formBody.append("favorite_id", this.$route.query.member_id),
        formBody.append("type", this.fav_type);
      await this.axios
        .post("profile_favorite", formBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result)
          this.fav_type = !this.fav_type;
        })
        .catch((err) => {
        });

    },

     async getshowInterest() {
      var formBody = new FormData();
      formBody.append("interest_id", this.$route.query.member_id),
        // formBody.append("type", this.fav_type);
      await this.axios
        .post("profile_interest", formBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result,"interest")
          // this.fav_type = !this.fav_type;
        })
        .catch((err) => { })


    },
        async ShowInterest() {
      var formBody = new FormData();
      formBody.append("interest_id", this.$route.query.member_id),
        // formBody.append("type", this.fav_type);
      await this.axios
        .post("profile_interest", formBody, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result.data.message,"interest")
          this.insterestMessage=result.data.message;
          this.messageSend=true;
          setTimeout(()=>{
            this.messageSend=false
          },2000)
        })
        .catch((err) => { });

    },
    // async getReferenceData() {
    //   await this.axios
    //     .get("get_reference_data", {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       },
    //     })
    //     .then((result) => {
    //       // console.log(result.data.data, "Reference");

    //       // const height = result.data.data[0][0].filter(
    //       //   (x) => x.numeric_value == this.height && x.text_value
    //       // );
    //       // if(height[0]){
    //       // this.height = height[0].text_value;
    //       // }else{
    //       //   this.height = '';
    //       // }

    //       this.maritalStatus = result.data.data[0][10].filter(
    //         (x) => x.numeric_value == this.maritalStatus && x.text_value
    //       );
    //       if(this.maritalStatus[0]){
    //       this.maritalStatus = this.maritalStatus[0].text_value;
    //       }else{
    //         this.maritalStatus = '';
    //       }
    //       const eyeColor = result.data.data[0][1].filter(
    //         (x) => x.numeric_value == this.eyeColor && x.text_value
    //       );
    //       if(eyeColor[0]){
    //       this.eyeColor = eyeColor[0].text_value;
    //       }else{
    //         this.eyeColor = '';
    //       }

    //       const hairColor = result.data.data[0][2].filter(
    //         (x) => x.numeric_value == this.hairColor && x.text_value
    //       );
    //       if(hairColor[0]){
    //       this.hairColor = hairColor[0].text_value;
    //       }else{
    //         this.hairColor = '';
    //       }
    //       const wantChild = result.data.data[0][11].filter(
    //         (x) => x.numeric_value == this.wantChild && x.text_value
    //       );
    //       if(wantChild[0]){
    //       this.wantChild = wantChild[0].text_value;
    //       }else{
    //         this.wantChild = '';
    //       }

    //       const btype = result.data.data[0][3].filter(
    //         (x) => x.numeric_value == this.bodyType && x.text_value
    //       );
    //       if(btype[0]){
    //       this.bodyType = btype[0].text_value;
    //       }else{
    //         this.bodyType = '';
    //       }

    //       const income = result.data.data[0][5].filter(
    //         (x) => x.numeric_value == this.annualIncome && x.text_value
    //       );
    //       if(income[0]){
    //       this.annualIncome = income[0].text_value;
    //       }else{
    //         this.annualIncome = '';
    //       }

    //       const religion = result.data.data[0][7].filter(
    //         (x) => x.numeric_value == this.religion && x.text_value
    //       );
    //       if(religion[0]){
    //       this.religion = religion[0].text_value;
    //       }else{
    //         this.religion = '';
    //       }

    //       const status = result.data.data[0][8].filter(
    //         (x) => x.numeric_value == this.status && x.text_value
    //       );
    //       if(status[0]){
    //       this.status = status[0].text_value;
    //       }else{
    //         this.status = '';
    //       }

    //       const haveChild = result.data.data[0][12].filter(
    //         (x) => x.numeric_value == this.haveChild && x.text_value
    //       );
    //       if(haveChild[0]){
    //       this.haveChild = haveChild[0].text_value;
    //       }else{
    //         this.haveChild = '';
    //       }

    //       const smokes = result.data.data[0][13].filter(
    //         (x) => x.numeric_value == this.smoke && x.text_value
    //       );
    //       if(smokes[0]){
    //       this.smoke = smokes[0].text_value;
    //       }else{
    //         this.smoke = '';
    //       }
    //       const edu = result.data.data[0][6].filter(
    //         (x) => x.numeric_value == this.education && x.text_value
    //       );
    //       if(edu[0]){
    //       this.education = edu[0].text_value;
    //       }

    //       // for match criteria
    //       // convert Match Smoking
    //       const matchsmokes = result.data.data[0][13].filter(
    //         (x) => x.numeric_value == this.match.match_smoke && x.text_value
    //       );
    //       if(matchsmokes[0]){
    //       this.match.match_smoke = matchsmokes[0].text_value;
    //       }else{
    //         this.match.match_smoke = '';
    //       }
    //       // convert Match Have Child
    //       const MatchhaveChild = result.data.data[0][12].filter(
    //         (x) => x.numeric_value == this.match.haveChild && x.text_value
    //       );
    //       if(MatchhaveChild[0]){
    //       this.match.haveChild = MatchhaveChild[0].text_value;
    //       }else{
    //         this.match.haveChild = '';
    //       }
    //       // convert Match Want Child
    //       const MatchwantChild = result.data.data[0][11].filter(
    //         (x) => x.numeric_value == this.match.wantChild && x.text_value
    //       );
    //       if(MatchwantChild[0]){
    //       this.match.wantChild = MatchwantChild[0].text_value;
    //       }else{
    //         this.match.wantChild = '';
    //       }
    //       // convert Match Height
    //       if(this.match.height_range){
    //       const MatchHeight = this.match.height_range.split(",");
    //       MatchHeight.forEach(element => {
    //         const Height = result.data.data[0][0].filter(
    //           (x) => x.numeric_value == element && x.text_value
    //         );
    //         this.temp.temp_heightRange.push(Height[0].text_value);
    //       });
    //       this.match.height_range = this.temp.temp_heightRange;
    //     }

    //       // convert Match Eye Color
    //       if(this.match.eye_color){
    //       const MatchEyeColor = this.match.eye_color.split(",");
    //       MatchEyeColor.forEach(element => {
    //         const EyeColor = result.data.data[0][1].filter(
    //           (x) => x.numeric_value == element && x.text_value
    //         );
    //         this.temp.temp_eyeColor.push(EyeColor[0].text_value);
    //       });
    //       this.match.eye_color = this.temp.temp_eyeColor;
    //     }
    //       // convert Match Hair Color
    //       if(this.match.hair_color){
    //       const MatchHaitColor = this.match.hair_color.split(",");
    //       MatchHaitColor.forEach(element => {
    //         const hairColor = result.data.data[0][2].filter(
    //           (x) => x.numeric_value == element && x.text_value
    //         );
    //         this.temp.temp_hairColor.push(hairColor[0].text_value);
    //       });
    //       this.match.hair_color = this.temp.temp_hairColor;
    //     }
    //       // convert Match Body Type
    //       if(this.match.bodyType){
    //       const MatchBodyType = this.match.bodyType.split(",");
    //       MatchBodyType.forEach(element => {
    //         // console.log(element, "body_element")
    //         const btype = result.data.data[0][3].filter(
    //           (x) => x.numeric_value == element && x.text_value
    //         );
    //         this.temp.temp_bodyType.push(btype[0].text_value);
    //       });
    //       this.match.bodyType = this.temp.temp_bodyType;
    //     }
    //       // convert Match Language
    //       if(this.match.language){
    //       const MatchLanguage = this.match.language.split(",");
    //       MatchLanguage.forEach(element => {
    //         const languagees = result.data.data[0][9].filter(
    //           (x) => x.numeric_value == element && x.text_value
    //         );
    //         this.temp.temp_language.push(languagees[0].text_value);
    //       });
    //       this.match.language = this.temp.temp_language;
    //     }
    //       // convert Match Annual Income
    //       if(this.match.annualIncome){
    //       const MatchAnnualIncom = this.match.annualIncome.split(",");
    //       MatchAnnualIncom.forEach(element => {
    //         const Anualincome = result.data.data[0][5].filter(
    //           (x) => x.numeric_value == element && x.text_value
    //         );
    //         this.temp.temp_annualIncom.push(Anualincome[0].text_value);
    //       });
    //       this.match.annualIncome = this.temp.temp_annualIncom;
    //     }
    //       // convert Match Education
    //       if(this.match.education){
    //       const MatchEducation = this.match.education.split(",");
    //       MatchEducation.forEach(element => {
    //         const edu = result.data.data[0][6].filter(
    //           (x) => x.numeric_value == element && x.text_value
    //         );
    //         this.temp.temp_edu.push(edu[0].text_value);
    //       });
    //       this.match.education = this.temp.temp_edu;
    //     }
    //       // convert Match Religion
    //       if(result.data.data){
    //       const Matchreligion = result.data.data[0][7].filter(
    //         (x) => x.numeric_value == this.match.religion && x.text_value
    //       );
    //       this.match.religion = Matchreligion[0].text_value;
    //       }





    //        // convert Match Martial Status
    //      if(this.match.maritalStatus){
    //        const MatchmaritalStatus = this.match.maritalStatus.split(",");

    //      MatchmaritalStatus.forEach(element => {
    //        // console.log(element,"MatchMartial");
    //        const maritalstats = result.data.data[0][10].filter(
    //          (x) => x.numeric_value == element && x.text_value
    //        );
    //        this.temp.temp_martialStatus.push(maritalstats[0].text_value);
    //      });
    //      this.match.maritalStatus = this.temp.temp_martialStatus
    //     }
    //       // convert Match Profession
    //       if(this.match.profession){
    //       const MatchProfession = this.match.profession.split(",");
    //       MatchProfession.forEach(element => {
    //         const Professional = result.data.data[0][4].filter(
    //           (x) => x.numeric_value == element && x.text_value
    //         );
    //         this.temp.temp_Profession.push(Professional[0].text_value);
    //         this.match.profession = this.temp.temp_Profession;
    //       });
    //     }
    //       // convert Match usa states
    //      const MatchUSAStatus = this.match.usa_status.split(",");
    //      MatchmaritalStatus.forEach(element => {
    //       const USAstats = result.data.data[0][8].filter(
    //          (x) => x.numeric_value == element && x.text_value
    //        );
    //        this.temp.temp_usastatus.push(USAstats[0].text_value);
    //      });
    //      this.match.usa_status = this.temp.temp_usastatus;

    //     })

    //     .catch((err) => {

    //     })
    //     .finally(() => {
    //       this.LoadingTimingFlag=true;
    //                 });
    // },

    // async getProfession() {
    //   await this.axios
    //     .get("get_profession", {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       },
    //     })
    //     .then((result) => {
    //       console.log(result.data.data.profession, 'profession');
    //       // console.log(this.match.profession, 'matcheh profession');

    //       const MatchProfession = this.match.profession.split(",");
    //       var index = 0;
    //       MatchProfession.forEach(element => {
    //         this.profession1[index] = result.data.data.profession.filter(
    //           (x) => x.profession_id == element && x.profession_name
    //         );

    //         index++;
    //       });
    //     })
    //     .catch((err) => { });
    // },
    // async getProfession2() {
    //   await this.axios
    //     .get("get_profession", {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       },
    //     })
    //     .then((result) => {
    //       this.profession = result.data.data.profession[this.profession-1].profession_name;
    //     })
    //     .catch((err) => { });
    // },
    // async getCountries() {


    //   await this.axios
    //     .get("get_match_criteria/" + this.$route.query.member_id, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       },
    //     })
    //     .then((result) => {
    //       this.match.country = result.data[0].country;
    //     })
    //     .catch((err) => { });



    //   await this.axios
    //     .get("get_countries", {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       },
    //     })
    //     .then((result) => {
    //       // console.log(result, "Countries")
    //       const country = result.data.data.countries.filter(
    //         (x) => x.id == this.country && x.name
    //       );
    //       this.country = country[0].name;
    //       const Matchcountry = result.data.data.countries.filter(
    //         (x) => x.id == this.match.country && x.name
    //       );
    //       this.match.country = Matchcountry[0].name;

    //       const MatchOrigin = result.data.data.countries.filter(
    //         (x) => x.id == this.match.origin && x.name
    //       );
    //       // console.log(MatchOrigin, "MatchOrigin")
    //       this.match.origin = MatchOrigin[0].name;



    //     })
    //     .catch((err) => { });
    // },

    // async getStates() {


    //   await this.axios
    //     .get("get_match_criteria/" + this.$route.query.member_id, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       },
    //     })
    //     .then((result) => {
    //       // this.match.country = result.data[0].country;
    //       this.match.state = result.data[0].state;
    //     })
    //     .catch((err) => { });



    //   await this.axios
    //     .get("get_states", {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       },
    //     })
    //     .then((result) => {

    //       const state = result.data.data.states.filter(
    //         (x) => x.id == this.state && x.name
    //       );
    //       this.state = state[0].name;




    //       const Matchstate = result.data.data.states.filter(
    //         (x) => x.id == this.match.state && x.name
    //       );
    //       this.match.state = Matchstate[0].name;




    //     })
    //     .catch((err) => { });
    // },

    async getMatchCriteria() {
      await this.axios
        .get("get_match_criteria/" + this.$route.query.member_id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          console.log(result, "MatchData");
          if(result.data[0].have_childern == 1){
          this.match.haveChild = 'Yes';
          }else if(result.data[0].have_childern == 2){
            this.match.haveChild = 'No';
          }

        })
        .catch((err) => { });
    },

    getAge() {
      var dateString = this.member.d_o_b;
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      // console.log(age);
      this.age = age;
      return age;
    }
  },
  computed: {
    isLoggedIn() {
      return (this.isLoggedIn = localStorage.getItem("token"));
    },
  },
  mounted() {
    $("div").removeClass("modal-backdrop");
    $(".modal-open").css({ paddingRight: "0px" });
    $('body').removeClass('modal-open');
    this.getprofile();
    this.getMatchCriteria();
    // this.getCountries();
    // this.getStates();
    // this.getProfession();
    // this.getProfession2();
    this.checkFav();
    // this.getshowInterest();
    // this.getReferenceData();

  },
};
</script>

<style scoped>
.custom-button {
  font-size: 15px;
  margin-top: 10px;
}

.custom-button i {
  padding-right: 5px;

}

h6 {
  margin-bottom: 10px;
}

button {
  line-height: 26px;
}

.title {
  font-size: 40px;
}

.custom-button {
  font-size: 15px;
  width: 220px;
  height: 50px;
  text-align: center;

}

@media (max-width: 1920px) and (min-width: 992px) {

  .FavouriteButton {
    margin-left: -18px;
  }

  .Bottom_Button {
    display: flex;
  }

  .MainDiv {
    display: flex;
    margin-bottom: 30px;
  }

}

@media (max-width: 991px) and (min-width: 768px) {
  .FavouriteButton {
    margin-left: -18px;
  }

  .MainDiv {
    display: flex;
    margin-bottom: 30px;
  }
}
</style>
